import { Box, ClickAwayListener, IconButton } from '@mui/material';
import { SxProps } from '@mui/material';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import { useTheme } from '@mui/material/styles';
import TuneIcon from '@mui/icons-material/Tune';
import { useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { isMobileState } from 'states';
import { SGTooltip } from '../core';

interface SettingsPopoutProps {
  title: string;
  popperID: string;
  children: React.ReactNode | React.ReactNode[];
  sx?: SxProps;
  placement?: PopperPlacementType;
  externalMenuOpen?: boolean;
  icon?: any;
}

const DEFAULT_STYLES: SxProps = {
  width: '350px',
  height: 'auto',
  marginTop: '10px',
  padding: '16px',
  textAlign: 'center',
};

export const SettingsPopout = ({
  children,
  placement = 'auto',
  popperID,
  sx = {},
  title,
  externalMenuOpen,
  icon = <TuneIcon />,
}: SettingsPopoutProps) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [openSettings, setOpenSettings] = useState(false);

  const defaultStyle: SxProps = {
    ...DEFAULT_STYLES,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.palette.shadows.default,
    borderRadius: theme.spacing(3),
  };

  const handleClose = () => {
    if (externalMenuOpen) {
      return;
    }
    setOpenSettings(false);
  };

  return (
    <>
      <IconButton
        aria-label={title}
        ref={anchorRef}
        onClick={() => {
          setOpenSettings((previousOpen) => !previousOpen);
        }}
        size="medium"
        color="primary"
      >
        <SGTooltip title={title}>{icon}</SGTooltip>
      </IconButton>
      <Popper
        id={popperID}
        open={openSettings}
        anchorEl={anchorRef.current}
        placement={placement}
        disablePortal={!isMobile}
        sx={{ ...(!isMobile && { zIndex: 10000 }) }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div role="presentation">
            <Box sx={{ ...defaultStyle, ...sx }}>{children}</Box>
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default SettingsPopout;
