import { Box } from '@mui/material';
import { DailyOpenInterest } from './DailyOpenInterest';
import { IntradayGamma } from './IntradayGamma';
import { OiTab, OI_TAB_LABELS } from 'types';
import { TabContext, TabPanel } from '@mui/lab';
import { Tabs } from 'components/shared';
import { oiTabState } from 'states';
import { updateSearch } from '../../util';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useSearchParams } from 'react-router-dom';

export const OpenInterestContainer = () => {
  const [oiTab, setOiTab] = useRecoilState(oiTabState);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab == null) {
      return;
    }
    if (
      Object.values(OiTab)
        .map((t) => t as string)
        .includes(tab)
    ) {
      setOiTab(tab as OiTab);
    } else {
      // If someone jammed a bogus tab in the URL, set it to something legitimate
      setSearchParams(updateSearch({ tab: oiTab }));
    }
  }, [searchParams]);

  return (
    <TabContext value={oiTab}>
      <Tabs
        options={OI_TAB_LABELS}
        onChange={(_evt, tab: string) => {
          setOiTab(tab as OiTab);
          setSearchParams(updateSearch({ tab }));
        }}
      />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          overflowX: 'auto',
        }}
      >
        <TabPanel key={OiTab.DAILY_OI} value={OiTab.DAILY_OI}>
          <Box
            sx={{
              transition: '0.5s',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              height: '100%',
            }}
          >
            <DailyOpenInterest />
          </Box>
        </TabPanel>
        <TabPanel key={OiTab.INTRADAY} value={OiTab.INTRADAY}>
          <Box
            sx={{
              transition: '0.5s',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              height: '100%',
            }}
          >
            <IntradayGamma />
          </Box>
        </TabPanel>
      </Box>
    </TabContext>
  );
};
