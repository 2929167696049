import { Box, Link } from '@mui/material';
import useIntegrations from '../../hooks/integrations/useIntegrations';

export const EdgeProIntegration = () => {
  const { getIntegrationsLinksBlock } = useIntegrations();

  return (
    <Box>
      <p>
        File URL updated: <strong>12/21/23</strong>
        <br />
        <em>EdgePro files from Edge Clear update automatically each day</em>.
      </p>
      {getIntegrationsLinksBlock('edge', ['SPY', 'QQQ'])}
    </Box>
  );
};
