import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { OiTab } from '../types';
import dayjs from 'dayjs';
import { InitOutput } from 'parquet-wasm';

const { persistAtom } = recoilPersist();

export const oiSymsState = atom<Set<string>>({
  key: 'oi-syms',
  default: new Set(),
});

export const oiIntradaySymsState = atom<Set<string>>({
  key: 'intraday-syms',
  default: new Set(),
});

export const oiIntradaySyms = atom<string[]>({
  key: 'oi-intraday-syms',
  default: ['SPX', 'VIX'],
  effects_UNSTABLE: [persistAtom],
});

export const oiIntradaySym = atom<string>({
  key: 'oi-intraday-sym',
  default: 'SPX',
  effects_UNSTABLE: [persistAtom],
});

export const oiIntradayTimestampState = atom<dayjs.Dayjs | null>({
  key: 'oi-intraday-timestamp',
  default: null,
});

export const oiIntradayGammaKeys = atom<string[]>({
  key: 'oi-intraday-gamma-keys',
  default: ['cust_gamma'],
  effects_UNSTABLE: [persistAtom],
});

export const oiIntradayPriceBoundsState = atom<number | null>({
  key: 'oi-price-bounds',
  default: null,
});

export const oiIntradayInvertedState = atom<boolean>({
  key: 'oi-intraday-inverted',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const oiIntradayDate = atom<dayjs.Dayjs | null>({
  key: 'oi-intraday-date',
  default: null,
});

export const oiIntradayFilterPrice = atom<boolean>({
  key: 'oi-intraday-filter-price',
  default: true,
});

export const oiTabState = atom<OiTab>({
  key: 'oi-tab',
  default: OiTab.DAILY_OI,
  effects_UNSTABLE: [persistAtom],
});

export const parquetWasmInitPromise = atom<Promise<InitOutput> | null>({
  key: 'parquet-wasm-init-promise',
  default: null,
});
