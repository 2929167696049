import { useRecoilValue } from 'recoil';
import { userDetailsState } from '../../states';
import { Box, Button, Typography } from '@mui/material';
import useToast from '../useToast';

const useIntegrations = () => {
  const userDetails = useRecoilValue(userDetailsState);
  const { openToast } = useToast();

  const getIntegrationsUrl = (fileName: string) => {
    if (userDetails?.integrationsKey == null) {
      return null;
    }

    return `https://api.spotgamma.com/integrations/${userDetails.integrationsKey}/${fileName}`;
  };

  const getIntegrationsDisclaimer = () => {
    return (
      <Box sx={{ marginBottom: '20px' }}>
        <Typography color="text.secondary">
          SpotGamma integrations only work with active memberships. Sharing
          SpotGamma proprietary levels or personalized integration links is a
          violation of our policy and will result in immediate account
          termination without a refund.
        </Typography>
      </Box>
    );
  };

  const getIntegrationsLink = (title: string, fileName: string) => {
    const url = getIntegrationsUrl(fileName);
    if (url == null) {
      return null;
    }

    title = title === 'SPX' ? 'SPX + ES' : title;

    return (
      <Box sx={{ margin: '2px 0px' }} key={fileName}>
        <strong>{title}:</strong>&nbsp;
        <Button
          sx={{ textTransform: 'none', padding: 0, lineHeight: '1' }}
          onClick={async () => {
            navigator.clipboard
              .writeText(url)
              .then(() => {
                openToast({
                  message: `Successfully copied link for ${title} to clipboard.`,
                  type: 'success',
                  duration: 3000,
                });
              })
              .catch(() => {
                openToast({
                  message: `There was an error copying link for ${title}. Please try again.`,
                  type: 'error',
                  duration: 3000,
                });
              });
          }}
        >
          Click to copy link for {title}
        </Button>
      </Box>
    );
  };

  const getIntegrationsLinksBlock = (
    fileSuffix: string,
    exclude: string[] = [],
  ) => {
    const excludeSet = new Set(exclude);
    return (
      <Box sx={{ margin: '10px 0px' }}>
        {['SPX', 'SPY', 'NDX', 'QQQ', 'RUT'].map(
          (sym) =>
            !excludeSet.has(sym) &&
            getIntegrationsLink(sym, `${sym}_${fileSuffix}.csv`),
        )}
      </Box>
    );
  };

  return {
    getIntegrationsLink,
    getIntegrationsUrl,
    getIntegrationsDisclaimer,
    getIntegrationsLinksBlock,
  };
};

export default useIntegrations;
