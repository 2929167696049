import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as d3 from 'd3';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { DarkPoolSectorAverage } from '../../../types/sentiment';
import { sectorAverageDPIState } from '../../../states/sentiment';
import { filterOutType, generateDarkPool } from '../../../util/sentiment';
import useDarkPool from '../../../hooks/sentiment/useDarkPool';
import { Loader } from 'components/shared';

export const DPISectors = () => {
  const [sectorData, setSectorData] = useRecoilState(sectorAverageDPIState);
  const { getDarkPool } = useDarkPool();
  const theme = useTheme();

  const sectorDPIColorScale = d3
    .scaleSequential(d3.interpolateRdYlGn)
    .domain([
      Math.min(...sectorData.map((d) => Math.min(...filterOutType(d)))),
      Math.max(...sectorData.map((d) => Math.max(...filterOutType(d)))),
    ]);

  useEffect(() => {
    async function fetchData() {
      const data = await getDarkPool();
      const { totalSectorDpi, totalSectorAvgDpi } = generateDarkPool(data);
      setSectorData([
        {
          type: 'Current Avg DPI%',
          ...Object.fromEntries(totalSectorDpi),
        } as DarkPoolSectorAverage,
        {
          type: '5 Day Avg DPI%',
          ...Object.fromEntries(totalSectorAvgDpi),
        } as DarkPoolSectorAverage,
      ]);
    }
    fetchData();
  }, [getDarkPool, setSectorData]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          justifyItems: 'flex-start',
          p: theme.spacing(5),
        }}
      >
        <Typography variant="h2" gutterBottom color="text.primary">
          Dark Pool Indicator
        </Typography>
      </Box>

      {sectorData.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    border: 'none',
                    textTransform: 'uppercase',
                    fontWeight: 500,
                  }}
                >
                  Sector
                </TableCell>
                {sectorData.length > 0 &&
                  Object.keys(sectorData[0]).map(
                    (key) =>
                      key !== 'type' && (
                        <TableCell
                          sx={{
                            position: 'sticky',
                            padding: '6px',
                            border: 'none',
                            letterSpacing: '0px',
                            fontSize: '11px',
                            lineHeight: '150%',
                            textTransform: 'uppercase',
                            fontWeight: 500,
                            minWidth: '85px',
                            alignSelf: 'flex-end',
                            verticalAlign: 'bottom',
                          }}
                        >
                          {key}
                        </TableCell>
                      ),
                  )}
              </TableRow>
            </TableHead>
            <TableBody>
              {sectorData.length > 0 &&
                sectorData.map((d) => (
                  <TableRow key={d.type} sx={{ 'td, th': { border: 0 } }}>
                    <TableCell sx={{ fontWeight: 600, py: '6px' }}>
                      {d.type}
                    </TableCell>
                    {Object.entries(d).map(
                      ([k, v]) =>
                        k !== 'type' && (
                          <TableCell
                            sx={{
                              backgroundColor: sectorDPIColorScale(v as number),
                              padding: '6px',
                              color: theme.palette.grey[900],
                            }}
                          >{`${(v as number).toFixed(2)}%`}</TableCell>
                        ),
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Loader isLoading={true} />
      )}
    </>
  );
};
