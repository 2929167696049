import React, { useEffect, useRef, useState } from 'react';
import { getUtcYMD } from 'util/shared/date';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  Brush,
  Bar,
  ComposedChart,
} from 'recharts';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';
import useZeroDTE from '../../../hooks/indices/useZeroDTE';
import { IndicesHeader } from '../shared/IndicesHeader';
import { formatAsCompactNumber } from '../../../util';
import dayjs from 'dayjs';
import {
  ZeroDTE,
  RawZeroDTE,
  IndicesContentType,
  SentimentTab,
  IndexSymbol,
  SymSelectorSettings,
} from '../../../types';
import {
  DEFAULT_BRUSH_ZOOM_CONFIG,
  DEFAULT_CHART_MARGINS,
  DEFAULT_Y2_AXIS_STYLES,
  DEFAULT_Y_AXIS_STYLES,
} from '../../../config';
import { zeroDTEChartZoomConfigState, zeroDTEInitialDataState } from 'states';
import { useRecoilState } from 'recoil';
import {
  ExpandableContentButton,
  InfoButton,
  Loader,
  ZoomOutButton,
} from 'components/shared';
import { getZoomConfigRefArea } from 'util/shared/chart';
import useBrushZoom from 'hooks/useBrushZoom';
import { getRollingMeans } from 'util/shared/volatility';
import ChartWatermarkContainer from 'components/shared/ChartWatermarkContainer';

const SMA_WINDOW = 10;

interface ZeroDTEChartProps {
  isSentiment?: boolean;
  chartStyleOverrides?: React.CSSProperties;
  containerStyleOverrides?: SxProps<Theme>;
  selectedSym: string;
  symSelectorSettings?: SymSelectorSettings;
}

export const ZeroDTEChart = ({
  selectedSym,
  isSentiment,
  chartStyleOverrides,
  containerStyleOverrides,
  symSelectorSettings,
}: ZeroDTEChartProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const theme = useTheme();
  const { getZeroDTE } = useZeroDTE();
  const [zoomConfig, setZoomConfig] = useRecoilState(
    zeroDTEChartZoomConfigState,
  );
  const [initialData, setInitialData] = useRecoilState(zeroDTEInitialDataState);

  const { zoomChartConfig } = useBrushZoom<ZeroDTE>(
    zoomConfig,
    setZoomConfig,
    'epoch_millis',
    initialData,
  );

  useEffect(() => {
    async function generateChartData() {
      setIsLoading(true);
      let data = await getZeroDTE(selectedSym, isSentiment);
      const movingAverageLine = getRollingMeans(
        data.map(({ zero_dte_oi, all_oi }: RawZeroDTE) => zero_dte_oi / all_oi),
        SMA_WINDOW,
      );

      const transformedData = data
        .map(({ trade_date, ...rest }: RawZeroDTE, idx: number) => ({
          epoch_millis: dayjs(trade_date).valueOf(),
          zero_oi_percentage: rest.zero_dte_oi / rest.all_oi,
          zero_vol_percentage: rest.zero_dte_volume / rest.all_volume,
          rolling_oi_percentage:
            movingAverageLine[Math.floor(idx / SMA_WINDOW)],
          ...rest,
        }))
        .sort((a: ZeroDTE, b: ZeroDTE) => a.epoch_millis - b.epoch_millis);
      setInitialData(transformedData);
      setZoomConfig((prev) => ({ ...prev, data: transformedData }));
      setIsLoading(false);
    }
    generateChartData();
  }, [
    getZeroDTE,
    isSentiment,
    selectedSym,
    setIsLoading,
    setInitialData,
    setZoomConfig,
  ]);

  return (
    <Loader isLoading={isLoading}>
      <Stack
        sx={{
          height: '100%',
          width: '100%',
          gap: '8px',
          ...containerStyleOverrides,
        }}
      >
        {!isSentiment ? (
          <IndicesHeader
            symbol={selectedSym}
            symSelectorSettings={symSelectorSettings}
            type={IndicesContentType.ZERO_DTE}
            title="0DTE Volume/Open Interest"
            expandable
            customController={
              <ZoomOutButton
                zoomConfig={zoomConfig}
                setZoomConfig={setZoomConfig}
                initialData={initialData}
                overrideDefault={{
                  leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
                  rightIdx: initialData.length - 1,
                }}
              />
            }
          />
        ) : (
          <Stack
            direction="row"
            gap={3}
            alignItems="center"
            justifyContent="flex-end"
          >
            <ZoomOutButton
              key="zoom"
              zoomConfig={zoomConfig}
              setZoomConfig={setZoomConfig}
              initialData={initialData}
              overrideDefault={{
                leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
                rightIdx: initialData.length - 1,
              }}
            />
            <InfoButton
              key={`${SentimentTab.ZeroDTE}-info`}
              articleKey={SentimentTab.ZeroDTE}
            />
            <ExpandableContentButton type={SentimentTab.ZeroDTE} />
          </Stack>
        )}
        {zoomConfig.data && (
          <ChartWatermarkContainer
            style={{ ...chartStyleOverrides }}
            ref={ref}
            size={25}
            offsetX={55}
            offsetY={40}
          >
            <ResponsiveContainer>
              <ComposedChart
                margin={DEFAULT_CHART_MARGINS}
                barGap={2}
                barSize={6}
                {...zoomChartConfig}
              >
                <CartesianGrid
                  strokeDasharray="1 10"
                  stroke={theme.palette.gray}
                />
                <XAxis
                  allowDataOverflow
                  label={{
                    value: 'Trade Date',
                    fontSize: 12,
                    offset: 3,
                    position: 'insideBottom',
                    fontWeight: 600,
                  }}
                  dataKey="epoch_millis"
                  domain={['dataMin', 'dataMax']}
                  tick={{ fontSize: 10 }}
                  tickFormatter={getUtcYMD}
                  tickCount={15}
                  type="number"
                  interval="preserveStartEnd"
                />
                <Brush
                  dataKey="epoch_millis"
                  tickFormatter={getUtcYMD}
                  startIndex={zoomConfig.leftIdx}
                  endIndex={zoomConfig.rightIdx}
                  onChange={(brushIndices: any) =>
                    setZoomConfig((prev) => ({
                      ...prev,
                      leftIdx: brushIndices.startIndex,
                      rightIdx: brushIndices.endIndex,
                    }))
                  }
                  height={!isSentiment ? 20 : 25}
                  travellerWidth={15}
                  stroke={theme.palette.gray}
                  fill={theme.palette.background.paper}
                  alwaysShowText
                />
                <YAxis
                  allowDataOverflow
                  yAxisId="left"
                  domain={['dataMin', 'dataMax']}
                  tickFormatter={formatAsCompactNumber}
                  tick={{ fontSize: 11 }}
                  type="number"
                  label={{
                    value: 'Open Interest 0DTE/Total %',
                    ...DEFAULT_Y_AXIS_STYLES,
                  }}
                />
                <YAxis
                  allowDataOverflow
                  yAxisId="right"
                  domain={['dataMin', 'dataMax']}
                  tickFormatter={formatAsCompactNumber}
                  tick={{ fontSize: 11 }}
                  label={{
                    value: 'Volume 0DTE/Total %',
                    ...DEFAULT_Y2_AXIS_STYLES,
                  }}
                  orientation="right"
                  type="number"
                />
                <Tooltip
                  isAnimationActive={false}
                  formatter={(v: string) =>
                    `${(parseFloat(v) * 100).toFixed(2)}%`
                  }
                  labelFormatter={getUtcYMD}
                  itemStyle={{ fontSize: '11px' }}
                  contentStyle={{
                    color: theme.palette.text.primary,
                    border: 'none',
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: theme.palette.shadows.paperBoxShadow,
                  }}
                  separator=": "
                />
                <Bar
                  yAxisId="right"
                  dataKey="zero_vol_percentage"
                  name="Volume 0DTE/Total"
                  key="zero_dte_volume"
                  fill={theme.palette.indices.zeroDte.volume}
                />
                <Bar
                  yAxisId="left"
                  dataKey="zero_oi_percentage"
                  name="Open Interest 0DTE/Total"
                  key="zero_dte_oi"
                  fill={theme.palette.indices.zeroDte.oi}
                />
                <Line
                  yAxisId="right"
                  dataKey="rolling_oi_percentage"
                  name="Average Open Interest 0DTE/Total"
                  key="Open Interest 0DTE/Total"
                  dot={false}
                  stroke={theme.palette.indices.zeroDte.oi_avg}
                />
                {getZoomConfigRefArea(zoomConfig, 'left')}
              </ComposedChart>
            </ResponsiveContainer>
          </ChartWatermarkContainer>
        )}
      </Stack>
    </Loader>
  );
};
