import { fetchAPI, getAuthHeader, getPollingHeader } from './shared/fetch';
import PollingWorker from 'PollingWorker';

interface PollOpts {
  url: string;
  interval?: number;
  buffer?: boolean;
  raw?: boolean;
  onResponse: (resp: any) => void;
  noPollOnInit?: boolean;
}

const DEFAULT_POLL_INTERVAL = 20_000; // 20s

// A helper/wrapper around the PollingWorker that abstracts away message ID
// matching as well as  removing message handlers and clearing associated
// intervals on unmount while handling race conditions.
//
// Usage: useEffect(() => poll(worker, opts), [worker, ...]);
export const poll = (worker: PollingWorker, opts: PollOpts) => {
  if (worker == null) {
    return;
  }
  let id: number | null = null;
  let unsubscribed = false;
  const interval = opts.interval ?? DEFAULT_POLL_INTERVAL;

  const onMessage = (msg: any) => {
    // Handle unsubscribe race and ignore messages that aren't ours.
    if (
      unsubscribed ||
      id == null ||
      msg?.data?.id !== id ||
      msg?.data?.type === 'RPC'
    ) {
      return;
    }
    opts.onResponse(msg.data);
    if (worker.setAuthHeader) {
      // locally sometimes get an error where this method doesnt exist
      worker.setAuthHeader(getAuthHeader());
    }
  };

  const unsubscribe = () => {
    worker?.removeEventListener('message', onMessage);
    if (id != null) {
      worker?.clearPoller(id);
    }
    id = null;
    unsubscribed = true;
  };

  if (worker == null) {
    return unsubscribe;
  }

  const pollOnInit = async () => {
    const json = await fetchAPI(opts.url, { ...getPollingHeader(interval) });
    opts.onResponse({ json });
  };

  // Kickoff the polling, waiting for the return ID from setPoller to save
  const startPolling = async () => {
    if (!worker.setPoller) {
      // locally sometimes get an error where this method doesnt exist
      return;
    }

    worker.setAuthHeader(getAuthHeader());
    id = await (opts.buffer
      ? worker.setBufferPoller(opts.url, interval)
      : worker.setPoller(opts.url, interval));

    // If we detect that we attempted to unsubscribe before setPoller returned.
    // make sure we follow through and rerun unsubcribe so we don't leave a poller running
    if (unsubscribed) {
      unsubscribe();
    }
  };
  startPolling();
  worker.addEventListener('message', onMessage);
  if (!opts.noPollOnInit) {
    pollOnInit();
  }
  return unsubscribe;
};
export default poll;
