import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  IconButton,
  List,
  ListItemButton,
  Popper,
  Stack,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { isMobileState, watchlistsState } from '../../states';
import { RecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { SGTooltip } from '../core';
import { useState } from 'react';
import { Watchlist } from 'types';

interface WatchlistMultiSelectProps {
  activeWatchlistIds: number[];
  setActiveWatchlistIds: (ids: number[]) => void;
  title?: string;
}

export const WatchlistMultiSelect = ({
  activeWatchlistIds,
  setActiveWatchlistIds,
  title = 'Filter by Watchlist',
}: WatchlistMultiSelectProps): JSX.Element => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const [anchorEl, setAnchorEl] = useState(null);
  const watchlists = useRecoilValue(watchlistsState);

  const handleToggleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (watchlist: Watchlist, checked: boolean) => {
    let newChecked = [...activeWatchlistIds];

    if (!checked) {
      // If watchlist is not currently active, add it
      newChecked.push(watchlist.id as number);
    } else {
      // If watchlist is currently active, remove it
      newChecked = newChecked.filter((wId) => wId !== watchlist.id);
    }

    setActiveWatchlistIds(newChecked);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'watchlist-popover' : undefined;

  return (
    <>
      <SGTooltip title="Watchlists">
        <IconButton aria-describedby={id} onClick={handleToggleClick}>
          {activeWatchlistIds.length > 0 ? (
            <VisibilityIcon color="primary" />
          ) : (
            <VisibilityOutlinedIcon color="primary" />
          )}
        </IconButton>
      </SGTooltip>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{ zIndex: 100, minWidth: 210, maxWidth: isMobile ? 270 : 380 }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box
            sx={{
              ...(isMobile ? { maxWidth: '100%' } : { maxWidth: '500px' }),
              backgroundColor: theme.palette.background.paper,
              display: 'flex',
              flexDirection: 'column',
              padding: '10px',
              boxShadow: `0px 0px 4px ${theme.palette.background.default}`,
              borderRadius: theme.spacing(3),
              border: `2px solid ${alpha(theme.palette.text.primary, 0.15)}`,
              gap: theme.spacing(3),
            }}
          >
            <Stack sx={{ paddingLeft: '9px' }}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: 14,
                }}
              >
                {title}
              </Typography>
            </Stack>

            <List>
              {watchlists && watchlists.length > 0 ? (
                watchlists.map((watchlist) => {
                  const checked = activeWatchlistIds.includes(
                    watchlist.id as number,
                  );

                  return (
                    <ListItemButton
                      key={watchlist.id}
                      dense
                      onClick={() => handleToggle(watchlist, checked)}
                    >
                      <Checkbox edge="start" checked={checked} />
                      <Typography>{watchlist.name}</Typography>
                    </ListItemButton>
                  );
                })
              ) : (
                <Stack direction="row" alignItems="center">
                  <SentimentDissatisfiedIcon
                    sx={{ color: theme.palette.text.primary, fontSize: 18 }}
                  />
                  <Typography sx={{ paddingLeft: '9px' }}>
                    No watchlists found.
                  </Typography>
                </Stack>
              )}
            </List>

            <Stack direction="row" gap={2} sx={{ width: '100%' }}>
              <Button
                startIcon={<ClearIcon />}
                sx={{
                  textTransform: 'capitalize',
                  fontSize: {
                    xs: 11,
                    sm: 13,
                  },
                  width: '50%',
                }}
                onClick={() => setActiveWatchlistIds([])}
              >
                Hide All
              </Button>

              <Button
                startIcon={<CheckIcon />}
                sx={{
                  textTransform: 'capitalize',
                  fontSize: {
                    xs: 11,
                    sm: 13,
                  },
                  width: '50%',
                }}
                onClick={() =>
                  watchlists &&
                  setActiveWatchlistIds(watchlists.map((w) => w.id as number))
                }
              >
                Show All
              </Button>
            </Stack>
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
