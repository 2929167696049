import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select as MaterialSelect,
  SelectChangeEvent,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SetterOrUpdater } from 'recoil';

interface MultiSelectProps {
  key: string;
  label: string;
  value: any[];
  setter: SetterOrUpdater<any>;
  options: any[];
  dontRenderAllSelected?: boolean;
  optionsTextTransform?: (val: any) => string;
}

export const MultiSelect = ({
  key,
  label,
  value,
  setter,
  options,
  optionsTextTransform,
  dontRenderAllSelected = false,
}: MultiSelectProps) => {
  const theme = useTheme();

  const onChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setter(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <MaterialSelect
      id={key}
      multiple
      value={value}
      displayEmpty
      size="small"
      sx={{
        fontSize: 11,
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.main,
        },
        '.MuiSelect-icon': {
          color: theme.palette.primary.main,
        },
      }}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return label ?? `Click to select`;
        }
        if (!dontRenderAllSelected) {
          return selected.join(' + ');
        }
        return value.length >= options.length
          ? 'Selected All'
          : `Selected ${value.length} of ${options.length}`;
      }}
      onChange={onChange}
    >
      {options.map((option) => {
        return (
          <MenuItem
            key={option}
            value={option}
            sx={{ fontSize: 10, py: 0, px: 4 }}
          >
            <Checkbox checked={value.indexOf(option) > -1} />
            <ListItemText>
              {optionsTextTransform ? optionsTextTransform(option) : option}
            </ListItemText>
          </MenuItem>
        );
      })}
    </MaterialSelect>
  );
};
