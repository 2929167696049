import { Tooltip as TooltipButton, IconButton, Box } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { SetterOrUpdater } from 'recoil';
import { useTheme } from '@mui/material/styles';
import {
  BiaxialChartZoomConfig,
  BrushZoomConfig,
  ChartZoomConfig,
} from '../../types';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {
  DEFAULT_BIAXIAL_ZOOM_CONFIG,
  DEFAULT_BRUSH_ZOOM_CONFIG,
  DEFAULT_ZOOM_CONFIG,
} from 'config';
import { isZoomConfigEqDefault } from '../../util';
import { SGTooltip } from '../core';

interface ZoomButtonProps {
  zoomConfig: BiaxialChartZoomConfig | ChartZoomConfig | BrushZoomConfig;
  setZoomConfig: SetterOrUpdater<any>;
  initialData: any[];
  overrideDefault?: any;
}

export const ZoomOutButton = ({
  zoomConfig,
  setZoomConfig,
  initialData,
  overrideDefault,
}: ZoomButtonProps) => {
  const theme = useTheme();

  const zoomOut = useCallback(() => {
    if (overrideDefault) {
      setZoomConfig({
        ...DEFAULT_BRUSH_ZOOM_CONFIG,
        ...overrideDefault,
        data:
          // slice() is done in order to "trick" the chart to re-render, otherwise it will not
          // since changing indices of Brush element not causing chart re-renders.
          // Known issue: https://github.com/recharts/recharts/issues/2404
          overrideDefault.leftIdx != null && overrideDefault.rightIdx != null
            ? initialData.slice()
            : initialData,
      });
    } else if ('top2' in zoomConfig) {
      setZoomConfig({ ...DEFAULT_BIAXIAL_ZOOM_CONFIG, data: initialData });
    } else {
      setZoomConfig({ ...DEFAULT_ZOOM_CONFIG, data: initialData });
    }
  }, [overrideDefault, zoomConfig, setZoomConfig, initialData]);

  const isZoomActive = useMemo(
    () =>
      isZoomConfigEqDefault(
        zoomConfig as BiaxialChartZoomConfig | ChartZoomConfig,
        overrideDefault,
      ),
    [overrideDefault, zoomConfig],
  );

  return (
    <SGTooltip title="Zoom Out">
      <Box color={theme.palette.gray}>
        <IconButton
          onClick={zoomOut}
          size="small"
          color={isZoomActive ? 'primary' : 'inherit'}
        >
          <ZoomOutIcon />
        </IconButton>
      </Box>
    </SGTooltip>
  );
};
