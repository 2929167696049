import { useRecoilState, useRecoilValue } from 'recoil';
import { useTheme } from '@mui/material/styles';
import {
  occCustomerOptionsTypeState,
  occTransactionSizeState,
} from '../../../states/sentiment';
import { OCCCustomerOptionType, OCCTransactionSize } from '../../../types';
import { alpha, Box, Button, ButtonGroup, Typography } from '@mui/material';
import {
  OCC_CUSTOMER_OPTION_NAME_MAPPING,
  OCC_TRANSACTION_SIZE_NAME_MAPPING,
} from '../../../config';
import { isMobileState } from '../../../states';
import SettingsPopout from '../../shared/SettingsPopout';

const CustomerOptionsControls = () => {
  const theme = useTheme();
  const [currentOptionsType, setOptionsType] = useRecoilState(
    occCustomerOptionsTypeState,
  );
  const isMobile = useRecoilValue(isMobileState);

  return (
    <Box sx={{ textAlign: 'left' }}>
      <Typography sx={{ fontSize: '12px', marginBottom: '4px' }}>
        Customer Option
      </Typography>
      <Box>
        {[...Array(6).keys()].map((index: number) => {
          const group = Object.values(OCCCustomerOptionType).slice(
            index * 2,
            (index + 1) * 2,
          );
          return (
            <ButtonGroup
              key={`${index}`}
              variant={isMobile ? 'contained' : 'outlined'} // outlined looks weird on mobile
              aria-label="outlined secondary button group"
              sx={{ alignSelf: 'left', flexWrap: 'wrap' }}
            >
              {group.map((customerOptionType) => {
                return (
                  <Button
                    key={customerOptionType}
                    sx={{
                      fontFamily: 'SF Pro Display',
                      fontSize: 12,
                      color: alpha(theme.palette.secondary.main, 1),
                      borderColor: alpha(theme.palette.secondary.main, 0.5),
                      backgroundColor:
                        currentOptionsType === customerOptionType
                          ? alpha(theme.palette.secondary.main, 0.25)
                          : 'transparent',
                      '&:hover': {
                        color: alpha(theme.palette.secondary.main, 1),
                        backgroundColor:
                          currentOptionsType === customerOptionType
                            ? alpha(theme.palette.secondary.main, 0.25)
                            : 'transparent',
                        borderColor: alpha(theme.palette.secondary.main, 1),
                      },
                      textTransform: 'capitalize',
                      width: '140px',
                    }}
                    onClick={() => {
                      setOptionsType(customerOptionType);
                    }}
                  >
                    {OCC_CUSTOMER_OPTION_NAME_MAPPING[customerOptionType]}
                  </Button>
                );
              })}
            </ButtonGroup>
          );
        })}
      </Box>
    </Box>
  );
};

const TransactionSizeControls = () => {
  const theme = useTheme();
  const [currentTransactionSize, setTransactionSize] = useRecoilState(
    occTransactionSizeState,
  );
  const isMobile = useRecoilValue(isMobileState);

  return (
    <Box sx={{ textAlign: 'left', paddingTop: '10px' }}>
      <Typography sx={{ fontSize: '12px', marginBottom: '4px' }}>
        Transaction Size
      </Typography>
      <Box>
        <ButtonGroup
          variant={isMobile ? 'contained' : 'outlined'}
          aria-label="outlined secondary button group"
          sx={{ alignSelf: 'left' }}
        >
          {Object.values(OCCTransactionSize).map((transactionSize) => (
            <Button
              key={transactionSize}
              sx={{
                fontFamily: 'SF Pro Display',
                fontSize: 12,
                color: alpha(theme.palette.primary.main, 1),
                borderColor: alpha(theme.palette.primary.main, 0.5),
                backgroundColor:
                  currentTransactionSize === transactionSize
                    ? alpha(theme.palette.primary.main, 0.25)
                    : 'transparent',
                '&:hover': {
                  color: alpha(theme.palette.primary.main, 1),
                  backgroundColor:
                    currentTransactionSize === transactionSize
                      ? alpha(theme.palette.primary.main, 0.25)
                      : 'transparent',
                  borderColor: alpha(theme.palette.primary.main, 1),
                },
                textTransform: 'capitalize',
              }}
              onClick={() => {
                setTransactionSize(transactionSize);
              }}
            >
              {OCC_TRANSACTION_SIZE_NAME_MAPPING[transactionSize]}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export const OCCChartSettings = () => {
  return (
    <SettingsPopout
      title="OCC Settings"
      popperID="occ-controls"
      placement="bottom-end"
    >
      <CustomerOptionsControls />
      <TransactionSizeControls />
    </SettingsPopout>
  );
};
