import {
  HomeEventsCalendarTab,
  Quadrant,
  QuadrantId,
  QuadrantTab,
  QuadrantTabCategory,
  HomeQuadrantTabId,
  Scanner,
  IndexSymbol,
  IndicesContentType,
} from 'types';
import { getDefaultSymOptions } from 'util/indices';
import { INDICES_CONTENT_LABEL_MAP } from './indices';
import { v4 as uuidv4 } from 'uuid';

export const EVENTS_CALENDAR_LABEL_MAPPING = new Map([
  [HomeEventsCalendarTab.MACRO_ECONOMIC_CALENDAR, 'Macro Economic'],
  [HomeEventsCalendarTab.EARNINGS, 'Earnings'],
  [HomeEventsCalendarTab.EARNINGS_CHART, 'Earnings Chart'],
]);

export const TOP_STOCK_SCANNERS = [Scanner.VOL_RISK_PREMIUM, Scanner.SQUEEZE];

export const QUADRANT_TAB_LABEL_MAP: Record<HomeQuadrantTabId, string> = {
  [HomeQuadrantTabId.LIVE_ES_FUTURES_CHART]: 'Live /ES Futures',
  [HomeQuadrantTabId.SG_LEVELS]: 'SpotGamma Levels',
  [HomeQuadrantTabId.INDEX_LEVELS]: 'Index Levels',
  [HomeQuadrantTabId.AT_SPOTGAMMA]: '@ SpotGamma',
  [HomeQuadrantTabId.MACRO_ECONOMIC_CALENDAR]: 'Events Calendar',
  [HomeQuadrantTabId.EARNINGS]: 'Earnings',
  [HomeQuadrantTabId.EARNINGS_CHART]: 'Earnings Chart',
  [HomeQuadrantTabId.SCANNER_SQUEEZE_CANDIDATES]: 'Squeeze Candidates',
  [HomeQuadrantTabId.SCANNER_VRP_CANDIDATES]: 'VRP',
  [HomeQuadrantTabId.INDEX_METRICS]: 'Index Metrics',
  [HomeQuadrantTabId.SUPP_RESISTANCE_LEVELS]: 'Support & Resistance',
  [HomeQuadrantTabId.OCC_CHART]: 'Options Clearning Corporation',
  [HomeQuadrantTabId.ZERO_DTE_EQUITY_VOL_CHART]: '0DTE Equity Volume/OI',
  [HomeQuadrantTabId.EQUITY_PUT_CALL_RATIO_CHART]: 'Equity Put/Call Ratio',
  [HomeQuadrantTabId.EXP_CONCENTRATION_CHART]: 'Expiration Concentration',
};

export const HOME_DEFAULT_QUADRANTS_MAP: Map<QuadrantId, Quadrant> = new Map<
  QuadrantId,
  Quadrant
>([
  [
    QuadrantId.TOP_LEFT,
    {
      tabs: [
        {
          id: uuidv4(),
          contentId: HomeQuadrantTabId.LIVE_ES_FUTURES_CHART,
          label:
            QUADRANT_TAB_LABEL_MAP[HomeQuadrantTabId.LIVE_ES_FUTURES_CHART],
          category: QuadrantTabCategory.CHART,
        },
        {
          id: uuidv4(),
          contentId: IndicesContentType.GAMMA_MODEL,
          label: INDICES_CONTENT_LABEL_MAP[IndicesContentType.GAMMA_MODEL],
          category: QuadrantTabCategory.CHART,
          sym: IndexSymbol.SPX,
          symOptions: getDefaultSymOptions(IndicesContentType.GAMMA_MODEL),
        },
        {
          id: uuidv4(),
          contentId: IndicesContentType.COMBO_STRIKES,
          label: INDICES_CONTENT_LABEL_MAP[IndicesContentType.COMBO_STRIKES],
          category: QuadrantTabCategory.CHART,
          sym: IndexSymbol.SPX,
          symOptions: getDefaultSymOptions(IndicesContentType.COMBO_STRIKES),
        },
        {
          id: uuidv4(),
          contentId: IndicesContentType.GAMMA_LEVELS,
          label: INDICES_CONTENT_LABEL_MAP[IndicesContentType.GAMMA_LEVELS],
          category: QuadrantTabCategory.CHART,
          sym: IndexSymbol.SPX,
          symOptions: getDefaultSymOptions(IndicesContentType.GAMMA_LEVELS),
        },
      ],
    },
  ],
  [
    QuadrantId.TOP_RIGHT,
    {
      tabs: [
        {
          id: uuidv4(),
          contentId: HomeQuadrantTabId.INDEX_LEVELS,
          label: QUADRANT_TAB_LABEL_MAP[HomeQuadrantTabId.INDEX_LEVELS],
          category: QuadrantTabCategory.TABLE,
        },
        {
          id: uuidv4(),
          contentId: HomeQuadrantTabId.SG_LEVELS,
          label: QUADRANT_TAB_LABEL_MAP[HomeQuadrantTabId.SG_LEVELS],
          category: QuadrantTabCategory.TABLE,
        },
        {
          id: uuidv4(),
          contentId: HomeQuadrantTabId.INDEX_METRICS,
          label: QUADRANT_TAB_LABEL_MAP[HomeQuadrantTabId.INDEX_METRICS],
          category: QuadrantTabCategory.TABLE,
        },
        {
          id: uuidv4(),
          contentId: HomeQuadrantTabId.SUPP_RESISTANCE_LEVELS,
          label:
            QUADRANT_TAB_LABEL_MAP[HomeQuadrantTabId.SUPP_RESISTANCE_LEVELS],
          category: QuadrantTabCategory.TABLE,
        },
      ],
    },
  ],
  [
    QuadrantId.BOTTOM_LEFT,
    {
      tabs: [
        {
          id: uuidv4(),
          contentId: HomeQuadrantTabId.SCANNER_SQUEEZE_CANDIDATES,
          label:
            QUADRANT_TAB_LABEL_MAP[
              HomeQuadrantTabId.SCANNER_SQUEEZE_CANDIDATES
            ],
          category: QuadrantTabCategory.TABLE,
        },
        {
          id: uuidv4(),
          contentId: HomeQuadrantTabId.SCANNER_VRP_CANDIDATES,
          label:
            QUADRANT_TAB_LABEL_MAP[HomeQuadrantTabId.SCANNER_VRP_CANDIDATES],
          category: QuadrantTabCategory.TABLE,
        },
      ],
    },
  ],
  [
    QuadrantId.BOTTOM_RIGHT,
    {
      tabs: [
        {
          id: uuidv4(),
          contentId: HomeQuadrantTabId.MACRO_ECONOMIC_CALENDAR,
          label:
            QUADRANT_TAB_LABEL_MAP[HomeQuadrantTabId.MACRO_ECONOMIC_CALENDAR],
          category: QuadrantTabCategory.LIST,
        },
        {
          id: uuidv4(),
          contentId: HomeQuadrantTabId.EARNINGS,
          label: QUADRANT_TAB_LABEL_MAP[HomeQuadrantTabId.EARNINGS],
          category: QuadrantTabCategory.TABLE,
        },
        {
          id: uuidv4(),
          contentId: HomeQuadrantTabId.EARNINGS_CHART,
          label: QUADRANT_TAB_LABEL_MAP[HomeQuadrantTabId.EARNINGS_CHART],
          category: QuadrantTabCategory.CHART,
        },
        {
          id: uuidv4(),
          contentId: HomeQuadrantTabId.AT_SPOTGAMMA,
          label: QUADRANT_TAB_LABEL_MAP[HomeQuadrantTabId.AT_SPOTGAMMA],
          category: QuadrantTabCategory.TEXT_CONTENT,
        },
      ],
    },
  ],
]);

export const ALL_HOME_QUADRANT_TABS: QuadrantTab[] = [
  ...[...HOME_DEFAULT_QUADRANTS_MAP.values()].flatMap((q: Quadrant) => q.tabs),
  {
    id: uuidv4(),
    contentId: HomeQuadrantTabId.OCC_CHART,
    label: QUADRANT_TAB_LABEL_MAP[HomeQuadrantTabId.OCC_CHART],
    category: QuadrantTabCategory.CHART,
  },
  {
    id: uuidv4(),
    contentId: HomeQuadrantTabId.ZERO_DTE_EQUITY_VOL_CHART,
    label: QUADRANT_TAB_LABEL_MAP[HomeQuadrantTabId.ZERO_DTE_EQUITY_VOL_CHART],
    category: QuadrantTabCategory.CHART,
  },
  {
    id: uuidv4(),
    contentId: HomeQuadrantTabId.EQUITY_PUT_CALL_RATIO_CHART,
    label:
      QUADRANT_TAB_LABEL_MAP[HomeQuadrantTabId.EQUITY_PUT_CALL_RATIO_CHART],
    category: QuadrantTabCategory.CHART,
  },
  {
    id: uuidv4(),
    contentId: HomeQuadrantTabId.EXP_CONCENTRATION_CHART,
    label: QUADRANT_TAB_LABEL_MAP[HomeQuadrantTabId.EXP_CONCENTRATION_CHART],
    category: QuadrantTabCategory.CHART,
  },
];
