import { StreamType, Lense, OptionType } from '../types';
import dayjs from 'dayjs';
import { PREMARKET_SYMBOLS } from '../config';
import { ET } from './shared/date';

export const streamTypeForLense = (lense: string, showTotal: boolean) => {
  switch (lense) {
    case Lense.All:
      return showTotal
        ? StreamType.FilteredTotalDelta
        : StreamType.FilteredCallDelta | StreamType.FilteredPutDelta;
    case Lense.NextExp:
      return showTotal
        ? StreamType.FilteredNextExpTotalDelta
        : StreamType.FilteredNextExpCallDelta |
            StreamType.FilteredNextExpPutDelta;
    case Lense.Retail:
      return showTotal
        ? StreamType.FilteredRetailTotalDelta
        : StreamType.FilteredRetailCallDelta |
            StreamType.FilteredRetailPutDelta;
    default:
      return 0;
  }
};

export const lenseForStreamType = (streamType: StreamType) => {
  switch (streamType) {
    case StreamType.FilteredRetailCallDelta:
    case StreamType.FilteredRetailPutDelta:
    case StreamType.FilteredRetailTotalDelta:
    case StreamType.AbsoluteRetailTotalDelta:
      return Lense.Retail;
    case StreamType.FilteredNextExpCallDelta:
    case StreamType.FilteredNextExpPutDelta:
    case StreamType.FilteredNextExpTotalDelta:
    case StreamType.AbsoluteNextExpTotalDelta:
      return Lense.NextExp;
    case StreamType.FilteredPutDelta:
    case StreamType.FilteredCallDelta:
    case StreamType.FilteredTotalDelta:
    case StreamType.AbsoluteTotalDelta:
      return Lense.All;
    default:
      return '';
  }
};

export const optionKeyForStreamType = (streamType: StreamType) => {
  switch (streamType) {
    case StreamType.FilteredRetailTotalDelta:
    case StreamType.FilteredNextExpTotalDelta:
    case StreamType.FilteredTotalDelta:
      return OptionType.TOT;
    case StreamType.FilteredRetailCallDelta:
    case StreamType.FilteredCallDelta:
    case StreamType.FilteredNextExpCallDelta:
      return OptionType.C;
    case StreamType.FilteredNextExpPutDelta:
    case StreamType.FilteredRetailPutDelta:
    case StreamType.FilteredPutDelta:
      return OptionType.P;
    default:
      return '';
  }
};

export const insertIntoSortedArr = (
  arr: any,
  val: any,
  key = 'utc_time',
  opts: any = {},
) => {
  if (!Array.isArray(arr)) {
    return arr;
  }

  arr.push(val);
  let i = arr.length - 1;
  let item = arr[i];
  while (i > 0 && item[key] < arr[i - 1][key]) {
    arr[i] = arr[i - 1];
    i -= 1;
  }
  arr[i] = item;

  if (opts.replace) {
    if (i > 0) {
      if (arr[i][key] === arr[i - 1][key]) {
        arr.splice(i - 1, 1);
      }
    }
  }
  return arr;
};

export const expectedStartEndForSymbol = (
  sym: string,
  date: dayjs.Dayjs = dayjs().tz(ET),
  showExtendedHours = true,
) => {
  const marketClose = date.hour(16).minute(15).second(0).millisecond(0);
  const marketOpen = date.hour(9).minute(30).second(0).millisecond(0);

  if (showExtendedHours && PREMARKET_SYMBOLS.has(sym)) {
    return {
      start: date.hour(0).minute(0).second(0).millisecond(0),
      end:
        date.day() === 5 // on friday, options market closes at 5pm and doesnt reopen again for futures
          ? date.hour(17).minute(0).second(0).millisecond(0)
          : date.hour(23).minute(59).second(59).millisecond(59),
      marketClose,
      marketOpen,
    };
  }

  return {
    start: marketOpen,
    end: marketClose,
    marketClose,
    marketOpen,
  };
};
