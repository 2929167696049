import { RawGreeksObject } from './impliedVol';

export interface Levels {}

export interface TimeSeriesData {
  symbol: string;
  price: number | null;
  priceDelta: number | null;
  values: number[];
}

export enum ScannerColumnKey {
  tradeDate = 'trade_date',
  sym = 'sym',
  price = 'price',
  prevClose = 'upx',
  volume = 'total_volume',
  wkHigh52 = 'dpi_high52w',
  wkLow52 = 'dpi_low52w',
  earningsDate = 'earnings_utc',
  keyGammaStr = 'keyg',
  keyDeltaStr = 'keyd',
  hedgeWall = 'maxfs',
  callWall = 'cws',
  putWall = 'pws',
  callGamma = 'atmgc',
  putGamma = 'atmgp',
  callDelta = 'atmdc',
  putDelta = 'atmdp',
  nextExpGamma = 'next_exp_g',
  nextExpDelta = 'next_exp_d',
  topGammaExp = 'max_exp_g_date',
  topDeltaExp = 'max_exp_d_date',
  callVol = 'cv',
  putVol = 'pv',
  nextExpCallVol = 'ne_call_volume',
  nextExpPutVol = 'ne_put_volume',
  putCallRatio = 'put_call_ratio',
  putCallVolRatio = 'volume_ratio',
  gammaRatio = 'gamma_ratio',
  deltaRatio = 'delta_ratio',
  neSkew = 'ne_skew',
  skew = 'skew',
  rv30 = 'rv30',
  iv30 = 'atm_iv30',
  ivRank = 'iv_rank',
  garchRank = 'garch_rank',
  skewRank = 'skew_rank',
  optionsImpliedMove = 'options_implied_move',
  dpi = 'dpi',
  dpiVol = 'dpi_volume',
  dpi5day = 'hist_dpi',
  dpi5dayVol = 'hist_dpi_volume',
}

export interface Equity {
  [ScannerColumnKey.sym]: string;
  [ScannerColumnKey.price]?: number;
  name?: string;
  [ScannerColumnKey.tradeDate]: string;
  [ScannerColumnKey.prevClose]: number;
  earningsDate?: number;
  [ScannerColumnKey.wkHigh52]: number;
  [ScannerColumnKey.wkLow52]: number;
  callsum: number;
  putsum: number;
  [ScannerColumnKey.putCallRatio]: number;
  minfs: number;
  [ScannerColumnKey.keyGammaStr]: number;
  [ScannerColumnKey.keyDeltaStr]: number;
  [ScannerColumnKey.hedgeWall]: number;
  [ScannerColumnKey.callWall]: number;
  [ScannerColumnKey.putWall]: number;
  largeCoi: number;
  largePoi: number;
  [ScannerColumnKey.nextExpGamma]: number;
  [ScannerColumnKey.nextExpDelta]: number;
  [ScannerColumnKey.topGammaExp]: string;
  [ScannerColumnKey.topDeltaExp]: string;
  [ScannerColumnKey.putCallVolRatio]: number;
  [ScannerColumnKey.callGamma]: number;
  [ScannerColumnKey.putGamma]: number;
  [ScannerColumnKey.gammaRatio]: number;
  [ScannerColumnKey.callDelta]: number;
  [ScannerColumnKey.putDelta]: number;
  [ScannerColumnKey.putVol]: number;
  [ScannerColumnKey.callVol]: number;
  [ScannerColumnKey.deltaRatio]: number;
  totaldelta: number;
  d95ne: number;
  d25ne: number;
  d95: number;
  d25: number;
  [ScannerColumnKey.neSkew]: number;
  [ScannerColumnKey.skew]: number;
  [ScannerColumnKey.nextExpCallVol]: number;
  [ScannerColumnKey.nextExpPutVol]: number;
  [ScannerColumnKey.optionsImpliedMove]: number;
  strike_list: string;
  cg_list: string;
  pg_list: string;
  mf_list: string;
  smf_list: string;
  putctrl: number;
  activity_factor: number;
  position_factor: number;
  call_strikes_list_absg: string;
  call_gnot_list_absg: string;
  put_strikes_list_absg: string;
  put_gnot_list_absg: string;
  date: string;
  Symbol: string;
  [ScannerColumnKey.volume]: number;
  [ScannerColumnKey.dpi]: number;
  [ScannerColumnKey.rv30]: number;
  [ScannerColumnKey.iv30]: number;
  [ScannerColumnKey.skewRank]: number;
  [ScannerColumnKey.garchRank]: number;
  [ScannerColumnKey.ivRank]: number;
  live?: boolean;
  vrp_scanner?: number;
  vrp_scanner_high?: number;
  squeeze_scanner?: number;
}

export type ScannerColumnVisibility = Record<ScannerColumnKey, boolean>;

export interface RawDailySkew {
  day: number;
  dte: number;
  exp: number;
  greeks: RawGreeksObject;
}

export interface RawSkewEntry {
  next: RawDailySkew;
  thirty: RawDailySkew;
}

export interface RawSkewObject {
  today: RawSkewEntry;
  yesterday: RawSkewEntry;
}

export interface HistoricalEquity extends Omit<Equity, 'sym' | 'Symbol'> {
  trade_date: string;
}

export enum Scanner {
  MOST_CALL_GAMMA = 'mostCallGamma',
  LOWEST_PUT_CALL_RATIO = 'lowestPutCallRatio',
  GAMMA_SQUEEZE = 'gammaSqueeze',
  MOST_PUT_GAMMA = 'mostPutGamma',
  HIGHEST_PUT_CALL_RATIO = 'highestPutCallRatio',
  CLOSE_TO_HEDGE_WALL = 'closeToHedgeWall',
  TOP_GAMMA_EXP = 'topGammaExp',
  TOP_DELTA_EXP = 'topDeltaExp',
  LARGEST_DELTA = 'largestDelta',
  CLOSE_TO_KEY_DELTA_STRIKE = 'closeToKeyDeltaStrike',
  HIGH_IMPACT = 'highImpact',
  BEARISH_DARK_POOL = 'bearishDarkPool',
  BULLISH_DARK_POOL = 'bullishDarkPool',
  VOL_RISK_PREMIUM = 'volRiskPremium',
  SQUEEZE = 'squeeze',
  CROSS_ASSET_SUMMARY = 'crossAssetSummary',
}

export type GammaImpact = {
  strike?: number;
  call_gnot?: number;
  put_gnot?: number;
  cg?: number;
  pg?: number;
};

export enum EquityColumnGroup {
  STATISTICS = 'STATISTICS',
  SG_KEY_DAILY_LEVELS = 'SG_KEY_DAILY_LEVELS',
  DIRECTIONAL_INDICATORS = 'DIRECTIONAL_INDICATORS',
  VOLATILITY_INSIGHTS = 'VOLATILITY_INSIGHTS',
  DARK_POOL_INDICATORS = 'DARK_POOL_INDICATORS',
}

export interface RawTimeseriesData {
  [symbol: string]: {
    meta: {
      symbol: string;
      interval: string;
      currency: string;
      exchange_timezone: string;
      exchange: string;
      type: string;
    };
    status: 'ok' | 'error';
    values: {
      datetime: string;
      open: string;
      high: string;
      low: string;
      close: string;
      volume: string;
    }[];
    string: string;
  };
}

export interface Tick {
  price: number;
  epoch_millis: number;
}

export type GammaLevel = {
  strike: number;
  next_exp_put?: number;
  next_exp_call?: number;
  current_put?: number;
  current_call?: number;
};

export const TABS: { [key: string]: string } = {
  composite: 'Composite View',
  pc_impact: 'Put & Call Impact',
  live_price: 'Live Price & SG Levels',
  skew: 'Skew',
  history: 'History',
  rr: 'Risk Reversal',
  fixed_strike_matrix: 'Fixed Strike Matrix',
  term_structure: 'Term Structure',
  vol_skew: 'Volatility Skew',
};

export const TAB_KEYS = new Set(Object.keys(TABS));

export type RiskReversal = {
  upx: number;
  epoch_millis: number;
  rr: number;
};

export type RawRiskReversal = {
  upx: number;
  trade_date: string;
  rr: number;
};

const allGridColumnKeys: ScannerColumnKey[] = Object.values(ScannerColumnKey);

export const ScannerExclusiveColumns: Record<Scanner, ScannerColumnKey[]> = {
  [Scanner.SQUEEZE]: [
    ScannerColumnKey.sym,
    ScannerColumnKey.price,
    ScannerColumnKey.volume,
    ScannerColumnKey.keyGammaStr,
    ScannerColumnKey.keyDeltaStr,
    ScannerColumnKey.hedgeWall,
    ScannerColumnKey.callWall,
    ScannerColumnKey.putWall,
    ScannerColumnKey.nextExpGamma,
    ScannerColumnKey.nextExpDelta,
    ScannerColumnKey.topGammaExp,
    ScannerColumnKey.topDeltaExp,
    ScannerColumnKey.putCallRatio,
    ScannerColumnKey.putCallVolRatio,
    ScannerColumnKey.gammaRatio,
    ScannerColumnKey.deltaRatio,
  ],
  [Scanner.VOL_RISK_PREMIUM]: [
    ScannerColumnKey.sym,
    ScannerColumnKey.price,
    ScannerColumnKey.keyGammaStr,
    ScannerColumnKey.keyDeltaStr,
    ScannerColumnKey.hedgeWall,
    ScannerColumnKey.callWall,
    ScannerColumnKey.putWall,
    ScannerColumnKey.neSkew,
    ScannerColumnKey.skew,
    ScannerColumnKey.rv30,
    ScannerColumnKey.iv30,
    ScannerColumnKey.ivRank,
    ScannerColumnKey.garchRank,
    ScannerColumnKey.optionsImpliedMove,
  ],
  [Scanner.MOST_CALL_GAMMA]: [
    ScannerColumnKey.sym,
    ScannerColumnKey.price,
    ScannerColumnKey.callGamma,
    ScannerColumnKey.putGamma,
    ScannerColumnKey.nextExpGamma,
    ScannerColumnKey.nextExpDelta,
    ScannerColumnKey.nextExpCallVol,
    ScannerColumnKey.nextExpPutVol,
    ScannerColumnKey.topGammaExp,
    ScannerColumnKey.topDeltaExp,
    ScannerColumnKey.rv30,
    ScannerColumnKey.iv30,
    ScannerColumnKey.optionsImpliedMove,
  ],
  [Scanner.LOWEST_PUT_CALL_RATIO]: [
    ScannerColumnKey.sym,
    ScannerColumnKey.price,
    ScannerColumnKey.callVol,
    ScannerColumnKey.putVol,
    ScannerColumnKey.nextExpCallVol,
    ScannerColumnKey.nextExpPutVol,
    ScannerColumnKey.callWall,
    ScannerColumnKey.putWall,
    ScannerColumnKey.putCallRatio,
    ScannerColumnKey.putCallVolRatio,
    ScannerColumnKey.rv30,
    ScannerColumnKey.iv30,
    ScannerColumnKey.optionsImpliedMove,
  ],
  [Scanner.MOST_PUT_GAMMA]: [
    ScannerColumnKey.sym,
    ScannerColumnKey.price,
    ScannerColumnKey.callGamma,
    ScannerColumnKey.putGamma,
    ScannerColumnKey.nextExpGamma,
    ScannerColumnKey.nextExpDelta,
    ScannerColumnKey.nextExpCallVol,
    ScannerColumnKey.nextExpPutVol,
    ScannerColumnKey.topGammaExp,
    ScannerColumnKey.topDeltaExp,
    ScannerColumnKey.rv30,
    ScannerColumnKey.iv30,
    ScannerColumnKey.optionsImpliedMove,
  ],
  [Scanner.HIGHEST_PUT_CALL_RATIO]: [
    ScannerColumnKey.sym,
    ScannerColumnKey.price,
    ScannerColumnKey.callVol,
    ScannerColumnKey.putVol,
    ScannerColumnKey.nextExpCallVol,
    ScannerColumnKey.nextExpPutVol,
    ScannerColumnKey.callWall,
    ScannerColumnKey.putWall,
    ScannerColumnKey.putCallRatio,
    ScannerColumnKey.putCallVolRatio,
    ScannerColumnKey.rv30,
    ScannerColumnKey.iv30,
    ScannerColumnKey.optionsImpliedMove,
  ],
  [Scanner.CLOSE_TO_HEDGE_WALL]: [
    ScannerColumnKey.sym,
    ScannerColumnKey.price,
    ScannerColumnKey.keyGammaStr,
    ScannerColumnKey.keyDeltaStr,
    ScannerColumnKey.hedgeWall,
    ScannerColumnKey.callWall,
    ScannerColumnKey.putWall,
    ScannerColumnKey.rv30,
    ScannerColumnKey.iv30,
    ScannerColumnKey.ivRank,
  ],
  [Scanner.TOP_GAMMA_EXP]: [
    ScannerColumnKey.sym,
    ScannerColumnKey.price,
    ScannerColumnKey.callWall,
    ScannerColumnKey.putWall,
    ScannerColumnKey.callGamma,
    ScannerColumnKey.putGamma,
    ScannerColumnKey.nextExpGamma,
    ScannerColumnKey.nextExpDelta,
    ScannerColumnKey.nextExpCallVol,
    ScannerColumnKey.nextExpPutVol,
    ScannerColumnKey.topGammaExp,
    ScannerColumnKey.topDeltaExp,
  ],
  [Scanner.TOP_DELTA_EXP]: [
    ScannerColumnKey.sym,
    ScannerColumnKey.price,
    ScannerColumnKey.callWall,
    ScannerColumnKey.putWall,
    ScannerColumnKey.callGamma,
    ScannerColumnKey.putGamma,
    ScannerColumnKey.nextExpGamma,
    ScannerColumnKey.nextExpDelta,
    ScannerColumnKey.nextExpCallVol,
    ScannerColumnKey.nextExpPutVol,
    ScannerColumnKey.topGammaExp,
    ScannerColumnKey.topDeltaExp,
  ],
  [Scanner.CLOSE_TO_KEY_DELTA_STRIKE]: [
    ScannerColumnKey.sym,
    ScannerColumnKey.price,
    ScannerColumnKey.keyGammaStr,
    ScannerColumnKey.keyDeltaStr,
    ScannerColumnKey.hedgeWall,
    ScannerColumnKey.callWall,
    ScannerColumnKey.putWall,
    ScannerColumnKey.rv30,
    ScannerColumnKey.iv30,
    ScannerColumnKey.ivRank,
  ],
  [Scanner.GAMMA_SQUEEZE]: allGridColumnKeys,
  [Scanner.LARGEST_DELTA]: allGridColumnKeys,
  [Scanner.HIGH_IMPACT]: allGridColumnKeys,
  [Scanner.BEARISH_DARK_POOL]: allGridColumnKeys,
  [Scanner.BULLISH_DARK_POOL]: allGridColumnKeys,
  [Scanner.CROSS_ASSET_SUMMARY]: allGridColumnKeys,
};

export const ScannerExclusiveColumnsCondensed: Record<
  Scanner,
  ScannerColumnKey[]
> = {
  ...ScannerExclusiveColumns,
  [Scanner.SQUEEZE]: [
    ScannerColumnKey.sym,
    ScannerColumnKey.price,
    ScannerColumnKey.prevClose,
    ScannerColumnKey.volume,
    ScannerColumnKey.callGamma,
    ScannerColumnKey.callVol,
    ScannerColumnKey.topGammaExp,
  ],
  [Scanner.VOL_RISK_PREMIUM]: [
    ScannerColumnKey.sym,
    ScannerColumnKey.price,
    ScannerColumnKey.prevClose,
    ScannerColumnKey.volume,
    ScannerColumnKey.iv30,
    ScannerColumnKey.rv30,
    ScannerColumnKey.ivRank,
  ],
};

export const ScannerExclusiveColumnsCondensedWidths: Map<
  ScannerColumnKey,
  number
> = new Map([
  [ScannerColumnKey.sym, 85],
  [ScannerColumnKey.price, 88],
  [ScannerColumnKey.prevClose, 95],
  [ScannerColumnKey.volume, 90],
  [ScannerColumnKey.callGamma, 89],
  [ScannerColumnKey.callVol, 88],
  [ScannerColumnKey.topGammaExp, 90],
  [ScannerColumnKey.iv30, 90],
  [ScannerColumnKey.rv30, 90],
  [ScannerColumnKey.ivRank, 90],
]);
