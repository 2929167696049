import {
  getPriceLines,
  toEntries,
  update,
  pruneLatest,
  getLast,
  levelsDateUpdated,
  getSgData,
  getQueryDate,
  sigHighLow,
} from '../../util';
import Series from '../hiro/HiroChart/trading_view/Series';
import Chart from '../hiro/HiroChart/trading_view/Chart';
import PriceLine from '../hiro/HiroChart/trading_view/PriceLine';
import { LineStyle } from 'lightweight-charts';
import { useTheme } from '@mui/material/styles';
import { useCallback, useEffect, useRef, useState } from 'react';

import { PriceLineKey, SigHighLowData } from '../../types';
import poll from '../../util/poll';
import { useRecoilValue } from 'recoil';
import {
  isMobileState,
  negativeTrendColorState,
  positiveTrendColorState,
  todaysOpenArrState,
  timezoneState,
  workerState,
} from '../../states';
import useLog from '../../hooks/useLog';
import convertData from '../hiro/HiroChart/convertData';
import { ComboSymbol } from '../../config';
import { Loader } from '../shared';
import { Typography } from '@mui/material';
import { convertLevelsToFutures } from '../../util/home';
import { Center } from '../shared/Center';
import useHomeContent from 'hooks/home/useHomeContent';

const POLL_INTERVAL = 10_000;

export const FuturesChart = () => {
  const sym = ComboSymbol.ES_F;
  const theme = useTheme();
  const [api, setApi] = useState(null);
  const handleRef = useCallback(setApi, [setApi]);
  const priceSeriesRef = useRef(null);
  const [errored, setErrored] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sgData, setSgData] = useState<any>(undefined);
  const worker = useRecoilValue(workerState);
  const currentTimezone = useRecoilValue(timezoneState);
  const { fetchAPIWithLog } = useLog('FuturesChart');
  const posColor = useRecoilValue(positiveTrendColorState);
  const negColor = useRecoilValue(negativeTrendColorState);
  const isMobile = useRecoilValue(isMobileState);
  const todaysOpenArr = useRecoilValue(todaysOpenArrState);
  const [futuresDiff, setFuturesDiff] = useState(undefined);
  const [sigData, setSigData] = useState<SigHighLowData | undefined>(undefined);

  const pricesDataRef = useRef<any[]>([]);
  const priceLines = getPriceLines(sgData, theme, sigData);
  const { getKeyLevelsData } = useHomeContent();

  const lastClose = sgData?.upx;

  const fetchData = async () => {
    setLoading(true);
    const [rawData, newSgData, snapshot, rawKeyLevelsData] = await Promise.all([
      fetchAPIWithLog(`v1/futures`),
      getSgData(getQueryDate(true), 'SPX'),
      fetchAPIWithLog('v1/futures/snapshot'),
      getKeyLevelsData(),
    ]);

    const esSnapshot = snapshot[sym]?.levels;
    const spxKeyLevelsData = rawKeyLevelsData?.data?.find(
      (k: any) => k.sym === 'SPX',
    );
    // if we are using levels data from a day that isnt yesterday, then the futures chart
    // will be inaccurate, so dont use it.
    if (
      levelsDateUpdated(newSgData?.trade_date) &&
      spxKeyLevelsData?.futuresDiff != null
    ) {
      const convertedSgData = convertLevelsToFutures({
        ...newSgData,
        futuresDiff: spxKeyLevelsData.futuresDiff,
      });
      setSgData(convertedSgData);
      setFuturesDiff(spxKeyLevelsData?.futuresDiff);
    } else if (levelsDateUpdated(esSnapshot?.trade_date)) {
      // fallback to snapshot levels (just last close really) if the sg data levels are not updated yet
      setSgData(esSnapshot);
    }

    const symData = rawData ? rawData[sym] : undefined;
    if (symData?.all?.length ?? 0 > 0) {
      const data = convertData(symData, true, currentTimezone);
      pricesDataRef.current = toEntries(
        data.all.TOT,
        'stock_price',
        currentTimezone,
      );
    } else {
      setErrored(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    const newSigData = sigHighLow(sgData, todaysOpenArr);
    if (futuresDiff == null || newSigData == null) {
      return;
    }

    setSigData({
      sig_high: newSigData.sig_high + futuresDiff,
      sig_low: newSigData.sig_low + futuresDiff,
    });
  }, [todaysOpenArr, sgData, futuresDiff]);

  useEffect(() => {
    if (pricesDataRef.current.length === 0) {
      fetchData();
    }
  }, []);

  const handleLatestResponse = ({ json }: any) => {
    if (pricesDataRef.current.length === 0) {
      return;
    }

    const fetchedData: any = convertData(json[sym], true, currentTimezone);

    const latestPrices = toEntries(
      fetchedData.all.TOT,
      'stock_price',
      currentTimezone,
    );

    const newStockPrices = pruneLatest(
      getLast(pricesDataRef.current),
      pricesDataRef.current,
      latestPrices,
      'time',
    );

    if (newStockPrices.length === 0) {
      return;
    }

    pricesDataRef.current.push(...newStockPrices);

    update(priceSeriesRef.current, newStockPrices);
  };

  useEffect(() => {
    return poll(worker, {
      url: `v1/futures/realtime`,
      interval: POLL_INTERVAL,
      onResponse: handleLatestResponse,
    });
  }, [worker]);

  const len = pricesDataRef.current.length;

  const timeScale = {
    timeVisible: true,
    secondsVisible: true,
    shiftVisibleRangeOnNewBar: true,
    logicalRange: [0, len - 1],
    fixLeftEdge: true,
    fixRightEdge: true, // false (desirable) prevents zooming out an arbitrary amount?
  };

  const chartLayoutOptions = {
    background: {
      color: theme.palette.background.paper,
    },
    textColor: theme.palette.text.primary,
  };

  const renderChart = () => {
    const colorProps =
      lastClose != null
        ? {
            topLineColor: posColor,
            topFillColor: posColor,
            bottomLineColor: negColor,
            bottomFillColor: negColor,
          }
        : { color: theme.palette.hiro.lenses.price.total };

    return (
      <Chart
        height={1}
        rightPriceScale={{
          autoScale: true,
          visible: true,
        }}
        leftPriceScale={{
          visible: true,
          scaleMargins: { top: 0.3, bottom: 0.25 },
        }}
        ref={handleRef}
        layout={chartLayoutOptions}
        timeScale={timeScale}
      >
        <Series
          ref={priceSeriesRef}
          data={pricesDataRef.current}
          baseValue={{ type: 'price', price: lastClose ?? 0 }}
          name="Price"
          priceScaleId={'left'}
          type={lastClose != null ? 'baseline' : 'line'}
          lineWidth={3}
          {...colorProps}
        >
          {Object.keys(priceLines)
            .filter((k: string) => priceLines[k as PriceLineKey]?.value != null)
            .map((k: string) => (
              <PriceLine
                key={k}
                price={priceLines[k as PriceLineKey].value}
                color={priceLines[k as PriceLineKey].color}
                lineStyle={LineStyle.Dotted}
                axisLabelVisible={true}
                title={k}
              />
            ))}
        </Series>
      </Chart>
    );
  };

  return (
    <div
      style={{
        // mobile needs an exact height in order to render properly
        height: isMobile ? '300px' : '90%',
        maxHeight: isMobile ? '300px' : '500px',
        width: '100%',
        marginTop: '15px',
      }}
    >
      <Loader isLoading={loading}>
        {errored ? (
          <Center
            sx={{
              cursor: 'pointer',
            }}
          >
            <Typography onClick={fetchData} sx={{ fontSize: '14px' }}>
              Unfortunately, there was an error. Click here to try again.
            </Typography>
          </Center>
        ) : (
          renderChart()
        )}
      </Loader>
    </div>
  );
};
