import { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  ET,
  expectedStartEndForSymbol,
  getDateFormatted,
  getQueryDateFormatted,
  getSelectedLenses,
  hexToRGBA,
  insertIntoSortedArr,
  isMarketOpen,
  isMarketOpenOnDate,
  lenseForStreamType,
  optionKeyForStreamType,
  update,
  utcMsToEtSecs,
  nonProdDebugLog,
} from '../../../util';
import dayjs from 'dayjs';
import {
  bottomCandlesTypeState,
  candleDurationState,
  endQueryDateState,
  hiroETHState,
  hiroManifestState,
  lenseState,
  negativeTrendColorState,
  optionTypeState,
  positiveTrendColorState,
  startQueryDateState,
  streamingPricesOverrideForSymState,
  streamingTokenState,
  sumWindowState,
} from 'states';
import { useStreamingRefs } from './useStreamingRefs';
import { useStreamingSocket } from './useStreamingSocket';
import { BottomCandlesType, Lense, ProductType, StreamType } from 'types';
import { useTheme } from '@mui/material/styles';
import useLog from '../../../hooks/useLog';
import useSetSym from '../../hiro/useSetSym';
import usePrices from './../api/usePrices';

const LENSE_FILTER_TO_DATA_KEY = {
  all: 'all',
  next_exp: 'nextExp',
  retail: 'retail',
};

export type useStreamingProps = {
  seriesRefs: any;
  api: any;
  priceSeriesRef: any;
  setChartLoading: (loading: boolean) => void;
};

const splitCandle = (candle: number[]): [number, number, number, number] => {
  let timestamp, _open, high, low, close;
  // TODO: Remove when candles API is push-safe
  if (candle.length === 4) {
    [timestamp, high, low, close] = candle;
  } else {
    [timestamp, _open, high, low, close] = candle;
  }
  // Discard the unused open price, which is 0 for anything other than price
  return [timestamp, high, low, close];
};

const useStreaming = (enabled: boolean, props?: useStreamingProps) => {
  const { seriesRefs, api, priceSeriesRef, setChartLoading } = props ?? {};
  const startDate = useRecoilValue(startQueryDateState);
  const endDate = useRecoilValue(endQueryDateState);
  const [hiroManifest, setHiroManifest] = useRecoilState(hiroManifestState);
  const selectedLenseMap = useRecoilValue(lenseState);
  const selectedLenses = getSelectedLenses(selectedLenseMap);
  const selectedLensesStr = useMemo(
    () => selectedLenses.sort().join(','),
    [selectedLenses],
  );
  const currOptionType = useRecoilValue(optionTypeState);
  const showTotal = currOptionType === 'total';

  const streamingPricesOverrideForSym = useRecoilValue(
    streamingPricesOverrideForSymState,
  );
  const showExtendedHrs = useRecoilValue(hiroETHState);
  const { getSym } = useSetSym();
  const sym = getSym(ProductType.HIRO);
  const rollingSeconds = useRecoilValue(sumWindowState);
  const bottomCandlesType = useRecoilValue(bottomCandlesTypeState);
  const [lastSym, setLastSym] = useState(sym);
  const candleDuration = useRecoilValue(candleDurationState);
  const theme = useTheme();
  const { logError } = useLog('useStreaming');
  const positiveTrendColor = useRecoilValue(positiveTrendColorState);
  const negativeTrendColor = useRecoilValue(negativeTrendColorState);

  usePrices(updatePrice);

  const usePricesHistorical = true;
  const streamingToken = useRecoilValue(streamingTokenState);
  const [liveStreamBucketingEnabled, setLiveStreamBucketingEnabled] =
    useState(false);

  const {
    hiroStreamingRefs,
    dataRefs,
    resetPricesRefs,
    resetCandlesRefs,
    getRolledCandles,
    getUnrolledCandles,
    calculateRolledCandles,
    isDataLoadedForLenses,
    getPrices,
    initUnrolledCandle,
    insertCandleIntoUnrolledCandles,
    insertBottomCandle,
    resetAllRefs,
    getBottomCandles,
  } = useStreamingRefs(enabled, showTotal);

  const {
    fetchStreamingEndpoint,
    fetchAndDecodeStreamingEndpoint,
    reconnectSocket,
  } = useStreamingSocket(
    enabled,
    hiroStreamingRefs,
    dataRefs,
    showTotal,
    addAndUpdateChartWithCandle,
  );

  function addAndUpdateChartWithCandle(candleArr: any, stream: StreamType) {
    let symbol, timestamp, open, high, low, close;
    if (candleArr.length === 6) {
      [symbol, timestamp, open, high, low, close] = candleArr;
    } else {
      [symbol, timestamp, high, low, close] = candleArr;
      open = 0;
    }
    if (symbol != sym) {
      return;
    }

    const time = roundToNearestCandleBucket(utcMsToEtSecs(timestamp));
    if (stream === StreamType.AbsolutePrice) {
      addPriceAndUpdate({
        time,
        value: close,
      });
    } else {
      const candle = { open, high, low, close, time };
      addCandleAndUpdate(candle, stream);
    }
  }

  const addPriceAndUpdate = (latestPrice: any) => {
    if (
      hiroStreamingRefs.chartUpdatingEnabled.current &&
      latestPrice != null &&
      (isMarketOpen() || showExtendedHrs)
    ) {
      updatePrice(latestPrice);
      const selectedLenses = getSelectedLenses(selectedLenseMap);

      if (api != null && selectedLenses.length > 0) {
        // If current time rolling is looking at latest data, update it's
        // rightmost bound to bring in new data
        const timeRange = api.timeScale().getVisibleLogicalRange();
        if (timeRange == null) {
          return;
        }
        const { from, to } = timeRange;
        const unrolledCandles = getUnrolledCandles(
          selectedLenses[0],
          showTotal ? 'TOT' : 'C',
        );
        if (to >= unrolledCandles.length && from != null && to != null) {
          let len = unrolledCandles.length;
          api.timeScale().setVisibleLogicalRange({
            from: from === 0 ? 0 : from + len,
            to: to + len,
          });
        }
      }
    }
  };

  const mergeCandles = (newCandle: any, candlesArr: any) => {
    if (candlesArr.length === 0) {
      return newCandle;
    }
    const lastCandle = candlesArr[candlesArr.length - 1];
    if (lastCandle.time !== newCandle.time) {
      return newCandle;
    }
    return {
      ...newCandle,
      high: Math.max(newCandle.high, lastCandle.high),
      low: Math.min(newCandle.low, lastCandle.low),
      close: lastCandle.close + newCandle.close,
    };
  };

  const addCandleAndUpdate = (newCandle: any, stream: StreamType) => {
    if (!newCandle || Object.keys(newCandle).length === 0) {
      return null;
    }

    const lense = lenseForStreamType(stream);

    if (stream === StreamType.AbsoluteTotalDelta) {
      if (bottomCandlesType !== BottomCandlesType.ABSOLUTE_DELTA) {
        return;
      }
      newCandle = mergeCandles(newCandle, getBottomCandles(lense));
      insertBottomCandle(newCandle, lense);
      updateHistogram(newCandle, lense);
    } else {
      const option = optionKeyForStreamType(stream);
      const refCandlesUnrolled = getUnrolledCandles(lense, option);
      if (!refCandlesUnrolled) {
        return null;
      }
      newCandle = mergeCandles(newCandle, refCandlesUnrolled);
      // consoleLog('Unrolled candles pre-add', refCandlesUnrolled);
      // consoleLog(
      //   'Last unrolled candle pre-add',
      //   refCandlesUnrolled[refCandlesUnrolled.length - 1],
      // );
      nonProdDebugLog(
        `Adding candle ${lense} ${option} for sym ${sym}`,
        newCandle,
      );
      // consoleLog(
      //   'Last unrolled candle post-add',
      //   refCandlesUnrolled[refCandlesUnrolled.length - 1],
      // );
      insertCandleIntoUnrolledCandles(newCandle, lense, option);
      updateChart(newCandle, lense, option);

      if (
        bottomCandlesType === BottomCandlesType.FILTERED_DELTA &&
        option === 'TOT' // only show histogram for total
      ) {
        updateHistogram(newCandle, lense);
      }
    }
  };

  const updateChart = (newCandle: any, lense: string, option: string) => {
    if (
      hiroStreamingRefs.chartUpdatingEnabled.current &&
      getDateFormatted(dayjs().tz(ET)) === getDateFormatted(endDate) &&
      (isMarketOpen() || showExtendedHrs)
    ) {
      const chartRef = seriesRefs[lense][option];
      const rolledCandles = calculateRolledCandles(lense, option);
      if (rolledCandles.length === 0) {
        return;
      }

      update(chartRef.current, [rolledCandles[rolledCandles.length - 1]]);
    }
  };

  const updateHistogram = (newCandle: any, lense: string) => {
    if (
      hiroStreamingRefs.chartUpdatingEnabled.current &&
      (isMarketOpen() || showExtendedHrs) &&
      seriesRefs[lense] &&
      seriesRefs[lense].histogram
    ) {
      const histogramRef = seriesRefs[lense].histogram;
      update(histogramRef.current, [candleToHistogramEntry(newCandle)]);
    }
  };

  const roundToNearestCandleBucket = (time: number, ms = false) => {
    let divisor = candleDuration;
    if (ms) {
      divisor *= 1000;
    }

    return Math.floor(time / divisor) * divisor;
  };

  const getBottomCandlesLense = () => {
    const selectedLenses = getSelectedLenses(selectedLenseMap);
    if (selectedLenses.length === 0) {
      return null;
    }
    return selectedLenses.length !== 1 ? 'all' : selectedLenses[0];
  };

  const getQueryStreamingCandlesParams = () => {
    // option = 'total' (default) | 'call' | 'put'
    // filter = 'all' (default) | 'next_exp' | 'retail'
    // field = 'delta' ( default) | 'gamma' | 'vega' | 'price'
    const options = showTotal ? ['total'] : ['call', 'put'];
    const filtersToFetch = selectedLenses.map(
      (lense: string) =>
        Object.keys(LENSE_FILTER_TO_DATA_KEY).find(
          (key) => (LENSE_FILTER_TO_DATA_KEY as any)[key] === lense,
        )!,
    );

    return options.flatMap((option) => {
      return filtersToFetch.flatMap((filter: string) => {
        return [
          {
            option,
            filter,
            field: 'delta',
          },
        ];
      });
    });
  };

  const queryStreamingCandlesData = async () => {
    if (streamingToken == null) {
      nonProdDebugLog('Streaming token is null');
      return;
    }
    disableChartUpdating();
    resetCandlesRefs(sym, true);
    reconnectSocket();
    const symbol = sym;

    // startdate and enddate come as dates, default to midnight
    // over query start at midnight till end  at 11:59:59pm because server wont return anything less
    const start = dayjs.tz(getDateFormatted(startDate), ET);
    const end = dayjs.tz(getDateFormatted(endDate), ET).add(1, 'day');

    // option = 'total' (default) | 'call' | 'put'
    // filter = 'all' (default) | 'next_exp' | 'retail'
    // field = 'delta' ( default) | 'gamma' | 'vega' | 'price'
    const endpointPrefix = `/candles?sym=${encodeURIComponent(
      symbol,
    )}&start=${start.valueOf()}&end=${end.valueOf()}`;

    const includePrices = usePricesHistorical;
    const paramsArr = getQueryStreamingCandlesParams();
    const candlesPromises = paramsArr.map(async (params: any) => {
      try {
        const endpoint = `${endpointPrefix}&option=${params.option}&filter=${params.filter}&field=${params.field}`;
        nonProdDebugLog('Fetching ', endpoint);
        const data = await fetchAndDecodeStreamingEndpoint(endpoint);
        return { ...params, data };
      } catch (e) {
        logError(e, 'queryStreamingCandlesData');
        return null;
      }
    });

    const pricePromise = async () => {
      try {
        const endpoint = `${endpointPrefix}&field=price`;
        const data = await fetchAndDecodeStreamingEndpoint(endpoint);
        return { field: 'price', data };
      } catch (e) {
        logError(e, 'pricePromise');
        return null;
      }
    };

    const bottomCandlesHistogramPromise = async () => {
      try {
        const lense = getBottomCandlesLense();
        if (
          bottomCandlesType === BottomCandlesType.FILTERED_DELTA ||
          lense == null
        ) {
          return null;
        }

        let data;
        let endpoint = '';
        if (bottomCandlesType === BottomCandlesType.ABSOLUTE_DELTA) {
          endpoint = `${endpointPrefix}&filter=${lense}&stream=absolute&field=delta`;
        } else if (bottomCandlesType === BottomCandlesType.SIZE) {
          endpoint = `${endpointPrefix}&field=size`;
        } else {
          logError(
            'Unrecognized bottom candles type, returning...',
            'bottomCandlesHistogramPromise',
            { bottomCandlesType },
          );
          return null;
        }

        data = await fetchAndDecodeStreamingEndpoint(endpoint);
        return { field: 'bottom_candles', data };
      } catch (e) {
        logError(e, 'bottomCandlesHistogramPromise');
        return null;
      }
    };

    const allData = await Promise.all([
      // order here matters. candlesPromises must come before latestCandleEventsPromise
      ...candlesPromises,
      includePrices ? pricePromise() : Promise.resolve(),
      bottomCandlesHistogramPromise(),
    ]);

    const parsePriceData = (priceData: any) => {
      resetPricesRefs(symbol);

      let currentDay = start;
      let includeExtendedHours = shouldIncludeExtendedHours(currentDay);
      let expectedStartEnd = expectedStartEndForSymbol(
        sym,
        currentDay,
        includeExtendedHours,
      );
      let expectedTsStart = utcMsToEtSecs(expectedStartEnd.start.valueOf());
      let expectedTsEnd = utcMsToEtSecs(expectedStartEnd.end.valueOf());

      for (const price of priceData) {
        const [timestamp, _high, _low, close] = splitCandle(price);
        const ts = utcMsToEtSecs(timestamp);
        // if (
        //   // testing fake requires changing your date to a time when the market was open
        //   // so simulate the market being open by not adding prices after now
        //   (!isProd() || isDemo()) &&
        //   sym === '__FAKE__' &&
        //   ts > now
        // ) {
        //   continue;
        // }

        if (ts < expectedTsStart) {
          continue;
        } else if (ts > expectedTsEnd) {
          if (getDateFormatted(currentDay) >= getDateFormatted(endDate)) {
            continue;
          }
          currentDay = currentDay.add(1, 'day');
          while (!isMarketOpenOnDate(currentDay)) {
            currentDay = currentDay.add(1, 'day');
          }
          includeExtendedHours = shouldIncludeExtendedHours(currentDay);
          expectedStartEnd = expectedStartEndForSymbol(
            sym,
            currentDay,
            includeExtendedHours,
          );
          expectedTsStart = utcMsToEtSecs(expectedStartEnd.start.valueOf());
          expectedTsEnd = utcMsToEtSecs(expectedStartEnd.end.valueOf());
          if (ts < expectedTsStart || ts > expectedTsEnd) {
            continue;
          }
        }

        getPrices().push({
          value: close,
          time: ts,
        });
      }
    };

    const parseCandleData = (candleDataAndParams: any) => {
      let filterDataKey = candleDataAndParams.filter;
      if (filterDataKey === 'next_exp') {
        filterDataKey = 'nextExp';
      }
      let optionDataKey =
        candleDataAndParams.option === 'total'
          ? 'TOT'
          : candleDataAndParams.option === 'call'
          ? 'C'
          : 'P';

      // once data is loaded, we can initialize the candles for that lense+option to prepare them to have data added
      initUnrolledCandle(filterDataKey, optionDataKey, symbol);

      let sum = 0;
      let i = 0;
      let currentDay = start;

      while (currentDay < end) {
        const expectedStartEnd = expectedStartEndForSymbol(
          sym,
          currentDay,
          shouldIncludeExtendedHours(currentDay),
        );
        const expectedTsStart = utcMsToEtSecs(expectedStartEnd.start.valueOf());
        const expectedTsEnd = Math.min(
          utcMsToEtSecs(dayjs().tz(ET).valueOf()),
          utcMsToEtSecs(expectedStartEnd.end.valueOf()),
        );
        const marketOpenTs = utcMsToEtSecs(expectedStartEnd.marketOpen);
        const marketCloseTs = utcMsToEtSecs(expectedStartEnd.marketClose);

        for (
          let es = expectedTsStart;
          es < expectedTsEnd;
          es += candleDuration
        ) {
          let ts = 1;
          let candleData = [];
          const isAfterHours = es <= marketOpenTs || es >= marketCloseTs;

          while (ts > 0 && es > ts) {
            candleData =
              i < candleDataAndParams.data.length
                ? candleDataAndParams.data[i]
                : [];
            ts = candleData[0] ? utcMsToEtSecs(candleData[0]) : 0;
            if (es > ts) {
              i += 1;
            }
          }

          const [timestamp, high, low, close] = splitCandle(candleData);
          let candle = { open: 0, close: 0, high: 0, low: 0, time: es };
          if (candleData.length > 0 && ts === es) {
            if (
              candleData.length > 0 &&
              i < candleDataAndParams.data.length - 1 &&
              timestamp === candleDataAndParams.data[i + 1][0]
            ) {
              // may have duplicate timestamps in array. if so ignore the earliest ones
              continue;
            }

            candle = {
              high,
              low,
              close,
              open: 0,
              time: ts,
            };
            if (optionDataKey === 'TOT' && filterDataKey === 'all') {
              sum += close;
            }
            i += 1;
          } else if (isAfterHours) {
            continue;
          }
          insertCandleIntoUnrolledCandles(candle, filterDataKey, optionDataKey);
          // getUnrolledCandles(filterDataKey, optionDataKey).push(candle);
        }

        currentDay = currentDay.add(1, 'day');
        while (!isMarketOpenOnDate(currentDay)) {
          currentDay = currentDay.add(1, 'day');
        }
      }
    };

    const shouldIncludeExtendedHours = (currentDay: dayjs.Dayjs) => {
      return (
        showExtendedHrs &&
        getDateFormatted(currentDay) === getQueryDateFormatted(true)
      );
    };

    const parseBottomCandles = (data: any) => {
      const lense = getBottomCandlesLense();
      if (!Array.isArray(data) || lense == null) {
        return;
      }
      let currentDay = start;
      let includeExtendedHours = shouldIncludeExtendedHours(currentDay);
      let expectedStartEnd = expectedStartEndForSymbol(
        sym,
        currentDay,
        includeExtendedHours,
      );
      let expectedTsStart = utcMsToEtSecs(expectedStartEnd.start.valueOf());
      let expectedTsEnd = utcMsToEtSecs(expectedStartEnd.end.valueOf());

      for (const event of data) {
        if (!Array.isArray(event)) {
          continue;
        }
        const [time, high, low, close] = splitCandle(event);
        const ts = utcMsToEtSecs(time);

        if (ts < expectedTsStart) {
          continue;
        } else if (ts > expectedTsEnd) {
          if (getDateFormatted(currentDay) >= getDateFormatted(endDate)) {
            continue;
          }
          currentDay = currentDay.add(1, 'day');
          while (!isMarketOpenOnDate(currentDay)) {
            currentDay = currentDay.add(1, 'day');
          }
          includeExtendedHours = shouldIncludeExtendedHours(currentDay);
          expectedStartEnd = expectedStartEndForSymbol(
            sym,
            currentDay,
            includeExtendedHours,
          );
          expectedTsStart = utcMsToEtSecs(expectedStartEnd.start.valueOf());
          expectedTsEnd = utcMsToEtSecs(expectedStartEnd.end.valueOf());

          if (ts < expectedTsStart || ts > expectedTsEnd) {
            continue;
          }
        }

        insertIntoSortedArr(
          dataRefs[lense].TOT.bottomCandles.current[sym],
          {
            time: utcMsToEtSecs(time),
            high,
            low,
            close,
            open: 0,
          },
          'time',
          { replace: true },
        );
      }

      nonProdDebugLog(
        'Parsed bottom candles',
        dataRefs[lense].TOT.bottomCandles.current[sym],
      );
    };

    nonProdDebugLog('All received data: ', allData);

    for (const dataAndParams of allData) {
      if (!dataAndParams) {
        continue;
      }

      if (dataAndParams.field === 'price') {
        parsePriceData(dataAndParams.data);
      } else if (dataAndParams.field === 'delta') {
        parseCandleData(dataAndParams);
      } else if (dataAndParams.field === 'bottom_candles') {
        parseBottomCandles(dataAndParams.data);
      }
    }

    nonProdDebugLog('Data refs', dataRefs);

    enableChartUpdating();
  };

  const enableChartUpdating = () => {
    if (hiroStreamingRefs.chartUpdatingEnabled.current) {
      return;
    }

    hiroStreamingRefs.chartUpdatingEnabled.current = true;
    setLiveStreamBucketingEnabled(true);
    setChartLoading!(false);
  };

  const disableChartUpdating = () => {
    hiroStreamingRefs.chartUpdatingEnabled.current = false;
    setLiveStreamBucketingEnabled(false);
    setChartLoading!(true);
  };

  useEffect(() => {
    if (!enabled || !liveStreamBucketingEnabled || streamingToken == null) {
      return;
    }

    return reconnectSocket();
  }, [
    liveStreamBucketingEnabled,
    sym,
    selectedLenseMap,
    endDate,
    streamingPricesOverrideForSym,
    bottomCandlesType,
    streamingToken,
    enabled,
  ]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const symChanged = sym !== lastSym;
    if (symChanged) {
      hiroStreamingRefs.currentSymbol.current = sym;
      resetAllRefs();
      setLastSym(sym);
    }

    queryStreamingCandlesData();
  }, [
    streamingToken,
    bottomCandlesType,
    showExtendedHrs,
    startDate,
    endDate,
    sym,
    selectedLensesStr,
    showTotal,
    candleDuration,
    enabled,
  ]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    let retries = 0;
    const maxRetries = 3;
    async function fetchManifest() {
      const response = await fetchStreamingEndpoint('/manifest');
      const data = await response.json();
      setHiroManifest({
        ...data,
        underlyingsSet: new Set(data.underlyings),
        combosSet: new Set(data.combos.map((hash: any) => hash.combo)),
      });
    }

    try {
      if (streamingToken == null || hiroManifest != null) {
        return;
      }
      fetchManifest();
    } catch (err) {
      retries += 1;
      if (retries < maxRetries) {
        setTimeout(() => fetchManifest(), 3000);
      }
    }
  }, [streamingToken, enabled]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const selectedLenses = getSelectedLenses(selectedLenseMap);
    if (isDataLoadedForLenses(selectedLenses)) {
      for (const lense of selectedLenses) {
        for (const option of showTotal ? ['TOT'] : ['C', 'P']) {
          calculateRolledCandles(lense, option);
        }
      }
    }
  }, [selectedLenseMap, showTotal, rollingSeconds, enabled]);

  const candleToHistogramEntry = (candle: any) => {
    const alpha = 0.6;
    let color = theme.palette.hiro.bottomCandles.absolute;

    if (bottomCandlesType === BottomCandlesType.FILTERED_DELTA) {
      color = candle.close > 0 ? positiveTrendColor : negativeTrendColor;
    }

    return {
      value: Math.abs(candle.close),
      time: candle.time,
      color: hexToRGBA(color, alpha),
    };
  };

  const getHistogramData = (lense: Lense) => {
    let arr: any[] = [];
    if (bottomCandlesType === BottomCandlesType.FILTERED_DELTA) {
      arr = getUnrolledCandles(lense, 'TOT');
    } else if (bottomCandlesType === BottomCandlesType.ABSOLUTE_DELTA) {
      arr = getBottomCandles(lense);
    }

    if (!arr?.length) {
      return [];
    }

    return arr.map((candle: any) => candleToHistogramEntry(candle));
  };

  const currentTimeAsCandleTime = () => {
    return roundToNearestCandleBucket(utcMsToEtSecs(dayjs().tz(ET).valueOf()));
  };

  function updatePrice(newPrice: any) {
    if (!newPrice.value) {
      return;
    }

    try {
      let price = newPrice;
      const currPrices = getPrices();

      if (currPrices.length > 0) {
        const lastPrice = currPrices[currPrices.length - 1];
        let newPriceTime = roundToNearestCandleBucket(newPrice.time);
        if (newPriceTime < lastPrice.time) {
          // we already have a more recent price, so no need to update
          return;
        }

        if (newPriceTime === lastPrice.time) {
          // remove last time from data arr to avoid having duplicates
          currPrices.splice(currPrices.length - 1, 1);
        }

        price = {
          ...newPrice,
          time: newPriceTime,
        };
      }
      update(priceSeriesRef.current, [price]);
      currPrices.push(price);
    } catch (e: any) {
      logError(e, 'updatePrice');
    }
  }

  return {
    getPrices,
    getHistogramData,
    getRolledCandles,
    getUnrolledCandles,
    candleToHistogramEntry,
    roundToNearestCandleBucket,
    currentTimeAsCandleTime,
  };
};

export default useStreaming;
