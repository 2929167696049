import { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useTheme } from '@mui/material/styles';
import { useRecoilState } from 'recoil';
import useSkew from '../../../hooks/equityhub/useSkew';
import { skewState } from '../../../states';
import { RawSkewObject, VolSkew } from '../../../types';
import {
  DELTA_TICK_CONFIG,
  formatAsDelta,
  formatAsPercentage,
  getIVForTgtDelta,
  getTicks,
} from '../../../util';
import { Box, Typography } from '@mui/material';
import { ColorMode } from '../../../theme';
import {
  DEFAULT_CHART_MARGINS,
  DEFAULT_X_AXIS_STYLES,
  DEFAULT_Y_AXIS_STYLES,
  DEFAULT_ZOOM_CONFIG,
} from 'config';
import { Loader } from 'components/shared';
import ChartWatermarkContainer from 'components/shared/ChartWatermarkContainer';
import { getDeltaFromX, getDeltaX } from 'util/iVol';

// prettier-ignore
const DELTAS = [
  0.99, 0.98, 0.97, 0.96, 0.95, 0.90, 0.85, 0.80, 0.75,
  0.70, 0.65, 0.60, 0.55, 0.50, 0.45, 0.40, 0.35, 0.30,
  0.25, 0.20, 0.15, 0.10, 0.05, 0.04, 0.03, 0.02, 0.01,
];

export const SkewGraph = () => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [dates, setDates] = useState<string[]>([]);
  const { getSkew } = useSkew();
  const [skewData, setSkewData] = useRecoilState(skewState);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);

        const rawSkew: RawSkewObject | null = await getSkew();

        let newSkewData: VolSkew[] = [];
        let dates: string[] = [];

        if (rawSkew) {
          const td1 = dayjs(rawSkew.today.next.day).format('MM-DD');
          const td2 = dayjs(rawSkew.yesterday.next.day).format('MM-DD');

          dates = [td1, td2];
          newSkewData = DELTAS.map((delta) => {
            const getIV = getIVForTgtDelta.bind(global, delta);
            return {
              [`Next Expiration IV ${td1}`]: getIV(rawSkew.today.next.greeks),
              [`30d IV ${td1}`]: getIV(rawSkew.today.thirty.greeks),
              [`Next Expiration IV ${td2}`]: getIV(
                rawSkew.yesterday.next.greeks,
              ),
              [`30d IV ${td2}`]: getIV(rawSkew.yesterday.thirty.greeks),
              strike: -1, // unused
              moneyness: -1, // unused
              delta,
              'delta-x': getDeltaX(delta),
            };
          });
        }

        setDates(dates);
        setSkewData(newSkewData);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [getSkew, setSkewData]);

  const ticks = useMemo(() => {
    const TICKS = [0.01, 0.05, 0.1, 0.25, 0.5, 0.75, 0.9, 0.95, 0.99];
    return TICKS.map(getDeltaX);
  }, [skewData]);

  const tickFormatter = (value: number) => formatAsDelta(getDeltaFromX(value));

  if (!skewData) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {loading ? (
          <Loader isLoading={loading} />
        ) : (
          <Typography variant="h2" gutterBottom color="text.primary">
            Failed to get skew data.
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <ChartWatermarkContainer ref={ref} size={15} offsetX={50} offsetY={35}>
      <ResponsiveContainer>
        <LineChart data={skewData} margin={DEFAULT_CHART_MARGINS}>
          <CartesianGrid
            strokeDasharray={
              theme.colorMode === ColorMode.LIGHT ? '2 8' : '0.3 8'
            }
          />
          <XAxis
            type="number"
            dataKey="delta-x"
            tick={{ fontSize: 11 }}
            label={{
              value: 'Delta',
              ...DEFAULT_X_AXIS_STYLES,
            }}
            domain={['dataMin', 'dataMax']}
            ticks={ticks}
            tickFormatter={tickFormatter}
          />
          <YAxis
            domain={[
              (dataMin: number) => Math.max(dataMin - 0.03, 0),
              (dataMax: number) => dataMax + 0.03,
            ]}
            type="number"
            tick={{ fontSize: 11 }}
            label={{
              value: 'Implied Volatility',
              ...DEFAULT_Y_AXIS_STYLES,
            }}
            tickFormatter={(value: number) => formatAsPercentage(value)}
          />
          <Tooltip
            itemStyle={{ fontSize: '11px' }}
            contentStyle={{
              color: theme.palette.text.primary,
              border: 'none',
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.palette.shadows.paperBoxShadow,
            }}
            separator=": "
            labelFormatter={(label) => tickFormatter(label)}
            formatter={(v: string, _name: string) =>
              formatAsPercentage(parseFloat(v))
            }
          />
          <Legend wrapperStyle={{ fontSize: '11px', paddingTop: 15 }} />
          <Line
            type="monotone"
            dataKey={`Next Expiration IV ${dates[0]}`}
            stroke={theme.palette.equityHub.skew.nextExpFirst}
            activeDot={{ r: 4 }}
            strokeWidth={2.5}
            legendType="plainline"
            dot={false}
            connectNulls
          />
          <Line
            type="monotone"
            dataKey={`30d IV ${dates[0]}`}
            strokeWidth={2.5}
            stroke={theme.palette.equityHub.skew.allFirst}
            activeDot={{ r: 4 }}
            legendType="plainline"
            dot={false}
            connectNulls
          />
          <Line
            type="monotone"
            dataKey={`Next Expiration IV ${dates[1]}`}
            strokeWidth={2.5}
            stroke={theme.palette.equityHub.skew.nextExpSecond}
            strokeDasharray="8 8"
            activeDot={{ r: 4 }}
            dot={false}
            connectNulls
          />
          <Line
            type="monotone"
            dataKey={`30d IV ${dates[1]}`}
            strokeWidth={2.5}
            stroke={theme.palette.equityHub.skew.allSecond}
            strokeDasharray="8 8"
            activeDot={{ r: 4 }}
            dot={false}
            connectNulls
          />
        </LineChart>
      </ResponsiveContainer>
    </ChartWatermarkContainer>
  );
};
