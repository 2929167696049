import { Stack, Typography } from '@mui/material';
import NotFound from 'icons/NotFound';

export const EmptyResultsOverlay = () => {
  return (
    <Stack
      gap={2}
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
      }}
    >
      <NotFound
        sx={{
          width: {
            xs: 70,
            md: 100,
          },
          height: {
            xs: 45,
            md: 70,
          },
        }}
        aria-hidden
        focusable="false"
      />
      <Typography>No Matching Results</Typography>
    </Stack>
  );
};
