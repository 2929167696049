import { alpha, SelectChangeEvent, useTheme } from '@mui/material';
import {
  ExpandableContentWrapper,
  GammaVannaModelChart,
  GammaLevels,
  ComboStrikesChart,
  TiltChart,
  ExpConcentrationChart,
  ZeroDTEChart,
  OIVolumeChart,
  ExpirationConcentrationTable,
  StrikeConcentrationTable,
} from 'components';
import { EarningsCalendar } from 'components/home/EarningsCalendar';
import { FuturesChart } from 'components/home/FuturesChart';
import { MacroCalendar } from 'components/home/MacroCalendar';
import { HistoricalChart } from 'components/indices/charts/HistoricalChart';
import { MaxRealVolChart } from 'components/indices/charts/MaxRealVolChart';
import { OptionsRiskReversalChart } from 'components/indices/charts/OptionsRiskReversalChart';
import { RealVolHistogramChart } from 'components/indices/charts/RealVolHistogramChart';
import { VolatilityChart } from 'components/indices/charts/VolatilityChart';
import { EarningsChart } from 'components/resources/EarningsChart';
import { EquityPCRatioChart } from 'components/sentiment/EquityPCRatioChart';
import { OCCChart } from 'components/sentiment/OCC';
import {
  GammaVannaModel,
  HomeEventsCalendarTab,
  HomeQuadrantTabId,
  IndicesContentType,
  QuadrantTab,
  Scanner,
  SentimentTab,
} from 'types';
import { getDefaultSymOptions } from 'util/indices';
import AtSpotgamma from '../HomeTabs/AtSpotgamma';
import IndexLevels from '../HomeTabs/IndexLevels';
import IndexMetrics from '../HomeTabs/IndexMetrics';
import KeySuppResistanceStrikes from '../HomeTabs/KeySuppResistanceStrikes';
import ScannerTable from '../HomeTabs/ScannerTable';
import SGPropietaryLevels from '../HomeTabs/SGPropietaryLevels';

interface ContentWrapperProps {
  tab: QuadrantTab;
  onSymChange?: (newSym: string, tabId: string) => Promise<void>;
  symChangeLoadingMap?: Map<string, boolean>;
}

const ContentWrapper = ({
  tab,
  symChangeLoadingMap,
  onSymChange,
}: ContentWrapperProps) => {
  const theme = useTheme();

  const defaultChartStyles = {
    padding: '12px',
    borderRadius: '8px',
    border: `1px solid ${alpha(theme.palette.primary.main, 0.35)}`,
  };

  const defaultContentContainerStyles = {
    backgroundColor: theme.palette.background.default,
    padding: '12px',
    borderRadius: '8px',
  };

  const contentForTypeMap = new Map<string, JSX.Element>([
    [HomeQuadrantTabId.INDEX_LEVELS, <IndexLevels />],
    [HomeQuadrantTabId.SG_LEVELS, <SGPropietaryLevels />],
    [HomeQuadrantTabId.INDEX_METRICS, <IndexMetrics />],
    [HomeQuadrantTabId.SUPP_RESISTANCE_LEVELS, <KeySuppResistanceStrikes />],
    [HomeQuadrantTabId.LIVE_ES_FUTURES_CHART, <FuturesChart />],
    [HomeQuadrantTabId.MACRO_ECONOMIC_CALENDAR, <MacroCalendar />],
    [HomeQuadrantTabId.EARNINGS, <EarningsCalendar />],
    [
      HomeQuadrantTabId.EARNINGS_CHART,
      <ExpandableContentWrapper
        type={HomeEventsCalendarTab.EARNINGS_CHART}
        styleOverrides={{
          p: 0,
          width: '100%',
          // chart is weird, 100% height makes it extend infinitely, but 98% does not
          height: '98%',
        }}
      >
        <EarningsChart chartOnly={true} />
      </ExpandableContentWrapper>,
    ],
    [
      HomeQuadrantTabId.SCANNER_SQUEEZE_CANDIDATES,
      <ScannerTable scanner={Scanner.SQUEEZE} />,
    ],
    [
      HomeQuadrantTabId.SCANNER_VRP_CANDIDATES,
      <ScannerTable scanner={Scanner.VOL_RISK_PREMIUM} />,
    ],
    [HomeQuadrantTabId.AT_SPOTGAMMA, <AtSpotgamma />],
    [
      HomeQuadrantTabId.OCC_CHART,
      <ExpandableContentWrapper
        type={SentimentTab.OCC}
        styleOverrides={{
          p: 1,
          height: '100%',
        }}
      >
        <OCCChart />
      </ExpandableContentWrapper>,
    ],
    [
      HomeQuadrantTabId.ZERO_DTE_EQUITY_VOL_CHART,
      <ExpandableContentWrapper
        type={SentimentTab.ZeroDTE}
        styleOverrides={{
          p: 1,
          height: '100%',
        }}
      >
        <ZeroDTEChart isSentiment={true} selectedSym={tab.sym ?? 'SPX'} />
      </ExpandableContentWrapper>,
    ],
    [
      HomeQuadrantTabId.EQUITY_PUT_CALL_RATIO_CHART,
      <ExpandableContentWrapper
        type={SentimentTab.EquityPutCallRatio}
        styleOverrides={{
          p: 1,
          height: '100%',
        }}
      >
        <EquityPCRatioChart />
      </ExpandableContentWrapper>,
    ],
    [
      HomeQuadrantTabId.EXP_CONCENTRATION_CHART,
      <ExpandableContentWrapper
        type={SentimentTab.ExpirationConcentration}
        styleOverrides={{
          p: 1,
          height: '100%',
        }}
      >
        <ExpConcentrationChart selectedSym={tab.sym ?? 'SPX'} />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.GAMMA_MODEL, // gamma model
      <ExpandableContentWrapper type={IndicesContentType.GAMMA_MODEL}>
        <GammaVannaModelChart
          model={GammaVannaModel.GAMMA}
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym != null &&
            onSymChange != null && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(IndicesContentType.GAMMA_MODEL),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.DELTA_MODEL, // delta model
      <ExpandableContentWrapper type={IndicesContentType.DELTA_MODEL}>
        <GammaVannaModelChart
          model={GammaVannaModel.DELTA}
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(IndicesContentType.DELTA_MODEL),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.VANNA_MODEL, // vanna model
      <ExpandableContentWrapper type={IndicesContentType.VANNA_MODEL}>
        <GammaVannaModelChart
          model={GammaVannaModel.VANNA}
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(IndicesContentType.VANNA_MODEL),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.GAMMA_LEVELS, // gamma levels
      <ExpandableContentWrapper type={IndicesContentType.GAMMA_LEVELS}>
        <GammaLevels
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(IndicesContentType.GAMMA_LEVELS),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.COMBO_STRIKES, // combo strikes
      <ExpandableContentWrapper type={IndicesContentType.COMBO_STRIKES}>
        <ComboStrikesChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(IndicesContentType.COMBO_STRIKES),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.TILT, // gamma tilt
      <ExpandableContentWrapper type={IndicesContentType.TILT}>
        <TiltChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(IndicesContentType.TILT),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.EXP_CONCENTRATION, // Expiration Concentration
      <ExpandableContentWrapper type={IndicesContentType.EXP_CONCENTRATION}>
        <ExpConcentrationChart
          isIndices
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(
                  IndicesContentType.EXP_CONCENTRATION,
                ),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.ZERO_DTE, // 0DTE Volume/Open Interest
      <ExpandableContentWrapper type={IndicesContentType.ZERO_DTE}>
        <ZeroDTEChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(IndicesContentType.ZERO_DTE),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.VOLFORECAST_MODEL, // SIV Index
      <ExpandableContentWrapper type={IndicesContentType.VOLFORECAST_MODEL}>
        <GammaVannaModelChart
          model={GammaVannaModel.VOLFORECAST}
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(
                  IndicesContentType.VOLFORECAST_MODEL,
                ),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.OPTIONS_RISK_REVERSAL_CHART, // Risk Reversal
      <ExpandableContentWrapper
        type={IndicesContentType.OPTIONS_RISK_REVERSAL_CHART}
      >
        <OptionsRiskReversalChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(
                  IndicesContentType.OPTIONS_RISK_REVERSAL_CHART,
                ),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.MAX_REAL_VOL, // price vs volatility
      <ExpandableContentWrapper type={IndicesContentType.MAX_REAL_VOL}>
        <MaxRealVolChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(IndicesContentType.MAX_REAL_VOL),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.VOLATILITY, // price vs volatility
      <ExpandableContentWrapper type={IndicesContentType.VOLATILITY}>
        <VolatilityChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(IndicesContentType.VOLATILITY),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.REAL_VOLATILITY, // price vs volatility
      <ExpandableContentWrapper type={IndicesContentType.REAL_VOLATILITY}>
        <RealVolHistogramChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(
                  IndicesContentType.REAL_VOLATILITY,
                ),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.OI_VOLUME, // OI & Volume
      <ExpandableContentWrapper type={IndicesContentType.OI_VOLUME}>
        <OIVolumeChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(IndicesContentType.OI_VOLUME),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.HISTORICAL_CHART, // gamma model
      <ExpandableContentWrapper type={IndicesContentType.HISTORICAL_CHART}>
        <HistoricalChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(
                  IndicesContentType.HISTORICAL_CHART,
                ),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.EXP_CONCENTRATION_TABLE, // gamma model
      <ExpandableContentWrapper
        type={IndicesContentType.EXP_CONCENTRATION_TABLE}
      >
        <ExpirationConcentrationTable
          containerStyleOverrides={{
            ...defaultContentContainerStyles,
          }}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(
                  IndicesContentType.EXP_CONCENTRATION_TABLE,
                ),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.STRIKE_CONCENTRATION_TABLE, // gamma model
      <ExpandableContentWrapper
        type={IndicesContentType.STRIKE_CONCENTRATION_TABLE}
      >
        <StrikeConcentrationTable
          containerStyleOverrides={{
            ...defaultContentContainerStyles,
          }}
          selectedSym={tab.sym ?? 'SPX'}
          {...(tab.sym &&
            onSymChange && {
              symSelectorSettings: {
                value: tab.sym,
                options: getDefaultSymOptions(
                  IndicesContentType.STRIKE_CONCENTRATION_TABLE,
                ),
                loading: symChangeLoadingMap?.get(tab.id),
                onChange: (event: SelectChangeEvent) =>
                  onSymChange(event.target.value, tab.id),
              },
            })}
        />
      </ExpandableContentWrapper>,
    ],
  ]);

  return contentForTypeMap.get(tab.contentId ?? tab.id) ?? null;
};

export default ContentWrapper;
