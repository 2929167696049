import { useRecoilCallback, useSetRecoilState } from 'recoil';
import {
  userDetailsState,
  userSettingsState,
  watchlistsState,
} from '../../states';
import {
  HiroChartSettings,
  SGSettings,
  UserDetails,
  UserDetailsPayload,
} from '../../types';
import { safeMerge } from '../../util';
import useLog from '../useLog';
import { useCallback } from 'react';

const useUserDetails = () => {
  const _setUserDetails = useSetRecoilState(userDetailsState);
  const { fetchAPIWithLog } = useLog('useUserDetails');
  const setSettings = useSetRecoilState(userSettingsState);
  const setWatchlists = useSetRecoilState(watchlistsState);

  // there is a potential bug where if we call setUserDetails in two components simultaneously, one change
  // will overwrite the other unless we pass in a callback to setUserDetails, since the userDetails
  // in each component will not have updated to reflect the change in the other. to prevent this,
  // enforce a callback as the required parameter to setUserDetails unless we're setting it to undefined
  const setUserDetails = (
    undefinedOrCb:
      | undefined
      | ((newDetails: UserDetails | undefined) => UserDetails | undefined),
  ) => {
    return _setUserDetails(undefinedOrCb);
  };

  // should only be used when initializing userDetails for the first time or setting it to undefined
  const initUserDetails = useCallback(
    (payload: UserDetailsPayload | undefined) => {
      // updateChartSettingsStatesAfterInit(newUserDetails);
      if (payload == null) {
        return setUserDetails(undefined);
      }
      const { watchlists, settings, ...userDetails } = payload;
      setUserDetails((_) => userDetails);
      setWatchlists(watchlists);
      setSettings(settings ?? {});
    },
    [_setUserDetails],
  );

  const setPartialUserDetails = (partialDetails: Partial<UserDetails>) => {
    setUserDetails((prevDetails) =>
      safeMerge(prevDetails ?? {}, partialDetails),
    );
  };

  const saveSgSettings = useRecoilCallback(
    ({ snapshot }) =>
      async (sgSettings: Partial<SGSettings>, shouldAwait = false) => {
        try {
          const [userDetails, settings] = await Promise.all([
            snapshot.getPromise(userDetailsState),
            snapshot.getPromise(userSettingsState),
          ]);

          if (userDetails == null) {
            return;
          }
          // merge here wont work if intentionally removing a setting
          // will need to modify this if that ends up being something we do
          const newSettings = safeMerge(settings ?? {}, sgSettings);
          const url = `v1/users/settings`;
          const opts = {
            method: 'POST',
            body: JSON.stringify({ settings: newSettings }),
          };
          const request = fetchAPIWithLog(url, opts);
          let response;
          if (shouldAwait) {
            response = await request;
            if (response.error != null) {
              return response;
            }
          }

          setSettings(newSettings);
          return response;
        } catch (err) {
          return { error: err };
        }
      },
    [],
  );

  const changeHiroChartSetting = (newSettings: Partial<HiroChartSettings>) => {
    const chartSettings = { ...newSettings };
    saveSgSettings({ hiro: { chartSettings } });
  };

  return {
    setUserDetails,
    setPartialUserDetails,
    saveSgSettings,
    initUserDetails,
    changeHiroChartSetting,
  };
};

export default useUserDetails;
