import { AlertCategory } from './alerts';
import { HiroChartSettings } from './hiro';
import { ColorMode } from '../theme';
import { QuadrantId, Quadrant } from './shared';

export interface WordpressDetails {
  active_memberships: any[];
  active_txn_count: number;
  address: any;
  display_name: string;
  expired_txn_count: string;
  first_txn: any;
  latest_txn: any;
  login_count: string;
  nicename: string;
  profile: {
    mepr_are_you_an_institution: string;
    mepr_bypass_discord: string;
    mepr_discord_username: string;
    mepr_equity_hub_waitlist_tickers: string;
    mepr_negative_trend_color: string;
    mepr_phone_number: string;
    mepr_positive_trend_color: string;
    mepr_what_type_of_investor_are_you: string;
    mepr_modern_dashboard_access: string;
    mepr_color_mode: string;
    mepr_play_alert_audio: string;
  };
  recent_subscriptions: any[];
  recent_transactions: Payment[];
  registered_at: string;
  sub_count: any;
  trial_txn_count: string;
  url: string;
  wp_user: {
    avatar_urls: { 24: string; 48: string; 96: string };
    description: string;
    id: number;
    link: string;
    meta: {
      mepr_equity_hub_waitlist_tickers: string;
      mepr_positive_trend_color: string;
      mepr_negative_trend_color: string;
      mepr_discord_username: string;
      mepr_modern_dashboard_access: string;
      mepr_color_mode: string;
    };
    name: string;
    slug: string;
    url: string;
    yoast_head: string;
    yoast_head_json: any;
    roles: string[];
  };
}

interface BloombergDetails {
  firmId: string;
  loginType: string;
  uuid: string;
  nonce: string;
  sub: string;
}

// increment this everytime you change UserDetails structure such that
// previously cached object will no longer be valid
export const USER_DETAILS_STORAGE_KEY = `userDetails_1`;

export interface UserDetails {
  email: string;
  firstName: string;
  lastName: string;
  id: number; // wordpress or bloomberg id
  sgId: string; // spotgamma user id (UUID)
  username: string;
  isInstitutional?: boolean;
  integrationsKey?: string;
  wordpress?: WordpressDetails;
  bloomberg?: BloombergDetails;
}

export type UserDetailsPayload = UserDetails & {
  settings?: SGSettings;
  watchlists: Watchlist[];
};

// why does this exist?
// watchlist/worksheet middle layer code expects that both worksheets and watchlists
// have a 'name' and 'symbols' property, both named the same and typed the same
// we want to enforce this in frontend code to ensure we dont break the middle layer code
type NamedSymbolsList = {
  symbols: string[];
  name: string;
};

// a worksheet is what we get from bloomberg directly.
// symbols are parsed to be market-correct symbols (AAPL US Equity -> AAPL, no bonds/currencies)
// but it has no alerts settings, no server watchlist id
// generally we do not want to use BbgWorksheet but rather Watchlist
export type BbgWorksheet = NamedSymbolsList & {
  id: string;
};

// a watchlist has the same symbols as its corresponding worksheet
// but is returned from the server along with alerts settings and sg watchlist id
// once we upload the worksheets we get from bbg to our server
// we usually want to use this, not worksheets
export type Watchlist = NamedSymbolsList & {
  id: number | null;
  soundDisabled?: boolean | null;
  disabledAlertCategories?: AlertCategory[] | null;
};

export enum SubLevel {
  NONE = 0,
  STANDARD = 1,
  PRO = 2,
  ALPHA = 3,
}

export enum DashAccess {
  DEFAULT = '',
  BETA = 'beta',
  INTERNAL = 'internal',
}

const ACCESS_LEVELS = new Map([
  [DashAccess.DEFAULT, 0],
  [DashAccess.BETA, 1],
  [DashAccess.INTERNAL, 2],
]);
export const hasDashAccess = (userAccess: DashAccess, target: DashAccess) =>
  ACCESS_LEVELS.get(userAccess)! >= ACCESS_LEVELS.get(target)!;

export interface AccountDetails {
  // these need to be snake case in order for saving preferences to work
  first_name: string;
  last_name: string;
  email: string;
}

export enum PreferencesTab {
  Account = 'account',
  Colors = 'colors',
  Payments = 'transactions',
  Watchlists = 'watchlists',
  Alerts = 'alerts',
}

export interface Payment {
  membership: string;
  member: string;
  coupon: string;
  subscription: string;
  id: string;
  amount: string;
  total: string;
  tax_amount: string;
  tax_rate: string;
  tax_desc: string;
  tax_class: string;
  trans_num: string;
  status: string;
  txn_type: string;
  gateway: string;
  prorated: string;
  created_at: string;
  expires_at: string;
  corporate_account_id: string;
  parent_transaction_id: string;
  order_id: string;
  tax_compound: string;
  tax_shipping: string;
  response: null;
}

// increment this everytime you change Settings structure
// if just adding a key/property, you dont need to do this since we return a Partial, so we expect some empty values.
// however, if you are changing the structure of the settings themselves such that a load of a previously cached
// settings object no longer would conform to the new settings structure, you need to increment this key so that the
// previously cached object will no longer be valid and things dont break
export const SG_SETTINGS_STORAGE_KEY = `sgSettings_1`;

export type SGSettings = {
  hiro: {
    tabs?: string[];
    chartSettings?: Partial<HiroChartSettings>;
  };
  timezone?: string;
  playAlertAudio?: boolean;
  disabledAlertCategories?: AlertCategory[];
  selectedWatchlistIds?: number[];
  colors?: {
    positiveTrend?: string;
    negativeTrend?: string;
    colorMode?: ColorMode;
  };
  tickersHidden?: boolean;
  homeQuadrants?: { [key in QuadrantId]: Quadrant };
  indicesQuadrants?: { [key in QuadrantId]: Quadrant };
};
