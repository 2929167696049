import { Stack, Typography } from '@mui/material';

import {
  dayjs,
  formatAsPercentage,
  getDateFormatted,
  getAvg,
  isBloomberg,
  prevBusinessDay,
  nextBusinessDay,
  nullsToEndComparator,
} from '../../../util';
import { Theme, alpha } from '@mui/material/styles';
import { useCallback, useMemo } from 'react';
import { formatAsCompactNumber } from '../../../util';
import * as d3 from 'd3';
import {
  GridColDef,
  GridColumnGroup,
  GridColumnGroupHeaderParams,
  GridColumnHeaderParams,
  gridDateComparator,
  gridNumberComparator,
  GridRenderCellParams,
  gridStringOrNumberComparator,
} from '@spotgamma/x-data-grid-premium';
import {
  COLUMN_TO_GROUP_MAPPING,
  historyOnlyFields,
  scannerOnlyFields,
} from 'config';
import { useRecoilValue } from 'recoil';
import {
  isMobileState,
  negativeTrendColorState,
  positiveTrendColorState,
  timezoneState,
} from 'states';
import { SGTooltip } from 'components/core';
import { ScannerColumnKey } from 'types';
import RowWatchlistBtn from 'components/stock_scanner/RowWatchlistBtn';

export const enum EquityTableType {
  History,
  StockScreener,
}

export const useEquityGridColumns = (
  theme: Theme,
  dpiRange: number[] | undefined,
  tableType: EquityTableType,
  blurredRowIds?: string[],
) => {
  const isMobile = useRecoilValue(isMobileState);
  const currentTimezone = useRecoilValue(timezoneState);

  const serverPositiveTrendColor: string = useRecoilValue(
    positiveTrendColorState,
  );
  const serverNegativeTrendColor: string = useRecoilValue(
    negativeTrendColorState,
  );

  const dpiColorScale = useMemo(
    () => dpiRange && d3.scaleSequential(d3.interpolateRdYlGn).domain(dpiRange),
    [dpiRange],
  );

  const valOrNa = (val: any) => {
    if (val == null || val === false) {
      // still want to show 0s
      return '';
    }
    return val;
  };

  const history = tableType === EquityTableType.History;

  const defaultHeaderTitleStyles = {
    fontSize: isMobile ? 11 : 13,
    color: theme.palette.sgGreen,
    textTransform: 'capitalize',
    textAlign: 'right',
    whiteSpace: 'normal',
    lineHeight: 'normal',
  };

  const getColHeaderStyles = useCallback(
    (params: GridColumnHeaderParams) => ({
      ...defaultHeaderTitleStyles,
      color: COLUMN_TO_GROUP_MAPPING[
        params.field as keyof typeof COLUMN_TO_GROUP_MAPPING
      ]
        ? alpha(
            theme.palette.equityHubColumns[
              COLUMN_TO_GROUP_MAPPING[
                params.field as keyof typeof COLUMN_TO_GROUP_MAPPING
              ]
            ],
            0.85,
          )
        : 'inherit',
    }),
    [defaultHeaderTitleStyles, theme.palette.equityHubColumns],
  );

  const getHistoryTextColor = useCallback(
    (params: GridRenderCellParams, field: ScannerColumnKey) => {
      if (!history) {
        return 'inherit';
      }

      // Assuming id is in the YYYY-MM-DDTHH:mm:ss.SSSZ format since historical table uses trade date as ids
      const currentDayKey = params.id;
      const previousDayKey = prevBusinessDay(dayjs(currentDayKey).utc()).format(
        'YYYY-MM-DDT00:00:00.000[Z]',
      );

      // Accessing the previous day's row
      const previousDayRow = params.api.getRow(previousDayKey);

      if (!previousDayRow || params.value === previousDayRow?.[field]) {
        return 'inherit';
      }

      return params.value < previousDayRow?.[field]
        ? serverNegativeTrendColor
        : serverPositiveTrendColor;
    },
    [history, serverNegativeTrendColor, serverPositiveTrendColor],
  );

  const getColoredCell = useCallback(
    (params: GridRenderCellParams) => (
      <Typography
        sx={{
          color: getHistoryTextColor(params, params.field as ScannerColumnKey),
          ...getBlurredStyles(params),
        }}
      >
        {valOrNa(params.value)}
      </Typography>
    ),
    [getHistoryTextColor],
  );

  const columnGroups: GridColumnGroup[] = history
    ? []
    : [
        {
          groupId: 'general_ticker_info',
          headerName: 'Ticker Information',
          description: '',
          headerClassName: 'custom-group-header',
          freeReordering: true,
          children: [
            { field: ScannerColumnKey.price },
            { field: ScannerColumnKey.prevClose },
            { field: ScannerColumnKey.volume },
            { field: ScannerColumnKey.wkHigh52 },
            { field: ScannerColumnKey.wkLow52 },
            { field: ScannerColumnKey.earningsDate },
          ],
          renderHeaderGroup: (_params: GridColumnGroupHeaderParams) => (
            <Typography
              sx={{
                ...defaultHeaderTitleStyles,
                fontWeight: 'bold',
                color: theme.palette.equityHubColumns.STATISTICS,
              }}
            >
              Ticker Information
            </Typography>
          ),
        },
        {
          groupId: 'sg_key_daily_levels',
          headerName: 'SpotGamma Key Daily Levels',
          description: '',
          headerClassName: 'custom-group-header',
          freeReordering: true,
          children: [
            { field: ScannerColumnKey.keyGammaStr },
            { field: ScannerColumnKey.keyDeltaStr },
            { field: ScannerColumnKey.hedgeWall },
            { field: ScannerColumnKey.callWall },
            { field: ScannerColumnKey.putWall },
            { field: ScannerColumnKey.callGamma },
            { field: ScannerColumnKey.putGamma },
            { field: ScannerColumnKey.callDelta },
            { field: ScannerColumnKey.putDelta },
            { field: ScannerColumnKey.nextExpGamma },
            { field: ScannerColumnKey.nextExpDelta },
            { field: ScannerColumnKey.topGammaExp },
            { field: ScannerColumnKey.topDeltaExp },
            { field: ScannerColumnKey.callVol },
            { field: ScannerColumnKey.putVol },
            { field: ScannerColumnKey.nextExpCallVol },
            { field: ScannerColumnKey.nextExpPutVol },
          ],
          renderHeaderGroup: (_params: GridColumnGroupHeaderParams) => (
            <Typography
              sx={{
                ...defaultHeaderTitleStyles,
                fontWeight: 'bold',
                color: theme.palette.equityHubColumns.SG_KEY_DAILY_LEVELS,
              }}
            >
              SpotGamma Key Daily Levels
            </Typography>
          ),
        },
        {
          groupId: 'directional_indicators',
          headerName: 'Directional Indicators',
          description: '',
          headerClassName: 'custom-group-header',
          freeReordering: true,
          children: [
            { field: ScannerColumnKey.putCallVolRatio },
            { field: ScannerColumnKey.gammaRatio },
            { field: ScannerColumnKey.deltaRatio },
            { field: ScannerColumnKey.putCallRatio },
          ],
          renderHeaderGroup: (_params: GridColumnGroupHeaderParams) => (
            <Typography
              sx={{
                ...defaultHeaderTitleStyles,
                fontWeight: 'bold',
                color: theme.palette.equityHubColumns.DIRECTIONAL_INDICATORS,
              }}
            >
              Directional Indicators
            </Typography>
          ),
        },
        {
          groupId: 'vol_insights',
          headerName: 'Volatility Insights',
          description: '',
          headerClassName: 'custom-group-header',
          freeReordering: true,
          children: [
            { field: ScannerColumnKey.neSkew },
            { field: ScannerColumnKey.skew },
            { field: ScannerColumnKey.rv30 },
            { field: ScannerColumnKey.iv30 },
            { field: ScannerColumnKey.ivRank },
            { field: ScannerColumnKey.garchRank },
            { field: ScannerColumnKey.skewRank },
            { field: ScannerColumnKey.optionsImpliedMove },
          ],
          renderHeaderGroup: (_params: GridColumnGroupHeaderParams) => (
            <Typography
              sx={{
                ...defaultHeaderTitleStyles,
                fontWeight: 'bold',
                color: theme.palette.equityHubColumns.VOLATILITY_INSIGHTS,
              }}
            >
              Volatility Insights
            </Typography>
          ),
        },
        {
          groupId: 'dp_indicators',
          headerName: 'Dark Pool Indicators',
          description: '',
          headerClassName: 'custom-group-header',
          freeReordering: true,
          children: [
            { field: ScannerColumnKey.dpi },
            { field: ScannerColumnKey.dpiVol },
            { field: ScannerColumnKey.dpi5day },
            { field: ScannerColumnKey.dpi5dayVol },
          ],
          renderHeaderGroup: (_params: GridColumnGroupHeaderParams) => (
            <Typography
              sx={{
                ...defaultHeaderTitleStyles,
                fontWeight: 'bold',
                color: theme.palette.equityHubColumns.DARK_POOL_INDICATORS,
              }}
            >
              Dark Pool Indicators
            </Typography>
          ),
        },
      ];

  const getBlurredStyles = (params: GridRenderCellParams) =>
    blurredRowIds?.includes(params.id as string) ? { filter: 'blur(3px)' } : {};

  const columns: GridColDef[] = useMemo(
    () =>
      (
        [
          {
            headerName: 'Trade Date',
            field: ScannerColumnKey.tradeDate,
            headerClassName: 'grid-header-cell',
            minWidth: 110,
            type: 'date',
            hideable: false,
            getSortComparator: nullsToEndComparator(gridDateComparator),
            valueGetter: (value: string) => {
              return value && dayjs.utc(value).toDate();
            },
            valueFormatter: (value: Date) =>
              `${valOrNa(
                value != null &&
                  getDateFormatted(nextBusinessDay(dayjs(value).utc())),
              )}`,
            renderCell: (params: GridRenderCellParams) => {
              const date = dayjs(params.value).utc();
              return (
                <Typography
                  sx={{
                    whiteSpace: 'normal',
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    date != null && getDateFormatted(nextBusinessDay(date)),
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <Typography
                sx={{
                  ...getColHeaderStyles(params),
                }}
              >
                Trade Date
              </Typography>
            ),
          },
          ...(!isBloomberg()
            ? [
                {
                  headerName: '',
                  type: 'boolean',
                  field: 'isWatchlisted',
                  headerClassName: 'grid-header-cell',
                  width: 45,
                  hideable: false,
                  filterable: false,
                  disableColumnMenu: true,
                  valueFormatter: (value: boolean) => `${valOrNa(value)}`,
                  renderCell: RowWatchlistBtn,
                },
              ]
            : []),
          {
            headerName: 'Symbol',
            field: ScannerColumnKey.sym,
            headerClassName: 'grid-header-cell',
            minWidth: 110,
            hideable: false,
            getSortComparator: nullsToEndComparator(
              gridStringOrNumberComparator,
            ),
            valueFormatter: (value: string) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Stack justifyContent="center">
                  <Stack
                    direction="row"
                    gap={2}
                    alignItems="center"
                    sx={{
                      ...getBlurredStyles(params),
                    }}
                  >
                    <div
                      className="company-photo"
                      style={{
                        backgroundImage: `url("https://financialmodelingprep.com/image-stock/${params.value}.png")`,
                        width: '22px',
                        height: '22px',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        borderRadius: '100%',
                      }}
                    />
                    <Typography>{valOrNa(params.value)}</Typography>
                  </Stack>
                </Stack>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Stock ticker.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Symbol
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Current Price',
            field: ScannerColumnKey.price,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              if (isNaN(params.value)) {
                return null;
              }

              return (
                <Typography
                  color={
                    params.value > params.row.upx
                      ? theme.palette.success.main
                      : 'red'
                  }
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value?.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }),
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Current stock price. If a stock’s current price is trading lower than the previous closing price, the price will be shown in red, and if the stock’s current price is trading higher than the previous closing price, then it will be shown in green. These colors can be customized so may change based on your preferences.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Current Price
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Previous Close',
            field: ScannerColumnKey.prevClose,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value?.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }),
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Price of the stock at yesterday’s close. Our data is modeled using last night's closing price. If the current price is far from the closing than some of our levels may jump or shift accordingly.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Previous Close
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Stock Volume',
            field: ScannerColumnKey.volume,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(params.value?.toLocaleString())}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="This is the total volume of stock traded the previous day.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Stock Volume
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: '52wk High',
            field: ScannerColumnKey.wkHigh52,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && `$${params.value.toFixed(2)}`,
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="This is the highest price the stock reached during the last 52 weeks.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  52wk High
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: '52wk Low',
            field: ScannerColumnKey.wkLow52,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && `$${params.value.toFixed(2)}`,
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="This is the highest price the stock reached during the last 52 weeks.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  52wk Low
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Earnings Date',
            field: ScannerColumnKey.earningsDate,
            type: 'date',
            headerClassName: 'grid-header-cell',
            getSortComparator: nullsToEndComparator(gridDateComparator),
            minWidth: 135,
            valueGetter: (value: string) => {
              return value && dayjs.utc(value).toDate();
            },
            valueFormatter: (value: Date) =>
              valOrNa(
                value != null &&
                  `${dayjs(value.valueOf())
                    .tz(currentTimezone)
                    .format('MM-DD h:mm A')}`,
              ),
            renderCell: (params: GridRenderCellParams) => {
              const date = params.value;
              return (
                <Typography
                  sx={{
                    whiteSpace: 'normal',
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    date != null &&
                      `${dayjs(date.valueOf())
                        .tz(currentTimezone)
                        .format('MM-DD h:mm A')}`,
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="The earnings date and time for the underlying equity.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Earnings Date
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Key Gamma Strike',
            field: ScannerColumnKey.keyGammaStr,
            headerClassName: 'grid-header-cell',
            minWidth: 135,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: getColoredCell,
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Options Strike with the most total gamma. A stock may pin or trade around the strike with the largest gamma position. Further studies suggest that the movement of the key gamma strike is correlated to stock returns.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                    color: alpha(
                      theme.palette.equityHubColumns[
                        COLUMN_TO_GROUP_MAPPING[
                          params.field as keyof typeof COLUMN_TO_GROUP_MAPPING
                        ]
                      ],
                      0.75,
                    ),
                  }}
                >
                  Key Gamma Strike
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Key Delta Strike',
            field: ScannerColumnKey.keyDeltaStr,
            headerClassName: 'grid-header-cell',
            minWidth: 135,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: getColoredCell,
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Options strike with the most total delta. When the key delta strike is in the money it may signal a reversal of a stock after expiration. This is due to large dealer hedges being unwound.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Key Delta Strike
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Hedge Wall',
            field: ScannerColumnKey.hedgeWall,
            headerClassName: 'grid-header-cell',
            minWidth: 125,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: getColoredCell,
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Proprietary SpotGamma level, considered a major support or resistance level, where risk exposure changes significantly for options dealers.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Hedge Wall
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Call Wall',
            field: ScannerColumnKey.callWall,
            headerClassName: 'grid-header-cell',
            minWidth: 112,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: getColoredCell,
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Strike with largest call open interest. This is considered a resistance level for the stock.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Call Wall
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Put Wall',
            field: ScannerColumnKey.putWall,
            headerClassName: 'grid-header-cell',
            minWidth: 112,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: getColoredCell,
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Strike with largest put open interest. This is considered a support level for the stock.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Put Wall
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Call Gamma',
            field: ScannerColumnKey.callGamma,
            headerClassName: 'grid-header-cell',
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            minWidth: 132,
            renderCell: (params: GridRenderCellParams) => (
              <Typography
                sx={{
                  ...getBlurredStyles(params),
                }}
              >
                {valOrNa(
                  params.value != null && formatAsCompactNumber(params.value),
                )}
              </Typography>
            ),
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="The total amount of call gamma for the underlying stock as of the previous close.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Call Gamma
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Put Gamma',
            field: ScannerColumnKey.putGamma,
            headerClassName: 'grid-header-cell',
            minWidth: 132,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && formatAsCompactNumber(params.value),
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="The total amount of put gamma for the underlying stock as of the previous close.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Put Gamma
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Call Delta',
            field: ScannerColumnKey.callDelta,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && formatAsCompactNumber(params.value),
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="The total amount of call delta for the underlying stock as of the previous close.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Call Delta
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Put Delta',
            field: ScannerColumnKey.putDelta,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && formatAsCompactNumber(params.value),
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="The total amount of put delta for the underlying stock as of the previous close.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Put Delta
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Next Expiry Gamma %',
            field: ScannerColumnKey.nextExpGamma,
            headerClassName: 'grid-header-cell',
            minWidth: 145,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null &&
                      `${(params.value * 100).toFixed(2)}%`,
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Percentage of gamma expiring at the next expiration. When the amount of gamma expiring is above 25% it means there is a significant concentration of short dated options positions in the stock. This may magnify the importance of options positioning due to expiration.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Next Expiry Gamma %
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Next Expiry Delta %',
            field: ScannerColumnKey.nextExpDelta,
            headerClassName: 'grid-header-cell',
            minWidth: 135,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null &&
                      `${(params.value * 100).toFixed(2)}%`,
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Percentage of delta expiring at the next expiration. When the amount of delta expiring is above 25% it means there is a significant concentration of short dated options positions in the stock. This may magnify the importance of options positioning due to expiration. If large put deltas are expiring the stock may rally as dealers cover short hedges. If large call deltas are expiring then the stock may sell off after expiration due to dealers selling long hedges.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Next Expiry Delta %
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Top Gamma Expiry',
            field: ScannerColumnKey.topGammaExp,
            type: 'date',
            headerClassName: 'grid-header-cell',
            getSortComparator: nullsToEndComparator(gridDateComparator),
            valueFormatter: (value: Date) =>
              `${valOrNa(value != null && getDateFormatted(value))}`,
            minWidth: 135,
            valueGetter: (value: string) => {
              return value && dayjs.utc(value).toDate();
            },
            renderCell: (params: GridRenderCellParams) => {
              const date = params.value;
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(date != null && getDateFormatted(date))}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Expiration date with the largest gamma notional expiring. Dates with large options expiring may invoke significant options related hedging flows. This suggests that the impact of options related flows may be a key driver of the stock in the days into and out of options expiration.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Top Gamma Expiry
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Top Delta Expiry',
            field: ScannerColumnKey.topDeltaExp,
            type: 'date',
            headerClassName: 'grid-header-cell',
            getSortComparator: nullsToEndComparator(gridDateComparator),
            valueFormatter: (value: Date) =>
              `${valOrNa(value != null && getDateFormatted(value))}`,
            minWidth: 135,
            valueGetter: (value: string) => {
              return value && dayjs.utc(value).toDate();
            },
            renderCell: (params: GridRenderCellParams) => {
              const date = params.value;
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(date != null && getDateFormatted(date))}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Expiration date with the largest delta notional expiring. Dates with large options expiring may invoke significant options related hedging flows. This suggests that the impact of options related flows may be a key driver of the stock in the days into and out of options expiration.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Top Delta Expiry
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Call Volume',
            field: ScannerColumnKey.callVol,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => (
              <Typography
                sx={{
                  ...getBlurredStyles(params),
                }}
              >
                {valOrNa(
                  params.value != null && formatAsCompactNumber(params.value),
                )}
              </Typography>
            ),
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="The total amount of call options from the previous day's trading.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Call Volume
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Put Volume',
            field: ScannerColumnKey.putVol,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => (
              <Typography
                sx={{
                  ...getBlurredStyles(params),
                }}
              >
                {valOrNa(
                  params.value != null && formatAsCompactNumber(params.value),
                )}
              </Typography>
            ),
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="The total amount of put options from the previous day's trading.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Put Volume
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Next Expiry Call Volume',
            field: ScannerColumnKey.nextExpCallVol,
            headerClassName: 'grid-header-cell',
            minWidth: 155,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null &&
                      `${(params.value * 100).toFixed(2)}%`,
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="The percentage of the previous sessions total call volume that was traded in contracts which expire at the next available expiration.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Next Expiry Call Volume
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Next Expiry Put Volume',
            field: ScannerColumnKey.nextExpPutVol,
            headerClassName: 'grid-header-cell',
            minWidth: 155,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null &&
                      `${(params.value * 100).toFixed(2)}%`,
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="The percentage of the previous sessions total put volume that was traded in contracts which expire at the next available expiration.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Next Expiry Put Volume
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Put/Call OI\u00a0Ratio',
            field: ScannerColumnKey.putCallRatio,
            headerClassName: 'grid-header-cell',
            minWidth: 135,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(params.value != null && params.value.toFixed(2))}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Number of puts open interest yesterday, vs number of call open interest for the previous session.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  {'Put/Call OI\u00a0Ratio'}
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Put/Call Volume Ratio',
            field: ScannerColumnKey.putCallVolRatio,
            headerClassName: 'grid-header-cell',
            minWidth: 135,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(params.value != null && params.value.toFixed(2))}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Put Delta:Call Delta Ratio. High/Low put delta to call delta indicates there are larger at-the-money put/call positions current in a stock. When large puts/calls expire it may lead to a move higher/lower in the stock as large stock hedges are unwound.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Put/Call Volume Ratio
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Gamma Ratio',
            field: ScannerColumnKey.gammaRatio,
            headerClassName: 'grid-header-cell',
            minWidth: 132,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(params.value != null && params.value.toFixed(2))}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Total call gamma divided by total put gamma.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Gamma Ratio
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Delta Ratio',
            field: ScannerColumnKey.deltaRatio,
            headerClassName: 'grid-header-cell',
            minWidth: 120,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(params.value != null && params.value.toFixed(2))}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Total call delta divided by total put delta.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Delta Ratio
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'NE Skew',
            field: ScannerColumnKey.neSkew,
            headerClassName: 'grid-header-cell',
            minWidth: 120,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(params.value?.toFixed(2))}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Implied volatility skew for options expiring on the next expiration date. A high NE Skew reading indicates short-term call demand is high relative to put demand. If NE Skew significantly exceeds Skew, that indicates heavy short-term options trading activity.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  NE Skew
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Skew',
            field: ScannerColumnKey.skew,
            headerClassName: 'grid-header-cell',
            minWidth: 118,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(params.value != null && params.value.toFixed(2))}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Implied volatility skew for options expiring in approximately one month. A high Skew reading indicates call demand is high relative to put demand.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Skew
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: '1 M RV',
            field: ScannerColumnKey.rv30,
            headerClassName: 'grid-header-cell',
            minWidth: 115,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && formatAsPercentage(params.value),
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="This represents 1-month realized volatility, or actual volatility experienced by an underlying asset over one month.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  1 M RV
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: '1 M IV',
            field: ScannerColumnKey.iv30,
            headerClassName: 'grid-header-cell',
            minWidth: 115,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && formatAsPercentage(params.value),
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="This represents Implied Volatility, or market expectation of volatility, for the following month.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  1 M IV
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'IV Rank',
            field: ScannerColumnKey.ivRank,
            headerClassName: 'grid-header-cell',
            minWidth: 115,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && formatAsPercentage(params.value),
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="This rank displays how high a stock’s current IV is versus the prior year.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  IV Rank
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Garch Rank',
            field: ScannerColumnKey.garchRank,
            headerClassName: 'grid-header-cell',
            minWidth: 123,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && formatAsPercentage(params.value),
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="The Garch Rank is a proprietary measure of how a stock’s volatility compares to the prior 30 days, ignoring event-specific volume.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Garch Rank
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Skew Rank',
            minWidth: 123,
            headerClassName: 'grid-header-cell',
            field: ScannerColumnKey.skewRank,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && `${params.value.toFixed(2)}`,
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Display how high a stock’s most recent skew (difference between 25 delta call IV / put IV) ranks versus the prior year; high values suggest traders hold a bullish view of the stock, while lower values indicate bearish expectations.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Skew Rank
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Options Implied Move',
            field: ScannerColumnKey.optionsImpliedMove,
            headerClassName: 'grid-header-cell',
            minWidth: 145,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && `$${params.value.toFixed(2)}`,
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Estimated stock move based on current options implied volatility. This is the percentage move options traders are pricing in for the stock over the next 30 days. If you believe the stock may move more or less than the estimated move, you may have a trading edge.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Options Implied Move
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'DPI',
            field: ScannerColumnKey.dpi,
            headerClassName: 'grid-header-cell',
            minWidth: 108,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            valueGetter: (value: number) => value && value * 100,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Stack
                  sx={{
                    backgroundColor: dpiColorScale
                      ? dpiColorScale(params.value)
                      : 'transparent',
                    textAlign: 'end',
                    minWidth: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    ...getBlurredStyles(params),
                  }}
                >
                  <Typography
                    color={
                      params.value != null
                        ? theme.palette.grey[900]
                        : theme.palette.text.primary
                    }
                  >
                    {valOrNa(
                      params.value != null && `${params.value.toFixed(2)}%`,
                    )}
                  </Typography>
                </Stack>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="Dark Pool Indicator assess the short activity within a given stock or index. You can see the DPI color scale to quickly monitor names with the highest DPI % in darker green, indicating the most bullish results, and the lowest DPI % in darker red indicating the most bearish results.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  DPI
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: '% DPI Volume',
            field: ScannerColumnKey.dpiVol,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && `${params.value.toFixed(2)}%`,
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="The number of shares sold short, both in dark pools and on listed exchanges, divided by the total shares outstanding for a given stock.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  % DPI Volume
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: '5 day DPI',
            field: ScannerColumnKey.dpi5day,
            headerClassName: 'grid-header-cell',
            minWidth: 125,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            valueGetter: (value: string) => {
              if (!value) {
                return null;
              }

              const hist_vals = value.split(',');

              return getAvg(
                hist_vals
                  .slice(0, Math.min(5, hist_vals.length))
                  .map((v: string) => parseFloat(v)),
              );
            },
            renderCell: (params: GridRenderCellParams) => {
              const val = params.value;
              let bg = 'transparent';

              if (params.value != null) {
                bg = dpiColorScale ? dpiColorScale(val!) : 'transparent';
              }

              return (
                <Stack
                  sx={{
                    backgroundColor: bg,
                    minWidth: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    ...getBlurredStyles(params),
                  }}
                >
                  <Typography
                    sx={{
                      backgroundColor: bg,
                      textAlign: 'end',
                    }}
                    color={
                      val != null
                        ? theme.palette.grey[900]
                        : theme.palette.text.primary
                    }
                  >
                    {valOrNa(val != null && `${(val * 100).toFixed(2)}%`)}
                  </Typography>
                </Stack>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="The average DPI reading over the last five trading days.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  5 day DPI
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: '5d % DPI Volume',
            field: ScannerColumnKey.dpi5dayVol,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            valueGetter: (value: string) => {
              if (!value) {
                return null;
              }

              const hist_vals = value.split(',');

              return getAvg(
                hist_vals
                  .slice(0, Math.min(5, hist_vals.length))
                  .map((v: string) => parseFloat(v)),
              );
            },
            renderCell: (params: GridRenderCellParams) => {
              const val: number | null = params.value;

              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(val != null && `${val.toFixed(2)}%`)}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title="The average DPI % reading over the last five trading days.">
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  5d % DPI Volume
                </Typography>
              </SGTooltip>
            ),
          },
        ] as GridColDef[]
      ).filter((colDef: GridColDef) =>
        history
          ? !scannerOnlyFields.includes(colDef.field as ScannerColumnKey)
          : !historyOnlyFields.includes(colDef.field as ScannerColumnKey),
      ) as GridColDef[],
    [
      currentTimezone,
      dpiColorScale,
      getColHeaderStyles,
      getColoredCell,
      history,
      theme.palette.equityHubColumns,
      theme.palette.grey,
      theme.palette.success.main,
      theme.palette.text.primary,
    ],
  );

  return {
    columns,
    columnGroups,
  };
};
