import { useCallback } from 'react';
import useLog from '../useLog';

const useDarkPool = () => {
  const { fetchAPIWithLog } = useLog('useDarkPool');
  const getDarkPool = useCallback(async () => {
    return await fetchAPIWithLog(`v4/darkPool`);
  }, []);

  return { getDarkPool };
};

export default useDarkPool;
