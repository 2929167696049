import { INDICES_CONTENT_LABEL_MAP } from 'config';
import {
  IndexSymbol,
  IndicesContentType,
  Quadrant,
  QuadrantId,
  QuadrantTabCategory,
} from 'types';
import { v4 as uuidv4 } from 'uuid';

export const SUPPRESSED_SYMS_SIV = new Set(['NDX', 'RUT']);

export const SUPPRESSED_SYMS_OPTIONS_RISK_REVERSAL = new Set(['IWM', 'RUT']);

export const ALL_INDICES_SYMS = Object.values(IndexSymbol);

export const isContentSuppressed = (
  contentType: IndicesContentType,
  sym: string,
) =>
  (contentType === IndicesContentType.VOLFORECAST_MODEL &&
    SUPPRESSED_SYMS_SIV.has(sym)) ||
  (contentType === IndicesContentType.OPTIONS_RISK_REVERSAL_CHART &&
    SUPPRESSED_SYMS_OPTIONS_RISK_REVERSAL.has(sym));

export const getDefaultSymOptions = (contentType: IndicesContentType) =>
  ALL_INDICES_SYMS.filter(
    (iSymbol: IndexSymbol) => !isContentSuppressed(contentType, iSymbol),
  );

export const getDefaultIndicesQuadrantMap = () => {
  return new Map<QuadrantId, Quadrant>([
    [
      QuadrantId.TOP_LEFT,
      {
        tabs: [
          {
            id: uuidv4(),
            contentId: IndicesContentType.GAMMA_MODEL,
            label: INDICES_CONTENT_LABEL_MAP[IndicesContentType.GAMMA_MODEL],
            category: QuadrantTabCategory.CHART,
            sym: IndexSymbol.SPX,
            symOptions: getDefaultSymOptions(IndicesContentType.GAMMA_MODEL),
          },
          {
            id: uuidv4(),
            contentId: IndicesContentType.DELTA_MODEL,
            label: INDICES_CONTENT_LABEL_MAP[IndicesContentType.DELTA_MODEL],
            category: QuadrantTabCategory.CHART,
            sym: IndexSymbol.SPX,
            symOptions: getDefaultSymOptions(IndicesContentType.DELTA_MODEL),
          },
          {
            id: uuidv4(),
            contentId: IndicesContentType.VANNA_MODEL,
            label: INDICES_CONTENT_LABEL_MAP[IndicesContentType.VANNA_MODEL],
            category: QuadrantTabCategory.CHART,
            sym: IndexSymbol.SPX,
            symOptions: getDefaultSymOptions(IndicesContentType.VANNA_MODEL),
          },
        ],
      },
    ],
    [
      QuadrantId.TOP_RIGHT,
      {
        tabs: [
          {
            id: uuidv4(),
            contentId: IndicesContentType.GAMMA_LEVELS,
            label: INDICES_CONTENT_LABEL_MAP[IndicesContentType.GAMMA_LEVELS],
            category: QuadrantTabCategory.CHART,
            sym: IndexSymbol.SPX,
            symOptions: getDefaultSymOptions(IndicesContentType.GAMMA_LEVELS),
          },
          {
            id: uuidv4(),
            contentId: IndicesContentType.COMBO_STRIKES,
            label: INDICES_CONTENT_LABEL_MAP[IndicesContentType.COMBO_STRIKES],
            category: QuadrantTabCategory.CHART,
            sym: IndexSymbol.SPX,
            symOptions: getDefaultSymOptions(IndicesContentType.COMBO_STRIKES),
          },
          {
            id: uuidv4(),
            contentId: IndicesContentType.TILT,
            label: INDICES_CONTENT_LABEL_MAP[IndicesContentType.TILT],
            category: QuadrantTabCategory.CHART,
            sym: IndexSymbol.SPX,
            symOptions: getDefaultSymOptions(IndicesContentType.TILT),
          },
          {
            id: uuidv4(),
            contentId: IndicesContentType.EXP_CONCENTRATION,
            label:
              INDICES_CONTENT_LABEL_MAP[IndicesContentType.EXP_CONCENTRATION],
            category: QuadrantTabCategory.CHART,
            sym: IndexSymbol.SPX,
            symOptions: getDefaultSymOptions(
              IndicesContentType.EXP_CONCENTRATION,
            ),
          },
        ],
      },
    ],
    [
      QuadrantId.BOTTOM_LEFT,
      {
        tabs: [
          {
            id: uuidv4(),
            contentId: IndicesContentType.VOLFORECAST_MODEL,
            label:
              INDICES_CONTENT_LABEL_MAP[IndicesContentType.VOLFORECAST_MODEL],
            category: QuadrantTabCategory.CHART,
            sym: IndexSymbol.SPX,
            symOptions: getDefaultSymOptions(
              IndicesContentType.VOLFORECAST_MODEL,
            ),
          },
          {
            id: uuidv4(),
            contentId: IndicesContentType.OPTIONS_RISK_REVERSAL_CHART,
            label:
              INDICES_CONTENT_LABEL_MAP[
                IndicesContentType.OPTIONS_RISK_REVERSAL_CHART
              ],
            category: QuadrantTabCategory.CHART,
            sym: IndexSymbol.SPX,
            symOptions: getDefaultSymOptions(
              IndicesContentType.OPTIONS_RISK_REVERSAL_CHART,
            ),
          },
          {
            id: uuidv4(),
            contentId: IndicesContentType.MAX_REAL_VOL,
            label: INDICES_CONTENT_LABEL_MAP[IndicesContentType.MAX_REAL_VOL],
            category: QuadrantTabCategory.CHART,
            sym: IndexSymbol.SPX,
            symOptions: getDefaultSymOptions(IndicesContentType.MAX_REAL_VOL),
          },
          {
            id: uuidv4(),
            contentId: IndicesContentType.REAL_VOLATILITY,
            label:
              INDICES_CONTENT_LABEL_MAP[IndicesContentType.REAL_VOLATILITY],
            category: QuadrantTabCategory.CHART,
            sym: IndexSymbol.SPX,
            symOptions: getDefaultSymOptions(
              IndicesContentType.REAL_VOLATILITY,
            ),
          },
        ],
      },
    ],
    [
      QuadrantId.BOTTOM_RIGHT,
      {
        tabs: [
          {
            id: uuidv4(),
            contentId: IndicesContentType.ZERO_DTE,
            label: INDICES_CONTENT_LABEL_MAP[IndicesContentType.ZERO_DTE],
            category: QuadrantTabCategory.CHART,
            sym: IndexSymbol.SPX,
            symOptions: getDefaultSymOptions(IndicesContentType.ZERO_DTE),
          },
          {
            id: uuidv4(),
            contentId: IndicesContentType.OI_VOLUME,
            label: INDICES_CONTENT_LABEL_MAP[IndicesContentType.OI_VOLUME],
            category: QuadrantTabCategory.CHART,
            sym: IndexSymbol.SPX,
            symOptions: getDefaultSymOptions(IndicesContentType.OI_VOLUME),
          },
        ],
      },
    ],
  ]);
};
