import { useTheme } from '@mui/material/styles';
import { TabContext, TabPanel } from '@mui/lab';
import { Box } from '@mui/material';
import { OCCChart } from './OCC';
import { DPISectors } from './DPISectors';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useSearchParams } from 'react-router-dom';
import {
  equityPCChartZoomConfigState,
  equityPCInitialDataState,
  expConcentrationChartZoomConfigState,
  expConcentrationInitialDataState,
  isMobileState,
  occChartZoomConfigState,
  occInitialDataState,
  zeroDTEChartZoomConfigState,
  zeroDTEInitialDataState,
} from '../../states';
import { InfoButton, Tabs, ZoomOutButton } from 'components/shared';
import { OCCChartSettings } from './OCC/OCCControls';
import { ExpConcentrationChart, ZeroDTEChart } from 'components/indices';
import { EquityPCRatioChart } from './EquityPCRatioChart';
import { SentimentTab, SENTIMENT_TABS } from 'types';
import { DEFAULT_BRUSH_ZOOM_CONFIG, SENTIMENT_LABEL_MAPPING } from 'config';

export const Sentiments = () => {
  const theme = useTheme();
  const [searchParams, setSearch] = useSearchParams();
  const query = searchParams.get('tab') ?? '';
  const tab = SENTIMENT_TABS.has(query) ? query : (SentimentTab.OCC as string);
  const isMobile = useRecoilValue(isMobileState);
  const [occZoomConfig, setOCCZoomConfig] = useRecoilState(
    occChartZoomConfigState,
  );
  const occInitialData = useRecoilValue(occInitialDataState);

  const [zeroDTEZoomConfig, setZeroDTEZoomConfig] = useRecoilState(
    zeroDTEChartZoomConfigState,
  );
  const zeroDTEInitialData = useRecoilValue(zeroDTEInitialDataState);

  const [expConcentrationZoomConfig, setExpConcentrationZoomConfig] =
    useRecoilState(expConcentrationChartZoomConfigState);
  const expConcentrationInitialData = useRecoilValue(
    expConcentrationInitialDataState,
  );

  const [equityPCZoomConfig, setEquityPCZoomConfig] = useRecoilState(
    equityPCChartZoomConfigState,
  );
  const equityPCInitialData = useRecoilValue(equityPCInitialDataState);

  const options = [
    ...(tab === (SentimentTab.OCC as string)
      ? [
          <OCCChartSettings key="occ" />,
          <ZoomOutButton
            key="zoom"
            zoomConfig={occZoomConfig}
            setZoomConfig={setOCCZoomConfig}
            initialData={occInitialData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: occInitialData.length - 1,
            }}
          />,
        ]
      : []),
    ...(tab === (SentimentTab.ZeroDTE as string)
      ? [
          <ZoomOutButton
            key="zoom"
            zoomConfig={zeroDTEZoomConfig}
            setZoomConfig={setZeroDTEZoomConfig}
            initialData={zeroDTEInitialData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: zeroDTEInitialData.length - 1,
            }}
          />,
        ]
      : []),
    ...(tab === (SentimentTab.EquityPutCallRatio as string)
      ? [
          <ZoomOutButton
            key="zoom"
            zoomConfig={equityPCZoomConfig}
            setZoomConfig={setEquityPCZoomConfig}
            initialData={equityPCInitialData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: equityPCInitialData.length - 1,
            }}
          />,
        ]
      : []),
    ...(tab === (SentimentTab.ExpirationConcentration as string)
      ? [
          <ZoomOutButton
            key="zoom"
            zoomConfig={expConcentrationZoomConfig}
            setZoomConfig={setExpConcentrationZoomConfig}
            initialData={expConcentrationInitialData}
          />,
        ]
      : []),
    <InfoButton key={`${tab}-info`} articleKey={tab} />,
  ];

  return (
    <Box
      sx={{
        transition: '0.5s',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        background: theme.palette.background.paper,
        boxShadow: theme.palette.shadows.paperBoxShadow,
        borderRadius: theme.spacing(3),
        ...(isMobile ? { minHeight: '450px' } : {}),
        paddingBottom: '30px', // support button
      }}
    >
      <TabContext value={tab}>
        <Tabs
          options={SENTIMENT_LABEL_MAPPING}
          onChange={(_evt, tab: string) => setSearch({ tab })}
          controlProps={{ options }}
        />
        <Box
          sx={{
            width: '100%',
            height: isMobile ? '500px' : '100%',
            display: 'flex',
            overflowX: 'auto',
            ...(isMobile ? { maxHeight: 'calc(100% - 150px)' } : {}),
          }}
        >
          <TabPanel value={SentimentTab.OCC as string}>
            <Box
              sx={{
                transition: '0.5s',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                height: '100%',
              }}
            >
              <OCCChart />
            </Box>
          </TabPanel>
          <TabPanel value={SentimentTab.ZeroDTE as string}>
            <Box
              sx={{
                transition: '0.5s',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                height: '100%',
              }}
            >
              <ZeroDTEChart isSentiment={true} selectedSym={''} />
            </Box>
          </TabPanel>
          <TabPanel value={SentimentTab.EquityPutCallRatio as string}>
            <Box
              sx={{
                transition: '0.5s',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                height: '100%',
              }}
            >
              <EquityPCRatioChart />
            </Box>
          </TabPanel>
          <TabPanel value="__dpi">
            <Box
              sx={{
                transition: '0.5s',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                height: '100%',
              }}
            >
              <DPISectors />
            </Box>
          </TabPanel>
          <TabPanel value={SentimentTab.ExpirationConcentration as string}>
            <Box
              sx={{
                transition: '0.5s',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                height: '100%',
              }}
            >
              <ExpConcentrationChart selectedSym={''} />
            </Box>
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
};
