import useHiroPolling, { useHiroPollingProps } from './useHiroPolling';
import { HiroUpdatingType, Lense, OptionType } from '../../types';
import { useStreamingProps } from '../bloomberg';
import { useRecoilValue } from 'recoil';
import { hiroUpdatingTypeState } from '../../states';
import useStreaming from '../bloomberg/streaming/useStreaming';
import { isZerohedge } from '../../util';
import { useTheme } from '@mui/material/styles';

export const SERIES_DEFAULT_OPTS = {
  lineWidth: 2,
  type: 'line',
  ...(isZerohedge() ? { lineWidth: 1 } : {}),
};

const CANDLE_DEFAULT_SERIES_OPTS = {
  type: 'candle',
  wickUpColor: '#838ca1',
  wickDownColor: '#838ca1',
};

const CANDLE_RED_COLOR = '#cc304a';

const useHiroUpdating = (props?: useHiroPollingProps | useStreamingProps) => {
  const type = useRecoilValue(hiroUpdatingTypeState);
  const theme = useTheme();

  const isPollingProps = (
    props: useHiroPollingProps | useStreamingProps | undefined,
  ): props is useHiroPollingProps => {
    return type === HiroUpdatingType.POLLING && props != null;
  };

  const isStreamingProps = (
    props: useHiroPollingProps | useStreamingProps | undefined,
  ): props is useStreamingProps => {
    return type === HiroUpdatingType.STREAMING && props != null;
  };

  const { getPollingSeriesData, prices } = useHiroPolling(
    type === HiroUpdatingType.POLLING,
    isPollingProps(props) ? props : undefined,
  );

  const {
    getRolledCandles,
    getHistogramData: getStreamingHistogramData,
    getPrices: getStreamingPrices,
  } = useStreaming(
    type === HiroUpdatingType.STREAMING,
    isStreamingProps(props) ? props : undefined,
  );

  const getSeriesData = (lense: Lense, optionType: OptionType) => {
    if (type === HiroUpdatingType.POLLING) {
      return getPollingSeriesData(lense, optionType);
    }

    return getRolledCandles(lense, optionType);
  };

  const getHistogramData = (lense: Lense) => {
    if (type === HiroUpdatingType.POLLING) {
      return undefined;
    }

    return getStreamingHistogramData(lense);
  };

  const getPrices = () => {
    if (type === HiroUpdatingType.POLLING) {
      return prices;
    }

    return getStreamingPrices() ?? [];
  };

  const getSeriesExtraOpts = (lense: Lense, optionType: OptionType) => {
    if (type === HiroUpdatingType.POLLING) {
      return { ...SERIES_DEFAULT_OPTS };
    }

    const colors = theme.palette.hiro.lenses[lense];
    let colorForOptionType = colors.total;
    if (optionType === OptionType.P) {
      colorForOptionType = colors.put;
    } else if (optionType === OptionType.C) {
      colorForOptionType = colors.call;
    }

    const downColor =
      lense === Lense.All && optionType === OptionType.TOT
        ? CANDLE_RED_COLOR
        : colorForOptionType;

    return {
      borderUpColor: colorForOptionType,
      upColor: colorForOptionType,
      borderDownColor: downColor,
      downColor,
      ...CANDLE_DEFAULT_SERIES_OPTS,
    };
  };

  return { getSeriesData, getPrices, getHistogramData, getSeriesExtraOpts };
};

export default useHiroUpdating;
