import { Sentiments } from '../components/sentiment/Sentiments';
import useCleanup from '../hooks/useCleanup';
import { ProductType } from 'types';
import { AccessProtectedPage } from './AccessProtectedPage';
import { useCallback } from 'react';

export const SentimentPage = () => {
  useCleanup();
  const render = useCallback(() => <Sentiments />, []);

  return (
    <AccessProtectedPage
      productType={ProductType.SENTIMENT}
      renderContent={render}
    />
  );
};
