const FUTURE_ADJUSTED_KEYS = new Set([
  'callwallstrike',
  'putwallstrike',
  'max_g_strike',
  'l1',
  'l2',
  'l3',
  'l4',
  'c1',
  'c2',
  'c3',
  'c4',
  'zero_g_strike',
  'upx',
  'topabs_strike',
  'cws',
  'pws',
  'upx',
  'keyd',
  'keyg',
  'maxfs',
  'putctrl',
  'sig_high',
  'sig_low',
]);

export const convertLevelsToFutures = (keyLevelsData: any) => {
  let obj: any = {};
  let futuresDiff = keyLevelsData?.futuresDiff ?? 0;
  futuresDiff = parseFloat(futuresDiff.toFixed(2));

  if (futuresDiff === 0) {
    return keyLevelsData;
  }

  for (const key of Object.keys(keyLevelsData)) {
    let val = keyLevelsData[key];
    if (
      FUTURE_ADJUSTED_KEYS.has(key) ||
      FUTURE_ADJUSTED_KEYS.has(key.toLowerCase())
    ) {
      val += futuresDiff;
    }
    obj[key] = val;
  }

  return obj;
};
