import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  ReferenceLine,
} from 'recharts';
import {
  isMobileState,
  oiVolumeChartZoomConfigState,
  oiVolumeInitialDataState,
} from 'states';
import {
  IndicesContentType,
  IndexSymbol,
  OIVolume,
  PutCall,
  RawModelRow,
  SymSelectorSettings,
} from '../../../types';
import {
  TIMESTAMP_TICK_CONFIG,
  formatAsCompactNumber,
  formatAsCurrency,
  getTicks,
} from '../../../util';
import { getZoomConfigRefArea } from 'util/shared/chart';
import {
  DEFAULT_CHART_MARGINS,
  DEFAULT_X_AXIS_STYLES,
  DEFAULT_Y_AXIS_STYLES,
} from '../../../config';
import { IndicesHeader } from '../shared/IndicesHeader';
import { Controls } from '../../shared/Controls';
import SettingsPopout from '../../shared/SettingsPopout';
import { ZoomOutButton } from 'components/shared';
import useZoom from 'hooks/useZoom';
import useModels from '../../../hooks/indices/useModels';
import ChartWatermarkContainer from 'components/shared/ChartWatermarkContainer';

const OI_TYPE = {
  CHANGE: 'change',
  TOTAL: 'total',
};

export const OIVolumeControls = ({
  oiType,
  setOiType,
  optType,
  setOptType,
}: {
  oiType: string;
  setOiType: React.Dispatch<React.SetStateAction<string>>;
  optType: PutCall;
  setOptType: React.Dispatch<React.SetStateAction<PutCall>>;
}) => {
  const isMobile = useRecoilValue(isMobileState);
  const theme = useTheme();
  const oiVolumeData = useRecoilValue(oiVolumeInitialDataState);
  const [zoomConfig, setZoomConfig] = useRecoilState(
    oiVolumeChartZoomConfigState,
  );
  const controls = (
    <Controls
      buttonGroups={[
        {
          buttons: [
            { value: OI_TYPE.CHANGE, label: 'OI Change' },
            { value: OI_TYPE.TOTAL, label: 'Total OI & Volume' },
          ],
          setter: setOiType,
          curValue: oiType,
        },
        {
          buttons: [
            { value: PutCall.PUT, label: 'Put' },
            { value: PutCall.CALL, label: 'Call' },
          ],
          setter: setOptType,
          curValue: optType,
        },
      ]}
    />
  );
  let settings = controls;
  if (isMobile) {
    settings = (
      <SettingsPopout
        title="Settings"
        popperID="oiVolumeControls"
        sx={{ backgroundColor: theme.palette.core.translucentBG }}
      >
        {controls}
      </SettingsPopout>
    );
  }

  return (
    <>
      {settings}
      <ZoomOutButton
        zoomConfig={zoomConfig}
        setZoomConfig={setZoomConfig}
        initialData={oiVolumeData}
      />
    </>
  );
};

// Round to the nearest $X
const ROUNDINGS = new Map([
  [IndexSymbol.SPX, 5],
  [IndexSymbol.NDX, 10],
  [IndexSymbol.RUT, 5],
  [IndexSymbol.SPY, 0.5],
  [IndexSymbol.QQQ, 0.5],
  [IndexSymbol.IWM, 0.5],
]);

interface OIVolumeChartProps {
  chartStyleOverrides?: React.CSSProperties;
  selectedSym: string;
  containerStyleOverrides?: SxProps<Theme>;
  symSelectorSettings?: SymSelectorSettings;
}

export const OIVolumeChart = ({
  chartStyleOverrides,
  selectedSym,
  containerStyleOverrides,
  symSelectorSettings,
}: OIVolumeChartProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const { getModels } = useModels();
  const [optType, setOptType] = useState<PutCall>(PutCall.PUT);
  const [oiType, setOiType] = useState<string>(OI_TYPE.TOTAL);
  const [zoomConfig, setZoomConfig] = useRecoilState(
    oiVolumeChartZoomConfigState,
  );
  const [oiVolumeData, setOiVolumeData] = useRecoilState(
    oiVolumeInitialDataState,
  );
  const keySuffix = oiType === OI_TYPE.CHANGE ? '_change' : '';
  const { zoomChartConfig } = useZoom<OIVolume>(
    zoomConfig,
    setZoomConfig,
    [`put_oi${keySuffix}`, `call_oi${keySuffix}`, 'put_volume', 'call_volume'],
    'strike',
    oiVolumeData,
    // ['put_volume', 'call_volume'],
  );
  const roundTo: number = useMemo(
    () => ROUNDINGS.get(selectedSym as IndexSymbol)!,
    [selectedSym],
  );
  const roundStrike = useCallback(
    (s: number) => Math.round(s / roundTo) * roundTo,
    [roundTo],
  );

  useEffect(() => {
    async function generateChartData() {
      let modelData: RawModelRow[] = await getModels(
        ['OI', 'OI_change', 'volume'],
        selectedSym,
      );
      const modelToRows: any = modelData.reduce(
        (o, r) => ({
          ...o,
          [r.model]: {
            strike_list: JSON.parse(r.strike_list),
            current_list: JSON.parse(r.current_list),
            next_exp_list: JSON.parse(r.next_exp_list),
          },
        }),
        {},
      );
      const { OI: oi, OI_change: oiChange, volume } = modelToRows;
      const strike2data = new Map();
      oi.strike_list.forEach((strike: number, idx: number) => {
        const roundedStrike = roundStrike(strike);
        const prev = strike2data.get(roundedStrike) ?? {};
        strike2data.set(roundedStrike, {
          ...prev,
          call_oi: oi.next_exp_list[idx] + (prev?.call_oi ?? 0),
          put_oi: oi.current_list[idx] + (prev?.put_oi ?? 0),
        });
      });
      oiChange.strike_list.forEach((strike: number, idx: number) => {
        const roundedStrike = roundStrike(strike);
        const prev = strike2data.get(roundedStrike) ?? {};
        strike2data.set(roundedStrike, {
          ...prev,
          put_oi_change: oiChange.current_list[idx] + (prev.put_oi_change ?? 0),
          call_oi_change:
            oiChange.next_exp_list[idx] + (prev.call_oi_change ?? 0),
        });
      });
      volume.strike_list.forEach((strike: number, idx: number) => {
        const roundedStrike = roundStrike(strike);
        const prev = strike2data.get(roundedStrike) ?? {};
        strike2data.set(roundedStrike, {
          ...prev,
          put_volume: volume.current_list[idx] + (prev.put_volume ?? 0),
          call_volume: volume.next_exp_list[idx] + (prev.call_volume ?? 0),
        });
      });
      for (const [roundedStrike, val] of strike2data.entries()) {
        val.strike = roundedStrike;
      }
      const sortedData = [...strike2data.values()].sort(
        (a: OIVolume, b: OIVolume) => b.strike - a.strike,
      );
      setOiVolumeData(sortedData);
      setZoomConfig((prev) => ({ ...prev, data: sortedData }));
    }
    generateChartData();
  }, [getModels, setZoomConfig, setOiVolumeData, selectedSym]);

  const ticks = useMemo(() => {
    const strikes = zoomConfig?.data?.map((d) => d.strike);
    return strikes ? getTicks(strikes, zoomConfig, TIMESTAMP_TICK_CONFIG) : [];
  }, [zoomConfig]);

  // TODO: Introduce 2nd y-axis when showing volume?
  const yLabel =
    oiType === OI_TYPE.TOTAL
      ? 'Open Interest & Volume'
      : 'Change in Open Interest';

  const controls = (
    <OIVolumeControls
      oiType={oiType}
      setOptType={setOptType}
      optType={optType}
      setOiType={setOiType}
    />
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        gap: '8px',
        ...containerStyleOverrides,
      }}
    >
      <IndicesHeader
        type={IndicesContentType.OI_VOLUME}
        title="Open Interest & Volume"
        expandable
        customController={controls}
        symbol={selectedSym}
        symSelectorSettings={symSelectorSettings}
      />
      {zoomConfig.data && (
        <ChartWatermarkContainer
          ref={ref}
          style={{ ...chartStyleOverrides }}
          size={25}
          offsetX={55}
          offsetY={40}
        >
          <ResponsiveContainer>
            <BarChart
              margin={DEFAULT_CHART_MARGINS}
              stackOffset="silhouette"
              {...zoomChartConfig}
            >
              <CartesianGrid
                strokeDasharray="1 10"
                stroke={theme.palette.gray}
              />
              <XAxis
                allowDataOverflow
                dataKey="strike"
                type="number"
                tick={{ fontSize: 11 }}
                ticks={ticks}
                tickFormatter={(v: number) => formatAsCurrency(v, true)}
                label={{
                  value: 'Strike',
                  ...DEFAULT_X_AXIS_STYLES,
                }}
                domain={[zoomConfig.left, zoomConfig.right]}
                tickCount={10}
              />
              <YAxis
                allowDataOverflow
                domain={[zoomConfig.bottom, zoomConfig.top]}
                tickFormatter={formatAsCompactNumber}
                tick={{ fontSize: 11 }}
                label={{
                  ...DEFAULT_Y_AXIS_STYLES,
                  value: yLabel,
                }}
              />
              <Tooltip
                formatter={(v: string) => parseFloat(v).toLocaleString()}
                labelFormatter={(value: number) =>
                  `Strike: $${Math.round(value).toLocaleString()}`
                }
                itemStyle={{ fontSize: '11px' }}
                contentStyle={{
                  color: theme.palette.text.primary,
                  border: 'none',
                  backgroundColor: theme.palette.background.paper,
                  boxShadow: theme.palette.shadows.paperBoxShadow,
                }}
                separator=": "
              />
              {oiType === OI_TYPE.TOTAL && (
                <Bar
                  dataKey={`${optType}_volume`}
                  name="Volume Traded"
                  fill={theme.palette.indices.delta.all}
                />
              )}
              <Bar
                dataKey={`${optType}_oi${keySuffix}`}
                name={
                  oiType === OI_TYPE.CHANGE
                    ? 'Open Interest Change'
                    : 'Total Open Interest'
                }
                fill={
                  optType === PutCall.PUT
                    ? theme.palette.core.put
                    : theme.palette.core.call
                }
              />
              <ReferenceLine y={0} strokeOpacity={0.5} />
              {getZoomConfigRefArea(zoomConfig)}
            </BarChart>
          </ResponsiveContainer>
        </ChartWatermarkContainer>
      )}
    </Box>
  );
};
