import { atom, selector } from 'recoil';
import {
  COMBO_SYMS,
  DEFAULT_BRUSH_ZOOM_CONFIG,
  FIELD_NAME_MAPPING,
  defaultColumnScannerVisibility,
  historyFields,
  scannerFields,
} from '../config';
import {
  BrushZoomConfig,
  Equity,
  HistoricalEquity,
  RiskReversal,
  Scanner,
  ScannerColumnKey,
  Tick,
  VolSkew,
} from '../types';
import { getLevelsFromDataAndFields } from '../util';
import {
  GridColumnVisibilityModel,
  GridFilterModel,
} from '@spotgamma/x-data-grid-premium';
import { watchlistsState } from 'states';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

// we init equities with COMBO_SYMS, so equities will always have at least that many
// items even if we havent fetched from the server yet
export const UNLOADED_EQUITIES_DEFAULT_LENGTH = COMBO_SYMS.length;

// Map ({instrument: data}) of all stocks in screener (unset prices)
export const unpricedEquitiesState = atom<Map<string, Equity>>({
  key: 'equityhub-equitiesState',
  default: new Map(),
});

// Map ({instrument: currentPrice}) of all stocks in screener
export const pricesState = atom<Map<string, number>>({
  key: 'equityhub-prices',
  default: new Map(),
});

// Merge price data with equitiesState
export const synthesizedEquitiesState = selector<Map<string, Equity>>({
  key: 'equityhub-synthesizedEquities',
  get: ({ get }) => {
    const equities = get(unpricedEquitiesState);
    const prices = get(pricesState);
    const watchlists = get(watchlistsState);
    const watchlistSyms = new Set(watchlists?.flatMap((w) => w.symbols));
    const synthesized = new Map();
    for (const [sym, equity] of equities.entries()) {
      synthesized.set(sym, {
        ...equity,
        price: prices.get(sym),
        id: sym,
        isWatchlisted: watchlistSyms.has(sym),
      });
    }
    return synthesized;
  },
});

export const equitySymbolsState = selector({
  key: 'equityhub-instrumentsState',
  get: ({ get }) => {
    const equities = get(unpricedEquitiesState);
    return Array.from(equities).map(([symbol, equity]) => {
      return { symbol, name: equity.name ?? symbol };
    });
  },
});

export const equityHubSymbolsSetState = selector({
  key: 'equityhub-equitySymbolsSetState',
  get: ({ get }) => {
    const equities = get(unpricedEquitiesState);
    return new Set(Array.from(equities).map(([sym, _equity]) => sym));
  },
});

export const selectedEquitySymbolState = atom<string | undefined>({
  key: 'equityhub-selectedEquitySymbolState',
  default: undefined,
});

export const selectedEquityState = selector({
  key: 'equityhub-selectedEquityState',
  get: ({ get }) => {
    const equities = get(synthesizedEquitiesState);
    return equities.get(get(selectedEquitySymbolState)!);
  },
});

export const skewState = atom<VolSkew[] | undefined>({
  key: 'equityhub-skewState',
  default: undefined,
});

export const gammaStrikesState = atom<any>({
  key: 'equityhub-gammaStrikesState',
  default: [],
});

export const equityQuantilesState = atom<
  | {
      positionMin: number;
      positionMax: number;
      activityMin: number;
      activityMax: number;
    }
  | undefined
>({
  key: 'equityhub-equityQuantilesState',
  default: undefined,
});

export const gammaNotionalState = atom<any>({
  key: 'equityhub-gammaNotionalState',
  default: [],
});

export const strikeState = atom<any>({
  key: 'equityhub-strikeState',
  default: [],
});

export const tickDataState = atom<Tick[]>({
  key: 'equityhub-tickDataState',
  default: [],
});

// List of all historical equities in screener
export const equityHistoryState = atom<HistoricalEquity[]>({
  key: 'equityhub-equityHistoryState',
  default: [],
});

// Currently active equity scanner in screener
export const ehActiveScannerState = atom<Scanner | undefined>({
  key: 'equityhub-ehActiveScannerState',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});

export const ehScannerColumnsVisibilityState = atom<GridColumnVisibilityModel>({
  key: 'equityhub-ehScannerColumnsVisibilityState',
  default: defaultColumnScannerVisibility,
  effects_UNSTABLE: [persistAtom],
});

export const ehUseDefaultColumnsState = atom<boolean>({
  key: 'equityhub-ehUseDefaultColumnsState',
  default: true,
  effects_UNSTABLE: [persistAtom],
});

export const ehScannerFilterModelState = atom<GridFilterModel>({
  key: 'equityhub-ehScannerFilterModelState',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});

export const ehActiveWatchlistsIdsState = atom<number[]>({
  key: 'equityhub-activeWatchlistIds',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const ehScannerColumnOrderState = atom<ScannerColumnKey[]>({
  key: 'equityhub-ehScannerColumnOrderState',
  default: scannerFields,
  effects_UNSTABLE: [persistAtom],
});

export const ehHistoryScannerColumnOrderState = atom<ScannerColumnKey[]>({
  key: 'equityhub-ehHistoryScannerColumnOrderState',
  default: historyFields,
  effects_UNSTABLE: [persistAtom],
});

export const ehHistoryScannerFilterModelState = atom<GridFilterModel>({
  key: 'equityhub-ehHistoryScannerFilterModelState',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});

export const ehHistoryScannerColumnsVisibilityState =
  atom<GridColumnVisibilityModel>({
    key: 'equityhub-ehHistoryScannerColumnsVisibilityState',
    default: defaultColumnScannerVisibility,
    effects_UNSTABLE: [persistAtom],
  });

export const selectedEquityLevelsState = selector({
  key: 'equityhub-selectedEquityLevelsState',
  get: ({ get }) => {
    const equity = get(selectedEquityState);
    if (!equity) {
      return undefined;
    }

    return getLevelsFromDataAndFields<Equity>(FIELD_NAME_MAPPING, equity);
  },
});

export const rrChartZoomConfigState = atom<BrushZoomConfig>({
  key: 'equityhub-rrChartZoomConfigState',
  default: DEFAULT_BRUSH_ZOOM_CONFIG,
});

export const rrInitialDataState = atom<RiskReversal[]>({
  key: 'equityhub-rrInitialDataState',
  default: [],
});
