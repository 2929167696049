import { useCallback, useEffect, useMemo } from 'react';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';
import { SetterOrUpdater } from 'recoil';
import { BiaxialChartZoomConfig, ChartZoomConfig } from '../types';
import { getAxisYDomain } from '../util';

const useZoom = <Type>(
  zoomConfig: ChartZoomConfig | BiaxialChartZoomConfig,
  setZoomConfig:
    | SetterOrUpdater<ChartZoomConfig>
    | SetterOrUpdater<BiaxialChartZoomConfig>,
  yFields: string[],
  xField: string,
  initialData: Type[],
  y2Fields?: string[],
) => {
  // listen to changes on the xField (if user changes x-axis view) and update left/right accordingly
  useEffect(() => {
    let { left, right, data } = zoomConfig;

    if (!(xField && left && right && data)) {
      return;
    }

    data = data?.slice();
    const xFieldLeftObj = data?.find((item) =>
      Object.values(item).includes(left),
    );
    const xFieldRightObj = data?.find((item) =>
      Object.values(item).includes(right),
    );

    const newLeft = xFieldLeftObj?.[xField] ?? 'dataMin';
    const newRight = xFieldRightObj?.[xField] ?? 'dataMax';

    // only update zoom config if the new left/right are changed
    if (newLeft !== left || newRight !== right) {
      setZoomConfig((prev: any) => ({
        ...prev,
        left: newLeft,
        right: newRight,
      }));
    }
  }, [setZoomConfig, xField, zoomConfig]);

  const zoom = useCallback(() => {
    let { refAreaLeft, refAreaRight, bottom, top, left, right, data } =
      zoomConfig;
    let top2: number | string | null = null;
    let bottom2: number | string | null = null;
    if ('top2' in zoomConfig && 'bottom2' in zoomConfig) {
      bottom2 = (zoomConfig as BiaxialChartZoomConfig).bottom2;
      top2 = (zoomConfig as BiaxialChartZoomConfig).top2;
    }

    if (
      zoomConfig.refAreaLeft === zoomConfig.refAreaRight ||
      zoomConfig.refAreaRight === ''
    ) {
      setZoomConfig((prev: any) => ({
        ...prev,
        refAreaLeft: '',
        refAreaRight: '',
      }));
      return;
    }

    let refLeft = zoomConfig.refAreaLeft;
    let refRight = zoomConfig.refAreaRight;
    if (zoomConfig.refAreaLeft > zoomConfig.refAreaRight) {
      [refLeft, refRight] = [refRight, refLeft]; // swap
    }

    if (zoomConfig.refAreaLeft) {
      const [b, t] = getAxisYDomain(
        refLeft as number,
        refRight as number,
        yFields,
        xField,
        0,
        initialData,
      );
      if (y2Fields) {
        const [b2, t2] = getAxisYDomain(
          refLeft as number,
          refRight as number,
          y2Fields,
          xField,
          0,
          initialData,
        );
        bottom2 = b2;
        top2 = t2;
      }
      bottom = b;
      top = t;
    }

    left = refLeft;
    right = refRight;
    data = data?.slice();
    const leftIdx = data?.findIndex((item) => item[xField] === left);
    const rightIdx = data?.findIndex((item) => item[xField] === right);
    refAreaLeft = '';
    refAreaRight = '';
    setZoomConfig((prev: any) => ({
      ...prev,
      refAreaLeft,
      refAreaRight,
      bottom,
      top,
      left,
      right,
      leftIdx,
      rightIdx,
      data,
      bottom2,
      top2,
    }));
  }, [initialData, xField, yFields, y2Fields, zoomConfig, setZoomConfig]);

  const zoomChartConfig = useMemo(() => {
    return {
      data: zoomConfig.data,
      onMouseDown: (e: CategoricalChartState) => {
        setZoomConfig((prev: any) => ({
          ...prev,
          refAreaLeft: e.activeLabel ?? '',
        }));
      },
      onMouseMove: (e: CategoricalChartState) => {
        if (zoomConfig.refAreaLeft) {
          setZoomConfig((prev: any) => ({
            ...prev,
            refAreaRight: e.activeLabel ?? '',
          }));
        }
      },
      onMouseUp: zoom,
    };
  }, [setZoomConfig, zoomConfig, zoom]);

  return { zoomChartConfig };
};

export default useZoom;
