import { useEffect, useRef, useMemo, Fragment, useState } from 'react';
import dayjs from 'dayjs';
import {
  ReferenceArea,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Label,
  ComposedChart,
  Legend,
  Brush,
} from 'recharts';
import { useTheme } from '@mui/material/styles';
import { Gamma, Lense, Level, Tick } from '../../types';
import { formatAsCurrency, gNotToPoints, priceToPoints } from '../../util';
import { ErrorContent } from './ErrorContent';
import { DEFAULT_Y_AXIS_STYLES } from '../../config';
import {
  isMobileState,
  realTimeChartZoomConfigState,
  realTimeInitialDataState,
  timezoneState,
} from '../../states';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  TIMESTAMP_TICK_CONFIG,
  getTicksBrushed,
  getZoomConfigRefArea,
} from 'util/shared/chart';
import ChartWatermarkContainer from './ChartWatermarkContainer';
import { Slider } from '@mui/material';

interface TimeSeriesChartProps {
  zoomChartConfig: any;
  levels: Level[] | undefined;
  gammas: Gamma[];
  loading?: boolean;
  showKeyLevels: boolean;
  sym?: string;
}

const LOWER_BOUND_MULTIPLIER = 0.98;
const UPPER_BOUND_MULTIPLIER = 1.02;

export const TimeSeriesChart = ({
  zoomChartConfig,
  levels,
  gammas,
  loading = false,
  showKeyLevels,
  sym,
}: TimeSeriesChartProps) => {
  const isMobile = useRecoilValue(isMobileState);
  const ref = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const fieldColorMapping = theme.palette.equityHub.fieldColorMapping;
  const [zoomConfig, setZoomConfig] = useRecoilState(
    realTimeChartZoomConfigState,
  );

  const initialData = useRecoilValue(realTimeInitialDataState);
  const currentTimezone = useRecoilValue(timezoneState);
  const [yDomain, setYDomain] = useState<number[]>([-Infinity, Infinity]);

  const maxLevelValue =
    levels && levels.length > 0
      ? levels.reduce((max: Level, level: Level) =>
          max.value > level.value ? max : level,
        ).value
      : null;
  const minLevelValue =
    levels && levels.length > 0
      ? levels.reduce((min: Level, level: Level) =>
          min.value < level.value ? min : level,
        ).value
      : null;

  const prices = initialData.map((t: Tick) => t.price);
  const minPrice = prices.length > 0 ? Math.min(...prices) : -Infinity;
  const maxPrice = prices.length > 0 ? Math.max(...prices) : Infinity;

  useEffect(() => {
    // reset y domain when sym changes
    if (minPrice && maxPrice) {
      setYDomain([
        minPrice * LOWER_BOUND_MULTIPLIER,
        maxPrice * UPPER_BOUND_MULTIPLIER,
      ]);
    }
  }, [minPrice, maxPrice]);

  const yTicks = useMemo(() => {
    return yDomain[0] && yDomain[1]
      ? getTicksBrushed(
          initialData.map((t: Tick) => t.epoch_millis),
          zoomConfig,
          TIMESTAMP_TICK_CONFIG,
        )
      : undefined;
  }, [initialData, yDomain, zoomConfig]);

  const getGammas = () => {
    return gammas
      .filter((gamma: Gamma) => gamma.call_gnot !== 0 && gamma.put_gnot !== 0)
      .map((d, i) => {
        const [callx1, callx2, putx1, putx2] = gNotToPoints(
          d.put_gnot,
          d.call_gnot,
          gammas,
          initialData.slice(zoomConfig.leftIdx, -1),
        );
        const [pricey1, pricey2] = priceToPoints(
          d.price,
          gammas,
          initialData || [],
        );

        return (
          <Fragment key={i}>
            <ReferenceArea
              x1={callx1}
              x2={callx2}
              y1={pricey1}
              y2={pricey2}
              fill={theme.palette.hiro.lenses[Lense.All].call}
              stroke={theme.palette.hiro.lenses[Lense.All].call}
              ifOverflow="hidden"
            />
            <ReferenceArea
              x1={putx1}
              x2={putx2}
              y1={pricey1}
              y2={pricey2}
              fill={theme.palette.hiro.lenses[Lense.All].put}
              stroke={theme.palette.hiro.lenses[Lense.All].put}
              ifOverflow="hidden"
            />
          </Fragment>
        );
      });
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    setYDomain(newValue as number[]);
  };

  if (!initialData || initialData.length === 0) {
    return (
      <ErrorContent loading={loading} content="Waiting for Market to Open" />
    );
  }

  return (
    <ChartWatermarkContainer
      ref={ref}
      style={{
        height: 'calc(100% - 40px)',
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '15px',
      }}
      size={25}
      offsetX={50}
      offsetY={40}
      sym={sym}
      symStyles={{
        right: '80px',
        top: '22px',
      }}
    >
      <ResponsiveContainer>
        <ComposedChart
          margin={{
            top: 5,
            right: 50,
            left: isMobile ? 8 : 20,
            bottom: 5,
          }}
          {...zoomChartConfig}
        >
          <CartesianGrid strokeDasharray="1 10" stroke={theme.palette.gray} />
          <XAxis
            allowDataOverflow
            dataKey="epoch_millis"
            tick={{ fontSize: 11 }}
            domain={[
              initialData[zoomConfig.leftIdx ?? 0]?.epoch_millis,
              initialData[zoomConfig.rightIdx ?? initialData.length - 1]
                ?.epoch_millis,
            ]}
            label={{
              value: 'Time',
              fontSize: 12,
              offset: 3,
              position: 'insideBottom',
              fontWeight: 600,
            }}
            type="number"
            tickCount={10}
            tickFormatter={(value: any) =>
              `${dayjs.tz(value, currentTimezone).format('LT')} `
            }
          />
          <Brush
            dataKey="epoch_millis"
            tickFormatter={(value: any) =>
              `${dayjs.tz(value, currentTimezone).format('LT')} `
            }
            startIndex={zoomConfig.leftIdx}
            endIndex={zoomConfig.rightIdx}
            onChange={(brushIndices: any) =>
              setZoomConfig((prev) => ({
                ...prev,
                leftIdx: brushIndices.startIndex,
                rightIdx: brushIndices.endIndex,
              }))
            }
            height={25}
            travellerWidth={15}
            stroke={theme.palette.gray}
            fill={theme.palette.background.paper}
            alwaysShowText
          />
          <YAxis
            allowDataOverflow
            domain={[
              yDomain[0] === -Infinity
                ? minPrice * LOWER_BOUND_MULTIPLIER
                : yDomain[0],
              yDomain[1] === Infinity
                ? maxPrice * UPPER_BOUND_MULTIPLIER
                : yDomain[1],
            ]}
            tick={{ fontSize: 11 }}
            label={{
              ...DEFAULT_Y_AXIS_STYLES,
              value: 'Price',
              offset: 2,
            }}
            type="number"
            tickFormatter={(value) => formatAsCurrency(value, value >= 1000)}
            ticks={yTicks}
          />
          <Tooltip
            formatter={(v: string) => formatAsCurrency(v)}
            labelFormatter={(value) =>
              `${dayjs.tz(value, currentTimezone).format('LT')} `
            }
            itemStyle={{ fontSize: '11px' }}
            contentStyle={{
              color: theme.palette.text.primary,
              border: 'none',
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.palette.shadows.paperBoxShadow,
            }}
            separator=": "
          />

          {gammas.length > 0 && (
            <Legend
              content={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '15px',
                    justifyContent: 'center',
                    marginBottom: -50,
                    marginTop: 25,
                    fontSize: 11,
                    color: theme.palette.text.secondary,
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  <div>
                    Call Gamma Notional{' '}
                    <span
                      style={{
                        display: 'inline-block',
                        backgroundColor:
                          theme.palette.hiro.lenses[Lense.All].call,
                        width: 10,
                        height: 10,
                        marginLeft: 2,
                      }}
                    />
                  </div>
                  <div>
                    Put Gamma Notional{' '}
                    <span
                      style={{
                        display: 'inline-block',
                        backgroundColor:
                          theme.palette.hiro.lenses[Lense.All].put,
                        width: 10,
                        height: 10,
                        marginLeft: 2,
                      }}
                    />
                  </div>
                </div>
              }
            />
          )}

          {getGammas()}
          <Line
            type="basis"
            dataKey="price"
            name="Price"
            stroke={theme.palette.core.price}
            dot={false}
            data={initialData}
            strokeWidth={2.5}
          />
          {levels &&
            showKeyLevels &&
            levels.map(({ field, value, name }) => (
              <ReferenceLine
                y={value}
                key={`time-series-reference-line-${field}`}
                stroke={
                  fieldColorMapping[
                    field.split(',')[0] as keyof typeof fieldColorMapping
                  ]
                }
                label={
                  <Label
                    position="right"
                    value={`${name} $${value.toLocaleString()}`}
                    fontSize={10}
                    style={{
                      textAnchor: 'end',
                      fill: fieldColorMapping[
                        field as keyof typeof fieldColorMapping
                      ],
                      textShadow: `${theme.palette.background.paper} 1px 1px 4px, ${theme.palette.background.paper} 1px -1px 4px, ${theme.palette.background.paper} -1px 1px 4px, ${theme.palette.background.paper} -1px -1px 4px, ${theme.palette.background.paper} 2px 2px 4px, ${theme.palette.background.paper} 2px -2px 4px, ${theme.palette.background.paper} -2px 2px 4px, ${theme.palette.background.paper} 2px -2px 4px`,
                    }}
                  />
                }
                isFront
              />
            ))}

          {getZoomConfigRefArea(zoomConfig)}
        </ComposedChart>
      </ResponsiveContainer>
      {minLevelValue && maxLevelValue && (
        <Slider
          size="small"
          orientation="vertical"
          getAriaLabel={() => 'Y Axis Range'}
          value={yDomain as number[]}
          sx={{
            maxHeight: '95%',
          }}
          onChange={handleChange}
          valueLabelDisplay="auto"
          getAriaValueText={(value: number) => formatAsCurrency(value)}
          valueLabelFormat={(value: number) => formatAsCurrency(value)}
          min={
            minLevelValue && minPrice
              ? Math.min(
                  minLevelValue * LOWER_BOUND_MULTIPLIER,
                  minPrice * LOWER_BOUND_MULTIPLIER,
                )
              : 0
          }
          max={
            maxLevelValue && maxPrice
              ? Math.min(
                  maxLevelValue * UPPER_BOUND_MULTIPLIER,
                  maxPrice * UPPER_BOUND_MULTIPLIER,
                )
              : 999999
          }
        />
      )}
    </ChartWatermarkContainer>
  );
};
