import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import InsightsIcon from '@mui/icons-material/Insights';
import { Feed, Home } from '@mui/icons-material';
import HeartPulse from 'icons/HeartPulse';
import BellCurve from 'icons/BellCurve';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import {
  DataExchangeAgreement,
  DataExchangeAgreementMap,
  ProductType,
  ScannerColumnKey,
  SubLevel,
} from '../types';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import BalanceIcon from '@mui/icons-material/Balance';
import { Box } from '@mui/material';
import { isBloomberg } from '../util/bloomberg';

export const BETA_MODE = process.env.REACT_APP_BETA_MODE === 'true';
export const DEV_MODE = process.env.REACT_APP_DEV_MODE === 'true';
export const NON_PROD = DEV_MODE || BETA_MODE;

export const MOBILE_MAX_WIDTH = 768; // showing mobile UI on tablets as well

export const HOME_LINK = {
  link: '/home',
  label: 'Market Overview',
  icon: <Home width="24px" />,
};

export const PRODUCT_LINKS: Record<
  ProductType,
  {
    link: string;
    label: string;
    icon: JSX.Element;
    isNew?: boolean;
    betaOnly?: boolean;
  }
> = {
  [ProductType.HOME]: HOME_LINK,
  [ProductType.EQUITYHUB]: {
    link: '/equityhub',
    label: 'Equity Hub',
    icon: <AlignVerticalCenterIcon width="24px" />,
  },
  [ProductType.SCANNERS]: {
    link: '/scanners',
    label: 'Scanners',
    icon: <QrCodeScannerIcon width="24px" />,
    isNew: true,
  },
  [ProductType.HIRO]: {
    link: '/hiro',
    label: 'HIRO',
    icon: <InsightsIcon width="24px" />,
  },
  [ProductType.SENTIMENT]: {
    link: '/sentiment',
    label: 'Sentiment',
    icon: (
      // Box added because tooltip hover doesn't get activated on <SvgIcon />
      <Box>
        <HeartPulse width="24px" />
      </Box>
    ),
  },
  [ProductType.INDICES]: {
    link: '/indices',
    label: 'Indices',
    icon: <LeaderboardIcon width="24px" />,
  },
  [ProductType.FOUNDERS_NOTES]: {
    link: '/foundersNotes',
    label: "Founder's Notes",
    icon: <Feed width="24px" />,
  },
  [ProductType.INTEGRATIONS]: {
    link: '/integrations',
    label: 'Integrations',
    icon: <ElectricalServicesIcon width="24px" />,
  },
  [ProductType.IMPLIED_VOL]: {
    link: '/ivol',
    label: 'Implied Volatility',
    icon: (
      <Box>
        <BellCurve width="24px" />
      </Box>
    ),
    isNew: true,
  },
  [ProductType.INTERNAL_OPEN_INTEREST]: {
    link: '/oi',
    label: 'Open Interest',
    icon: <BalanceIcon width="24px" />,
  },
};

export const ALL_PRODUCTS: ProductType[] = [
  ProductType.HOME,
  ProductType.HIRO,
  ProductType.IMPLIED_VOL,
  ProductType.EQUITYHUB,
  ProductType.SCANNERS,
  ProductType.FOUNDERS_NOTES,
  ProductType.SENTIMENT,
  ProductType.INDICES,
  ProductType.INTEGRATIONS,
  ProductType.INTERNAL_OPEN_INTEREST,
];

export const DEV_ONLY_PRODUCTS: ProductType[] = [];
export const BETA_ONLY_PRODUCTS: ProductType[] = [];
export const INTERNAL_ONLY_PRODUCTS: ProductType[] = [
  ProductType.INTERNAL_OPEN_INTEREST,
];

export const RELEASED_PRODUCTS: ProductType[] = ALL_PRODUCTS.filter(
  (prod: ProductType) =>
    !DEV_ONLY_PRODUCTS.includes(prod) && !BETA_ONLY_PRODUCTS.includes(prod),
);

export const STANDARD_PRODUCTS: ProductType[] = [
  ProductType.HOME,
  ProductType.FOUNDERS_NOTES,
  ProductType.SENTIMENT,
  ProductType.INDICES,
  ProductType.INTEGRATIONS,
];

export const ONLY_PRO_PRODUCTS: ProductType[] = [
  ProductType.EQUITYHUB,
  ProductType.SCANNERS,
];

export const PRO_PRODUCTS: ProductType[] =
  ONLY_PRO_PRODUCTS.concat(STANDARD_PRODUCTS);

export const ONLY_ALPHA_PRODUCTS: ProductType[] = [
  ProductType.HIRO,
  ProductType.IMPLIED_VOL,
];

export const ALPHA_PRODUCTS: ProductType[] =
  ONLY_ALPHA_PRODUCTS.concat(PRO_PRODUCTS);

export const BLOOMBERG_PRODUCTS: ProductType[] = [ProductType.HIRO];

export const SUBSCRIPTION_PRODUCTS: Record<SubLevel, ProductType[]> = {
  [SubLevel.NONE]: [],
  [SubLevel.STANDARD]: isBloomberg()
    ? []
    : STANDARD_PRODUCTS.filter((pt: ProductType) => pt !== ProductType.HOME),
  [SubLevel.PRO]: isBloomberg() ? [] : ONLY_PRO_PRODUCTS,
  [SubLevel.ALPHA]: isBloomberg() ? BLOOMBERG_PRODUCTS : ONLY_ALPHA_PRODUCTS,
};

// On DEV, what access to products do users have?
export const DEV_ACCESS_TO_PRODUCTS: Record<string, ProductType[]> = {
  beta: [],
  internal: ALL_PRODUCTS,
  '': [],
};

// On BETA (staging), what access to products do users have?
export const BETA_ACCESS_TO_PRODUCTS: Record<string, ProductType[]> = {
  beta: ALL_PRODUCTS,
  internal: ALL_PRODUCTS,
  '': [],
};

// On PROD, what access to products do users have?
export const PROD_ACCESS_TO_PRODUCTS: Record<string, ProductType[]> = {
  beta: RELEASED_PRODUCTS,
  internal: ALL_PRODUCTS,
  '': RELEASED_PRODUCTS,
};

export const ZENDESK_SETTINGS = {
  position: {
    horizontal: 'right',
    vertical: 'bottom',
  },
  offset: { horizontal: '0px', vertical: '0px' },
};

export const RIGHT_PROPS = {
  flexDirection: 'row-reverse',
  justifyContent: 'flex-end',
};
export const LEFT_PROPS = { alignItems: 'left' };

export const DEFAULT_POSITIVE_TREND_COLOR = '#18bdac';

export const DEFAULT_NEGATIVE_TREND_COLOR = '#cc304a';

export const FREE_SYMBOLS = ['TSLA', 'AAPL', 'NVDA', 'MSFT', 'AMZN'];

export const MAIN_CONTENT_DEFAULT_WIDTH = 0.7;

export const historyOnlyFields = [
  ScannerColumnKey.tradeDate,
  ScannerColumnKey.putDelta,
  ScannerColumnKey.callDelta,
];

export const scannerOnlyFields = [
  'isWatchlisted',
  ScannerColumnKey.sym,
  ScannerColumnKey.price,
  ScannerColumnKey.volume,
  ScannerColumnKey.wkHigh52,
  ScannerColumnKey.wkLow52,
  ScannerColumnKey.earningsDate,
  ScannerColumnKey.dpi5day,
];

export const scannerFields = Object.values(ScannerColumnKey).filter(
  (sck) => !historyOnlyFields.includes(sck),
);
export const historyFields = Object.values(ScannerColumnKey).filter(
  (sck) => !scannerOnlyFields.includes(sck),
);

export const scannerUnmodifiableFields = [
  'isWatchlisted',
  ScannerColumnKey.sym,
  ScannerColumnKey.tradeDate,
];

export const DATA_EXCHANGE_AGREEMENT_QUESTIONS: Record<
  DataExchangeAgreement,
  string
> = {
  [DataExchangeAgreement.INSTITUTIONAL_TRADER]:
    'Are you an institutional trader?',
  [DataExchangeAgreement.NON_BUSINESS_DATA_USAGE]:
    'Do you use stock market data exclusively for personal, non-business use?',
  [DataExchangeAgreement.REGISTERED_BODY]:
    'Are you registered or qualified with the SEC, CFTC, any securities agency, exchange, association or regulatory body?',
  [DataExchangeAgreement.ASSET_MANAGER]:
    'Are you an asset manager or investment advisor?',
  [DataExchangeAgreement.EXTERNAL_CAPITAL_USE]:
    'Do you use the capital of any other individual or entity in your trading?',
  [DataExchangeAgreement.TRADING_FOR_CORP]:
    'Do you conduct trading for the benefit of a corporation, partnership, or other entity?',
  [DataExchangeAgreement.IS_PROFIT_SHARING]:
    'Have you entered into any profit-sharing agreement related to your trading activities?',
  [DataExchangeAgreement.BENEFIT_EXCHANGE_FOR_TRADING]:
    'Are you receiving office space, equipment or other benefits in exchange for trading or financial consulting work?',
};

export const DEFAULT_AGREEMENT_FORM: DataExchangeAgreementMap = {
  [DataExchangeAgreement.INSTITUTIONAL_TRADER]: null,
  [DataExchangeAgreement.NON_BUSINESS_DATA_USAGE]: null,
  [DataExchangeAgreement.REGISTERED_BODY]: null,
  [DataExchangeAgreement.ASSET_MANAGER]: null,
  [DataExchangeAgreement.EXTERNAL_CAPITAL_USE]: null,
  [DataExchangeAgreement.TRADING_FOR_CORP]: null,
  [DataExchangeAgreement.IS_PROFIT_SHARING]: null,
  [DataExchangeAgreement.BENEFIT_EXCHANGE_FOR_TRADING]: null,
};
