import { useState, useEffect, useMemo } from 'react';
import { Box, Checkbox, FormControlLabel, ListItemText } from '@mui/material';
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  Stack,
} from '@mui/material';
import { Typography, useTheme } from '@mui/material';
import {
  oiIntradayDate,
  oiIntradaySymsState,
  oiIntradayGammaKeys,
  oiIntradayPriceBoundsState,
  oiIntradayInvertedState,
} from 'states';
import {
  timezoneState,
  oiIntradaySym,
  oiIntradayTimestampState,
  oiIntradayFilterPrice,
} from 'states';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { useSearchParams } from 'react-router-dom';
import { fetchRawAPI, predicateSearch, updateSearch } from 'util/shared';
import useWasmParquet from 'hooks/useWasmParquet';
import { readParquet } from 'parquet-wasm';
import * as arrow from 'apache-arrow';
import useLog from '../../hooks/useLog';
import dayjs, { Dayjs } from 'dayjs';
import Plotly from 'plotly.js';
import { Plot } from './Plot';
import * as d3 from 'd3';

enum ProcessingState {
  FETCHING = 'fetching',
  READING = 'reading',
  CONVERTING = 'converting',
  FAILED_FETCH = 'failed_fetch',
  PRICE_CANDLES = 'price_candles',
  DONE = 'done',
}

const GAMMA_LABELS = new Map([
  ['cust_gamma', 'Customers'],
  ['procust_gamma', 'Pro Customers'],
  ['bd_gamma', 'Broker Dealers'],
  ['firm_gamma', 'Firms'],
  ['mm_gamma', 'Market Makers'],
]);
const GAMMA_KEYS = [...GAMMA_LABELS.keys()];

const PRICE_BOUNDS = [
  0.001, 0.002, 0.003, 0.004, 0.005, 0.01, 0.02, 0.03, 0.04,
];

function getStatusString(processingState: ProcessingState) {
  switch (processingState) {
    case ProcessingState.FETCHING:
      return 'Fetching GEX...';
    case ProcessingState.READING:
      return 'Reading parquet...';
    case ProcessingState.CONVERTING:
      return 'Converting to in-memory Arrow format...';
    case ProcessingState.PRICE_CANDLES:
      return 'Fetching prices...';
    case ProcessingState.DONE:
      return 'Finished!';
  }
}

type TwelveCandle = {
  datetime: dayjs.Dayjs;
  open: number;
  high: number;
  low: number;
  close: number;
  volume: number;
};

function convertCandleDatetimes(candles: any[], tz: string): TwelveCandle[] {
  return candles.map((c) => ({
    ...c,
    datetime: dayjs.tz(c.datetime, tz),
  }));
}

// Convert twelvedata candles into Plotly trace
function getCandles(
  candles: TwelveCandle[],
  timestamp: number | undefined,
): Plotly.Data {
  candles = timestamp
    ? candles.filter((c) => c.datetime.valueOf() <= timestamp)
    : candles;
  const x = candles.map((c) => c.datetime.toDate());
  const open = candles.map((c) => c.open);
  const high = candles.map((c) => c.high);
  const low = candles.map((c) => c.low);
  const close = candles.map((c) => c.close);
  return {
    x,
    open,
    high,
    low,
    close,
    xaxis: 'x',
    type: 'candlestick',
    increasing: { line: { color: '#17BECF' } },
    decreasing: { line: { color: '#ef108a' } },
    line: { color: 'rgb(0,0,0)', width: 1 },
  };
}

// TODO different colors for dark/light mode?
const ZERO = 'rgb(255,255,255)';
const NEGATIVE = 'rgb(130,100,20)';
const POSITIVE = 'rgb(0,0,255)';
function getColorScale(gammas: number[]) {
  if (gammas.length === 0) {
    return [
      [0, NEGATIVE],
      [1, POSITIVE],
    ];
  }
  let min = gammas.reduce((a, b) => Math.min(a, b));
  let max = gammas.reduce((a, b) => Math.max(a, b));

  if (max < 0) {
    // 0 is most negative, and 1 is closer to zero
    return [
      [0, NEGATIVE],
      [1, ZERO],
    ]; // TODO ZERO should be interpolated here
  } else if (min > 0) {
    return [
      [0, ZERO],
      [1, POSITIVE],
    ]; // TODO ZERO should be interpolated here
  }
  const zeroFrac = Math.abs(min) / (max - min);
  let pos = POSITIVE;
  let neg = NEGATIVE;
  if (zeroFrac < 0.5) {
    neg = d3.interpolateRgb(ZERO, NEGATIVE)(zeroFrac);
  } else {
    pos = d3.interpolateRgb(ZERO, POSITIVE)(1 - zeroFrac);
  }
  return [
    [0, neg],
    [zeroFrac, ZERO],
    [1, pos],
  ];
}

function getContourData(
  table: arrow.Table,
  timestamp: number | undefined,
  netGammaKeys: string[],
  invert: boolean = false,
  priceBounds: number[] | null,
): Plotly.Data | null {
  const array = table.toArray();
  if (array.length === 0) {
    return null;
  }

  if (timestamp == null) {
    timestamp = -Infinity;
    // TODO: This shouldn't be necessary (why do we have zeroes at EOD?)
    for (const e of array) {
      for (const k of netGammaKeys) {
        if (e[k] != 0) {
          timestamp = Math.max(timestamp, e.timestamp);
        }
      }
    }
  }

  // Filter down to the timestamp in question. Using binary search cuts filter time significantly
  const start = predicateSearch(array, (e) => e.timestamp < timestamp!) + 1;
  const end = predicateSearch(array, (e) => e.timestamp <= timestamp!);
  let data = array.slice(start, Math.max(0, end) + 1);
  if (priceBounds != null) {
    data = data.filter(
      (e) => e.spot >= priceBounds[0] && e.spot <= priceBounds[1],
    );
  }

  const x: Date[] = data.map((e) => new Date(e.time));
  const y: number[] = data.map((e) => Number(e.spot));
  const getGamma = (e: any) => netGammaKeys.reduce((tot, k) => tot + e[k], 0);
  const z: number[] = data.map(invert ? (e: any) => -getGamma(e) : getGamma);
  const type = 'contour';
  const colorscale = getColorScale(z);

  // @ts-ignore
  return { x, y, z, colorscale, type };
}

const IntradayGammaControls = ({ table }: { table: arrow.Table }) => {
  const tz = useRecoilValue(timezoneState);
  const setTimestamp = useSetRecoilState<Dayjs | null>(
    oiIntradayTimestampState,
  );
  const [filterPrice, setFilterPrice] = useRecoilState(oiIntradayFilterPrice);
  const [gammaKeys, setGammaKeys] = useRecoilState(oiIntradayGammaKeys);
  const [priceBounds, setPriceBounds] = useRecoilState(
    oiIntradayPriceBoundsState,
  );
  const [isInverted, setIsInverted] = useRecoilState(oiIntradayInvertedState);
  const theme = useTheme();

  const timestamps = useMemo(() => {
    if (table == null) {
      return [];
    }
    const ts = new Set(table.toArray().map((e) => e.timestamp));
    return [...ts].sort().map((t) => dayjs.utc(t).tz(tz));
  }, [table]);

  const timeMarks = [];
  for (let i = 0; i < timestamps.length; i += 20) {
    timeMarks.push({
      value: i,
      label: `${timestamps[i].format('HH:mm')}`,
    });
  }

  const handleTimeChange = (
    _evt: Event,
    idx: number | number[],
    _activeThumb: number,
  ) => {
    if (!Array.isArray(idx)) {
      setTimestamp(timestamps[idx]);
    }
  };

  const handleBoundsChange = (
    _evt: Event,
    idx: number | number[],
    _activeThumb: number,
  ) => {
    if (!Array.isArray(idx)) {
      setPriceBounds(PRICE_BOUNDS[idx]);
    }
  };

  return (
    <Stack direction="row">
      <Box width="400px" sx={{ paddingLeft: '40px' }}>
        <Slider
          aria-label="timestamp"
          marks={timeMarks}
          valueLabelDisplay="on"
          min={0}
          max={timestamps.length - 1}
          onChange={handleTimeChange}
        />
      </Box>
      <Box width="100px" sx={{ paddingLeft: '40px' }}>
        <Slider
          aria-label="Price Bounds"
          valueLabelDisplay="on"
          marks
          value={PRICE_BOUNDS.indexOf(priceBounds ?? 0.01)}
          min={0}
          max={PRICE_BOUNDS.length - 1}
          onChange={handleBoundsChange}
        />
        <Typography
          sx={{
            textTransform: 'capitalize',
            fontSize: { xs: 12, sm: 13 },
          }}
        >
          Price&nbsp;Range
        </Typography>
      </Box>
      <Select
        id="intraday-gamma-select"
        multiple
        value={gammaKeys}
        displayEmpty
        size="small"
        onChange={(event: SelectChangeEvent<string[]>) => {
          setGammaKeys(event.target.value as string[]);
        }}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return `Select an entity`;
          }
          return selected.map((k) => GAMMA_LABELS.get(k)!).join(',');
        }}
        sx={{
          padding: 0,
          marginLeft: '50px',
          color: theme.palette.text.secondary,
        }}
      >
        {[...GAMMA_LABELS.entries()].map(([gamma, label]) => (
          <MenuItem key={gamma} value={gamma}>
            <Checkbox checked={gammaKeys.indexOf(gamma) > -1} />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Select>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={isInverted}
            onChange={(evt) => {
              setIsInverted(evt.target.checked);
            }}
          />
        }
        label={
          <Typography
            sx={{
              textTransform: 'capitalize',
              fontSize: {
                xs: 12,
                sm: 13,
              },
              color: theme.palette.primary.main,
            }}
          >
            Invert
          </Typography>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={filterPrice}
            onChange={(evt) => {
              setFilterPrice(evt.target.checked);
            }}
          />
        }
        label={
          <Typography
            sx={{
              textTransform: 'capitalize',
              fontSize: {
                xs: 12,
                sm: 13,
              },
              color: theme.palette.primary.main,
            }}
          >
            Filter Price
          </Typography>
        }
      />
    </Stack>
  );
};

export const IntradayGamma = () => {
  const [searchParams, setSearch] = useSearchParams();
  const gammaKeys = useRecoilValue(oiIntradayGammaKeys);
  const invert = useRecoilValue(oiIntradayInvertedState);
  const intradaySym = useRecoilValue(oiIntradaySym);
  const intradayDate = useRecoilValue(oiIntradayDate);
  const [candles, setCandles] = useState<TwelveCandle[]>([]);
  const [table, setTable] = useState<arrow.Table | null>(null);
  const priceBounds = useRecoilValue(oiIntradayPriceBoundsState);
  const [processingState, setProcessingState] = useState<ProcessingState>(
    ProcessingState.FETCHING,
  );
  const { getWasmPromise } = useWasmParquet();
  const timestamp = useRecoilValue(oiIntradayTimestampState);
  const filterPrice = useRecoilValue(oiIntradayFilterPrice);
  const { fetchAPIWithLog } = useLog('oi');
  const setSyms = useSetRecoilState(oiIntradaySymsState);

  useEffect(() => {
    const fetchSyms = async () => {
      const result = await fetchAPIWithLog('v1/intraday_syms');
      setSyms(new Set(result as string[]));
    };
    fetchSyms();
  }, []);

  useEffect(() => {
    const newSym = searchParams.get('sym');
    if (newSym != null) {
      setSearch(updateSearch({ sym: intradaySym }));
    }
  }, [intradaySym, searchParams]);

  useEffect(() => {
    const fetchParquet = async () => {
      const sym = encodeURIComponent(intradaySym);
      const params = new URLSearchParams({ sym });
      if (intradayDate != null) {
        params.append('date', intradayDate.format('YYYY-MM-DD'));
      }
      const [resp, _wasm] = await Promise.all([
        fetchRawAPI(`v1/oi/intradayGamma?${params.toString()}`),
        getWasmPromise(),
      ]);
      setProcessingState(ProcessingState.READING);
      if (resp.status !== 200) {
        const json = await resp.json();
        setProcessingState(ProcessingState.FAILED_FETCH);
        console.error(resp.status, json);
        return;
      }
      const buffer = await resp.arrayBuffer();
      const arrowTable = readParquet(new Uint8Array(buffer));
      setProcessingState(ProcessingState.CONVERTING);

      const table = arrow.tableFromIPC(arrowTable.intoIPCStream());
      setTable(table);
      setProcessingState(ProcessingState.PRICE_CANDLES);

      // TODO: Ideally we'd "know" that the date we fetch for intraday GEX is
      // going to come back filled and be able to fetch price candles in
      // parallel
      const tsData = table.getChild('timestamp')?.data;
      const tsValues = tsData?.[(tsData?.length ?? 1) - 1]?.values;
      const lastTS = tsValues[tsValues.length - 1];
      const startDate = dayjs(Number(lastTS / 1_000n)); // nanos to millis
      const endDate = startDate.add(1, 'day');
      const seriesParams = new URLSearchParams({
        symbol: sym,
        start_date: startDate.format('YYYY-MM-DD'),
        end_date: endDate.format('YYYY-MM-DD'),
        interval: '5min',
        order: 'ASC',
      });
      const series = await fetchAPIWithLog(
        `v1/twelve_series?${seriesParams.toString()}`,
      );
      const result = series[Object.keys(series)[0]];
      const candles = convertCandleDatetimes(
        result.values,
        result.meta.exchange_timezone,
      );
      setCandles(candles);
      setProcessingState(ProcessingState.DONE);
    };
    fetchParquet();
  }, [intradayDate, intradaySym]);

  const contourData = useMemo(() => {
    if (table == null) {
      return null;
    }
    let bounds = null;
    if (candles != null && priceBounds != null) {
      let lo = Infinity;
      let hi = -Infinity;
      for (const candle of candles) {
        lo = Math.min(lo, candle.low);
        hi = Math.max(hi, candle.high);
      }
      bounds = [lo * (1 - priceBounds), hi * (1 + priceBounds)];
    }
    return getContourData(
      table,
      timestamp?.valueOf(),
      gammaKeys,
      invert,
      bounds,
    );
  }, [gammaKeys, candles, invert, table, timestamp, priceBounds]);

  const candlesData = useMemo(() => {
    if ((candles?.length ?? 0) === 0) {
      return {};
    }
    return getCandles(candles, filterPrice ? timestamp?.valueOf() : undefined);
  }, [candles, filterPrice, timestamp]);

  const body =
    table == null || contourData == null ? (
      <Box>{getStatusString(processingState)}</Box>
    ) : (
      <Box>
        <Box>
          <IntradayGammaControls table={table} />
        </Box>
        <Box>
          <Plot
            data={[candlesData, contourData]}
            layout={{
              width: 800,
              height: 600,
              title: `${gammaKeys
                .map((k) => GAMMA_LABELS.get(k)!)
                .join(',')} Gamma exposure`,
            }}
          />
        </Box>
      </Box>
    );
  return <Box>{body}</Box>;
};
