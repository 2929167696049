import { useSearchParams } from 'react-router-dom';
import { ProductType } from '../../types';
import { updateSearch } from '../../util';
import {
  currentProductTypeState,
  equityHubSymbolsSetState,
  freeSymbolState,
  hiroSymbolsState,
  productAccessState,
} from '../../states';
import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  COMBINED_SIGNALS,
  COMBO_SYMS_TO_INDEX_SYMBOL,
  ComboSymbol,
} from '../../config';

const DEFAULT_SYM = ComboSymbol.SPX;
const DEFAULT_NON_COMBO_SYM = COMBO_SYMS_TO_INDEX_SYMBOL[ComboSymbol.SPX];

export const useSetSym = () => {
  const [searchParams, setSearch] = useSearchParams();
  const products = useRecoilValue(productAccessState);
  const productsWithAccess = new Set(products);
  const hiroSymbols = useRecoilValue(hiroSymbolsState);
  const ehSymbols = useRecoilValue(equityHubSymbolsSetState);
  const currentProductType = useRecoilValue(currentProductTypeState);
  const freeSymbol = useRecoilValue(freeSymbolState);

  const getSymRaw = useCallback(() => searchParams.get('sym'), [searchParams]);

  // sometimes when called on the first render of a new route the currentProductType
  // takes a small amount of time to update and therefore may be out of sync on first render
  // best practice here should be to pass in the ProductType
  // if it is clear what it should be
  const getSym = (productType = currentProductType) => {
    if (productType == null || !productsWithAccess.has(productType)) {
      return freeSymbol ?? '';
    }

    const sym = getSymRaw() ?? getDefaultSym(productType);
    // only HIRO supports Combo symbols
    if (productType === ProductType.HIRO || !COMBINED_SIGNALS.has(sym)) {
      return sym;
    }

    const indexSym = COMBO_SYMS_TO_INDEX_SYMBOL[sym];
    if (indexSym == null) {
      return DEFAULT_NON_COMBO_SYM;
    }

    // TODO: change this once equity hub supports RUT
    if (indexSym === 'RUT' && productType === ProductType.EQUITYHUB) {
      return DEFAULT_NON_COMBO_SYM;
    }

    return indexSym;
  };

  const getDefaultSym = (productType: ProductType | null) =>
    productType === ProductType.HIRO ? DEFAULT_SYM : DEFAULT_NON_COMBO_SYM;

  const navigateToSymUrl = (sym: string) => {
    const encodedSym = encodeURIComponent(sym);
    const inEH =
      (ehSymbols.size === 0 && !COMBINED_SIGNALS.has(sym)) ||
      ehSymbols.has(sym);

    if (productsWithAccess.has(ProductType.HIRO) && hiroSymbols.has(sym)) {
      return `/${ProductType.HIRO}?sym=${encodedSym}`;
    } else if (productsWithAccess.has(ProductType.EQUITYHUB) && inEH) {
      return `/${ProductType.EQUITYHUB}?sym=${encodedSym}`;
    }

    return null;
  };

  const sym = useMemo(() => getSym(), [searchParams, currentProductType]);

  const setSym = useCallback(
    (newSym: string | undefined | null, productType: ProductType) => {
      if (!productsWithAccess.has(productType) && newSym !== freeSymbol) {
        return '';
      }

      if (newSym == null) {
        // default to setting the symbol to the Combo SPX
        // so that HIRO will automatically get Combo SPX when switched to
        // in getSym we do the conversion such that product types that dont support the combo
        // instead get raw SPX
        if (productType === ProductType.INDICES) {
          setSearch(updateSearch({ sym: null }));
          return '';
        } else {
          newSym = DEFAULT_SYM;
        }
      }
      if (newSym !== getSymRaw()) {
        // remove zoomTo if it's there from an alert click on a previous symbol
        const newSearch = { sym: newSym, zoomTo: undefined };
        setSearch(updateSearch(newSearch));
      }

      return getSym(productType);
    },
    [getSymRaw, setSearch, getSym],
  );

  const getParam = (name: string) => {
    const val = searchParams.get(name);
    if (val == null) {
      return undefined;
    }
    return decodeURIComponent(val);
  };

  const setParams = (params: any) => {
    const encoded: any = {};
    for (const key in params) {
      encoded[key] =
        params[key] == null ? params[key] : encodeURIComponent(params[key]);
    }
    setSearch(updateSearch(encoded));
  };

  return {
    getSymRaw,
    setSym,
    getSym,
    sym,
    navigateToSymUrl,
    getParam,
    setParams,
    searchParams,
  };
};

export default useSetSym;
