import { ReactNode } from 'react';
import { useRecoilState } from 'recoil';
import { useTheme } from '@mui/material/styles';
import { Box, Modal, SxProps, Theme } from '@mui/material';
import { componentIsExpandedState } from 'states';

interface ExpandableContentWrapperProps {
  children: ReactNode;
  type: string;
  styleOverrides?: SxProps<Theme>;
}
export const ExpandableContentWrapper = ({
  children,
  type,
  styleOverrides,
}: ExpandableContentWrapperProps) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useRecoilState(componentIsExpandedState(type));

  return (
    <>
      <Box
        sx={{
          transition: '0.5s',
          display: 'flex',
          background: theme.palette.background.paper,
          borderRadius: theme.spacing(3),
          height: '100%',
          ...styleOverrides,
        }}
        key={type}
      >
        {children}
      </Box>
      <Modal
        key={`${type}-fullscreen`}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Box
          sx={{
            p: 5,
            height: '100%',
            width: '100%',
            background: theme.palette.background.paper,
          }}
        >
          {children}
        </Box>
      </Modal>
    </>
  );
};
