import bbMarketData from '../lib/bloomberg/bb-apps-data';
import {
  COMBINED_SIGNALS,
  COMBO_SYMS_TO_INDEX_SYMBOL,
  ComboSymbol,
} from '../config/hiro';
import { ET_UTC_OFFSET_MS } from './shared';
import dayjs from 'dayjs';
import bbAppUntyped, {
  IGlobalApplication,
} from '../lib/bloomberg/bb-apps-application';

const bbgIndices = ['SPX', 'NDX', 'RUT', 'RTY', 'ES1'];
export const bbApp = bbAppUntyped as unknown as IGlobalApplication;
export const bbAppComponent = bbApp?.currentComponent;

export const isBloomberg = () => {
  return process.env.REACT_APP_BLOOMBERG === 'true' || isBBEnvAvailable();
};

// this method will not work in PollingWorker/any worker since we do not have access to
// the global window object
export const isBBEnvAvailable = () => {
  return bbWindow() != null;
};

export const bbWindow = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  const w = window as any;
  return w.bb?.apps;
};

export const connectToBbgMarketSession = async () => {
  return await bbMarketData.createSession();
};

export const isValidBbgSymbol = (sym: string) => {
  return (
    (sym.includes('Index') && bbgIndices.includes(sym.split(' ')[0])) ||
    sym.includes('US Equity')
  );
};

export const toBbgSymbol = (sym: string) => {
  const comboSymTranslation = COMBO_SYMS_TO_INDEX_SYMBOL[sym];
  const isFutures = /\/ES[FHUZ]/.test(sym) || sym.includes('ES=F');
  if (bbgIndices.includes(sym) || comboSymTranslation || isFutures) {
    sym = comboSymTranslation ?? sym;
    if (sym === 'RUT') {
      sym = 'RTY'; // bloomberg does not recognize RUT, only RTY
    } else if (isFutures) {
      sym = 'ES1';
    }
    return `${sym} Index`;
  }
  return `${sym} US Equity`;
};

export const bbgSymbolToSgSymbol = (sym: string) => {
  if (COMBINED_SIGNALS.has(sym)) {
    return sym;
  }

  if (!sym || (!sym.includes('US Equity') && !sym.includes('Index'))) {
    return null;
  }

  const parts = sym.split(' ');
  if (parts.length < 2) {
    return sym;
  }

  if (parts[parts.length - 1] === 'Index') {
    if (parts[0].startsWith('ES')) {
      if (
        parts[0].length > 3 &&
        ['H', 'M', 'U', 'Z'].includes(parts[0][2]) &&
        !isNaN(parseInt(parts[0][3]))
      ) {
        // technically this will break for anything after 2029 but that's ok
        return `/ES${parts[0][2]}2${parts[0][3]}:XCME`;
      }
      return ComboSymbol.ES_F;
    }
    if (parts[0].startsWith('RTY')) {
      return ComboSymbol.RUT;
    }
  }

  return parts[0];
};

export const firstMatchInObj = (obj: any, keys: string[]) => {
  for (const key of keys) {
    if (obj[key] != null) {
      return obj[key];
    }
  }

  return null;
};

// returns true if this is the web demo hosted at /demo
export function isBbgDemo() {
  return !isBBEnvAvailable() && isBloomberg();
}

export const utcMsToEtSecs = (ms: dayjs.Dayjs | number) => {
  return Math.floor((Number(ms) + ET_UTC_OFFSET_MS) / 1000);
};

export const getLaunchpadSym = () => {
  if (!isBBEnvAvailable() || bbAppComponent == null) {
    return null;
  }

  return (
    bbAppComponent.getProperty('Security 1') ||
    bbAppComponent.getProperty('Security 2')
  );
};
