import { Grid, IconButton, Paper, Popper, Typography } from '@mui/material';
import { useState } from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import { alpha, useTheme } from '@mui/material/styles';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  macroCalendarCountryOptionsState,
  macroCalendarCountriesSettingState,
  macroCalendarImpactsSettingState,
} from 'states';
import { MultiSelect } from 'components/shared';
import { IMPACT_OPTIONS } from 'types';
import { SGTooltip } from '../core';

export const MacroCalendarSettings = () => {
  const [openSettings, setOpenSettings] = useState(false);
  const [anchorSettingsEl, setAnchorSettingsEl] = useState<any>(null);
  const theme = useTheme();
  const countryOptions = useRecoilValue(macroCalendarCountryOptionsState);
  const [selectedCountry, setSelectedCountry] = useRecoilState(
    macroCalendarCountriesSettingState,
  );
  const [selectedImpact, setSelectedImpact] = useRecoilState(
    macroCalendarImpactsSettingState,
  );

  return (
    <>
      <IconButton
        aria-label="settings"
        onClick={(event) => {
          setAnchorSettingsEl(event.currentTarget);
          setOpenSettings((previousOpen) => !previousOpen);
        }}
        size="medium"
        color="primary"
      >
        <SGTooltip title="Macro Calendar Settings">
          <TuneIcon />
        </SGTooltip>
      </IconButton>
      <Popper
        id="macro-calendar-settings"
        open={openSettings}
        anchorEl={anchorSettingsEl}
        placement="bottom-end"
      >
        <Paper
          sx={{
            padding: 4,
            borderRadius: 2,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-start"
            gap={3}
          >
            <Grid
              item
              gap={3}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography color={theme.palette.text.primary} fontSize={12}>
                Country
              </Typography>
              <MultiSelect
                key="macro-calendar-country-select"
                label="Select Country"
                value={selectedCountry}
                setter={setSelectedCountry}
                options={countryOptions}
              />
            </Grid>
            <Grid
              item
              gap={3}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography color={theme.palette.text.primary} fontSize={12}>
                Impact
              </Typography>
              <MultiSelect
                key="macro-calendar-impact-select"
                label="Select Impact"
                value={selectedImpact}
                setter={setSelectedImpact}
                options={IMPACT_OPTIONS}
              />
            </Grid>
          </Grid>
        </Paper>
      </Popper>
    </>
  );
};
