import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import weekday from 'dayjs/plugin/weekday';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { PREMARKET_SYMBOLS } from '../../config';

dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(advancedFormat);
dayjs.extend(dayOfYear);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export { dayjs };

export const ET = 'America/New_York';

export const getAllTimezones = (): string[] => {
  return Intl.supportedValuesOf('timeZone');
};

const SEARCH_LIMIT = 7;
export const getCurrentDate = (tz = ET): dayjs.Dayjs => dayjs().tz(tz);

export const isBusinessDay = (timestamp = dayjs().tz(ET)) => {
  return timestamp.day() > 0 && timestamp.day() < 6;
};

export const ET_UTC_OFFSET_MS = dayjs().tz(ET).utcOffset() * 60 * 1000;

export const stockMarketOpen = () =>
  dayjs().tz(ET).set('hour', 9).set('minute', 30).set('second', 0);

export const stockMarketClose = () =>
  dayjs().tz(ET).set('hour', 16).set('minute', 0).set('second', 0);

export const preMarketOpen = () =>
  dayjs().tz(ET).set('hour', 0).set('minute', 0).set('second', 0);

export const shouldShowWaitingForMarketOpen = () => {
  const timestamp = dayjs().tz(ET);
  return isMarketOpenOnDate(timestamp) && timeToMarketOpenMs() > 0;
};

export const timeToMarketOpenMs = (timestamp = dayjs().tz(ET)) => {
  // negative if market already open
  return stockMarketOpen().diff(timestamp);
};

export const timeToPreMarketOpenMs = (timestamp = dayjs().tz(ET)) => {
  // negative if premarket already open
  // technically with a premarket at midnight this will always be negative
  // but let's keep this in here for now in case we change the premarket start time
  return preMarketOpen().diff(timestamp);
};

export const isPreMarket = () => {
  return (
    isMarketOpenOnDate() &&
    timeToMarketOpenMs() > 0 &&
    timeToPreMarketOpenMs() <= 0
  );
};

export const isMarketOpen = () => {
  const now = dayjs().tz(ET);
  return (
    isMarketOpenOnDate() &&
    now.isBetween(stockMarketOpen(), stockMarketClose(), null, '[]') // []' means inclusive
  );
};

export const getQueryDate = (allowPreOpen = false, tz = ET): dayjs.Dayjs => {
  // if market has already opened today, it will return today (even if after 4pm est)
  // otherwise, it will get the last day the market was open accounting for market holidays + weekends
  let date = getCurrentDate(tz);
  if (isMarketOpenOnDate(date)) {
    if (allowPreOpen || date >= stockMarketOpen()) {
      return date;
    }
  }
  // if after cutoff for showing waiting for market then use today
  // otherwise use yesterday instead since market isn't open yet today
  date = date.subtract(1, 'day');
  while (!isMarketOpenOnDate(date)) {
    date = date.subtract(1, 'day');
  }
  return date;
};

export const getClosestBusinessDay = (): dayjs.Dayjs => {
  if (isBusinessDay()) {
    return getCurrentDate();
  }
  return nextBusinessDay();
};

export const getQueryDateFormatted = (allowPreOpen = false): string =>
  getDateFormatted(getQueryDate(allowPreOpen));

export const getFormattedDateFromUTC = (utcTimestamp: string | number) =>
  getDateFormatted(
    dayjs(
      typeof utcTimestamp === 'string' ? Number(utcTimestamp) : utcTimestamp,
    ).tz(ET),
  );

// data from https://www.nyse.com/markets/hours-calendars
// TODO: Add programmatic way to find market closure dates
const CLOSURES = new Set([
  '2023-01-02',
  '2023-01-16',
  '2023-02-20',
  '2023-04-07',
  '2023-05-29',
  '2023-06-19',
  '2023-07-04',
  '2023-09-04',
  '2023-11-23',
  '2023-12-25',
  '2024-01-01',
  '2024-01-15',
  '2024-02-19',
  '2024-03-29',
  '2024-05-27',
  '2024-06-19',
  '2024-07-04',
  '2024-09-02',
  '2024-11-28',
  '2024-12-25',
  '2025-01-01',
  '2025-01-20',
  '2025-02-17',
  '2025-04-18',
  '2025-05-26',
  '2025-06-19',
  '2025-07-04',
  '2025-09-01',
  '2025-11-27',
  '2025-12-25',
]);

export type DayjsInput =
  | string
  | number
  | Date
  | dayjs.Dayjs
  | null
  | undefined;
export const getDateFormatted = (date: DayjsInput): string => {
  return dayjs(date).format(dateFormat);
};

export const isMarketOpenOnDate = (date: dayjs.Dayjs = dayjs()): boolean => {
  return !(
    date.weekday() === 0 ||
    date.weekday() === 6 ||
    CLOSURES.has(getDateFormatted(date))
  );
};

export const spansNonMktDays = (a: dayjs.Dayjs, b: dayjs.Dayjs) => {
  let [lo, hi] = a.isBefore(b) ? [a, b] : [b, a];
  for (; hi.isAfter(lo); hi = hi.subtract(1, 'day')) {
    if (!isMarketOpenOnDate(hi)) {
      return true;
    }
  }
  return false;
};

export const prevBusinessDay = (timestamp = dayjs().tz(ET)) => {
  let currentDay = timestamp.clone();
  let loopIndex = 1;
  while (loopIndex < SEARCH_LIMIT) {
    currentDay = currentDay.subtract(1, 'day');

    if (isBusinessDay(currentDay)) {
      break;
    }
    loopIndex += 1;
  }

  return currentDay;
};

export const nextBusinessDay = (timestamp = dayjs().tz(ET)) => {
  return nextBusinessDayHolidays(dayjs(timestamp), false);
};

const nextBusinessDayHolidays = (
  timestamp: dayjs.Dayjs,
  includeHolidays: boolean,
) => {
  let currentDay = timestamp.clone();
  let loopIndex = 1;
  while (loopIndex < SEARCH_LIMIT) {
    currentDay = currentDay.add(1, 'day');
    if (
      (includeHolidays && isBusinessDay(currentDay)) ||
      (!includeHolidays && isMarketOpenOnDate(currentDay))
    ) {
      break;
    }
    loopIndex += 1;
  }

  return currentDay;
};

export const prevBusinessDayOpenMarket = (timestamp = dayjs().tz(ET)) => {
  const searchLimit = 7;
  let currentDay = timestamp.clone();
  let loopIndex = 1;
  while (loopIndex < searchLimit) {
    currentDay = prevBusinessDay(currentDay);

    if (isMarketOpenOnDate(currentDay)) {
      break;
    }
    loopIndex += 1;
  }

  return currentDay;
};

export const businessDaysSubtract = (
  timestamp = dayjs().tz(ET),
  numDays: number,
) => {
  let currentDay = timestamp.clone();
  var i;
  for (i = 0; i < numDays; i++) {
    currentDay = prevBusinessDay(currentDay);
  }
  return currentDay;
};

export const businessDaysAdd = (
  timestamp = dayjs().tz(ET),
  numDays: number,
) => {
  let currentDay = timestamp.clone();
  var i;
  for (i = 0; i < numDays; i++) {
    currentDay = nextBusinessDay(currentDay);
  }
  return currentDay;
};

const dateFormat = 'YYYY-MM-DD';

export const getDaysUntil = (
  timestamp1: string,
  timestamp2 = dayjs.utc(),
): number => {
  return dayjs.utc(timestamp1, dateFormat).diff(timestamp2, 'days');
};

export const roundToStartOfDay = (timestamp: number) => {
  return dayjs(timestamp).startOf('day').valueOf();
};

export const roundToEndOfDay = (timestamp: number) => {
  return dayjs(timestamp).endOf('day').valueOf();
};

export function calcOffsetMS(tz: string, time: number) {
  const utcOffsetMinutes = dayjs.tz(time, tz).utcOffset();
  return utcOffsetMinutes * 60 * 1_000;
}

export const getLastMonday = () => getDateFormatted(dayjs().day(1));

// TODO: Read preferred date format from User settings
export const getYMD = (tz: string, epoch_millis: number | string) =>
  getDateFormatted(dayjs(epoch_millis).tz(tz));

// Returns format in Aug 16, 2018 8:02 PM
export const getDateAndTime = (timestamp: string) =>
  dayjs(timestamp).format('lll');

// Days in our model tables come in as UTC midnight timestamps.  So they must
// be displayed as UTC to get the correct date.
export const getUtcYMD = getYMD.bind(global, 'UTC');

// Formats timestamp to August 16, 2018
export const formatToLLDate = (timestamp: string) =>
  dayjs(timestamp).format('LL');

export const getMostRecentBusinessDay = () => {
  if (isBusinessDay()) {
    return getCurrentDate();
  }
  return prevBusinessDay();
};

export const getQueryDateForSymbol = (
  symbol: string | undefined,
  tz?: string,
) => {
  return getQueryDate(PREMARKET_SYMBOLS.has(symbol ?? ''), tz);
};
