import { Box, Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Equity, HistoricalEquity, ScannerColumnKey } from '../../../types';
import {
  ehHistoryScannerColumnOrderState,
  ehHistoryScannerColumnsVisibilityState,
  ehHistoryScannerFilterModelState,
  equityHistoryState,
  selectedEquitySymbolState,
} from '../../../states/equityhub';
import useHistory from '../../../hooks/equityhub/useHistory';
import { alpha, SxProps, Theme, useTheme } from '@mui/material/styles';
import { Loader } from 'components/shared';
import {
  EquityTableType,
  useEquityGridColumns,
} from './useEquityScannerGridColumns';
import {
  DataGridPremium,
  GridCallbackDetails,
  gridClasses,
  GridColDef,
  GridColumnOrderChangeParams,
  GridFilterModel,
  GridSlots,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiContext,
} from '@spotgamma/x-data-grid-premium';
import { EmptyGridOverlay } from 'components/stock_scanner/EmptyGridOverlay';
import { EmptyResultsOverlay } from 'components/stock_scanner/EmptyResultsOverlay';
import { historyFields, scannerUnmodifiableFields } from 'config';
import useSetSym from 'hooks/hiro/useSetSym';
import { dayjs } from 'util/shared';
import SelectedSymbol from 'components/shared/SelectedSymbol';

interface CustomToolbarProps {
  setFilterButtonEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  selectedSymbol?: string;
}

const CustomToolbar = ({
  setFilterButtonEl,
  selectedSymbol,
}: CustomToolbarProps) => {
  const apiRef = useGridApiContext();

  const sxProps: SxProps<Theme> = {
    textTransform: 'capitalize',
    fontSize: {
      xs: 12,
      md: 14,
    },
  };

  const { getSym } = useSetSym();

  const fileName = `${
    getSym() ? `${getSym()}_` : ''
  }history-table_${dayjs().format('YYYY-MM-DD')}`;

  return (
    <GridToolbarContainer sx={{ padding: '5px' }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        width="100%"
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <GridToolbarFilterButton
            slotProps={{
              button: {
                sx: sxProps,
              },
            }}
            ref={setFilterButtonEl}
          />
          <GridToolbarColumnsButton
            slotProps={{
              button: {
                sx: sxProps,
              },
            }}
          />
          <GridToolbarExport
            slotProps={{
              button: {
                sx: sxProps,
              },
            }}
            csvOptions={{
              getRowsToExport: () => apiRef.current.getAllRowIds(),
              fileName,
            }}
            excelOptions={{
              getRowsToExport: () => apiRef.current.getAllRowIds(),
              fileName,
            }}
            printOptions={{ disableToolbarButton: true }}
          />
        </Stack>

        {selectedSymbol && (
          <SelectedSymbol symbol={selectedSymbol} sx={{ position: 'unset' }} />
        )}
      </Stack>
    </GridToolbarContainer>
  );
};

export const HistoryTable = () => {
  const [data, setData] = useRecoilState(equityHistoryState);
  const selectedSymbol = useRecoilValue(selectedEquitySymbolState);
  const [dpiRange, setDPIRange] = useState<number[] | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [columnVisibilityModel, setColumnVisibilityModel] = useRecoilState(
    ehHistoryScannerColumnsVisibilityState,
  );
  const [filterModel, setFilterModel] = useRecoilState(
    ehHistoryScannerFilterModelState,
  );

  const [columnOrder, setColumnOrder] = useRecoilState(
    ehHistoryScannerColumnOrderState,
  );

  const [filterButtonEl, setFilterButtonEl] =
    useState<HTMLButtonElement | null>(null);

  const { getHistory } = useHistory();
  const theme = useTheme();

  const { columns, columnGroups } = useEquityGridColumns(
    theme,
    dpiRange,
    EquityTableType.History,
  );

  const defaultContentColor = theme.palette.getContrastText(
    theme.palette.background.default,
  );

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const data = await getHistory(selectedSymbol!);
      const dpiArray = data.map((d: Equity) => d.dpi);
      const dpiRange = [Math.min(...dpiArray), Math.max(...dpiArray)];
      setDPIRange(dpiRange);
      setData(
        data.map((he: any) => ({
          ...he,
          id: he.trade_date,
        })) as HistoricalEquity[],
      );
      setLoading(false);
    }
    fetchData();
  }, [getHistory, setData, selectedSymbol]);

  const getTogglableColumns = (columns: GridColDef[]) => {
    return columns
      .filter(
        (column) =>
          !scannerUnmodifiableFields.includes(column.field as ScannerColumnKey),
      )
      .map((column) => column.field);
  };

  const orderedColumns = useMemo(() => {
    const orderMap = new Map(columnOrder.map((field, index) => [field, index]));
    const fallback = new Map(
      historyFields.map((field, idx) => [
        field as string,
        idx + columns.length,
      ]),
    );

    return [...columns].sort(
      (a, b) =>
        (orderMap.get(a.field as ScannerColumnKey) ?? fallback.get(a.field)!) -
        (orderMap.get(b.field as ScannerColumnKey) ?? fallback.get(b.field)!),
    );
  }, [columnOrder, columns]);

  const handleColumnOrderChange = (
    params: GridColumnOrderChangeParams,
    _event: any,
    _details: GridCallbackDetails,
  ) => {
    const result = columnOrder.slice(); // Create a copy of the array
    const [removedElement] = result.splice(params.oldIndex, 1); // Remove the element from its original position
    result.splice(params.targetIndex, 0, removedElement); // Insert the element at the new position

    setColumnOrder(result);
  };

  return (
    <Box
      sx={{
        padding: 2,
        height: '100%',
      }}
    >
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <DataGridPremium
          rows={data}
          columns={orderedColumns}
          onColumnOrderChange={handleColumnOrderChange}
          columnGroupingModel={columnGroups}
          initialState={{ pinnedColumns: { left: ['trade_date'] } }}
          hideFooter
          disableDensitySelector
          disableMultipleRowSelection
          disableAggregation
          disableRowGrouping
          disableRowSelectionOnClick
          rowHeight={40}
          columnHeaderHeight={52}
          columnVisibilityModel={columnVisibilityModel}
          filterModel={filterModel}
          onColumnVisibilityModelChange={(newModel) => {
            setColumnVisibilityModel(newModel);
          }}
          onFilterModelChange={(model: GridFilterModel) =>
            setFilterModel(model)
          }
          slots={{
            toolbar: CustomToolbar as GridSlots['toolbar'],
            noRowsOverlay: EmptyGridOverlay,
            noResultsOverlay: EmptyResultsOverlay,
          }}
          slotProps={{
            columnsManagement: {
              getTogglableColumns,
            },
            basePopper: {
              sx: {
                '& .MuiSvgIcon-root': {
                  color: alpha(defaultContentColor, 0.75),
                },
              },
            },
            panel: {
              anchorEl: filterButtonEl,
              sx: {
                width: '300px',
                transform: '0px',
              },
            },
            toolbar: {
              setFilterButtonEl,
              selectedSymbol,
            },
            filterPanel: {
              filterFormProps: {
                // Customize inputs by passing props
                logicOperatorInputProps: {
                  variant: 'outlined',
                  size: 'small',
                },
                columnInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { mt: 'auto' },
                },
                operatorInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { mt: 'auto' },
                },
                valueInputProps: {
                  InputComponentProps: {
                    variant: 'outlined',
                    size: 'small',
                  },
                },
              },
              sx: {
                gap: 2,

                '& .MuiButtonBase-root': {
                  textTransform: 'capitalize',
                },
                '& .MuiDataGrid-filterForm': { p: 3, mt: 6 },
                '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
                '& .MuiDataGrid-filterFormColumnInput': { mr: 2 },
                '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: alpha(theme.palette.primary.main, 0.5),
                },
              },
            },
          }}
          sx={{
            borderRadius: 2,
            backgroundColor: theme.palette.background.default,
            border: 'none',
            '& .MuiDataGrid-pinnedRows': {
              backgroundColor: theme.palette.scannerGrid.pinnedRow,
              '& .MuiDataGrid-pinnedColumns': {
                backgroundColor: theme.palette.scannerGrid.pinnedRow,
              },
            },
            '& .MuiDataGrid-filler': {
              display: 'none',
            },
            '& .grid-header-cell': {
              backgroundColor: theme.palette.scannerGrid.mainHeader,
              ':hover': {
                backgroundColor: alpha(theme.palette.text.primary, 0.1),
              },
              border: 'none',
            },
            '& .MuiDataGrid-topContainer': {
              border: 'none',
            },
            '& .MuiDataGrid-scrollbar': {
              background: theme.palette.background.default,
            },
            '& .MuiDataGrid-pinnedColumns': {
              backgroundColor: theme.palette.scannerGrid.pinnedColumn,
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: alpha(theme.palette.text.primary, 0.1),
            },
            '& .custom-group-header': {
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                justifyContent: 'center',
                height: '40px',
              },
            },
            '& .MuiDataGrid-columnHeader': {
              border: 'none',
            },
            '& .MuiDataGrid-columnHeaders': {
              border: 'none',
            },
            '& .MuiDataGrid-columnHeaderTitleContainer': {
              border: 'none',
            },
            '& .MuiDataGrid-sortIcon': {
              color: defaultContentColor,
            },
            '& .MuiDataGrid-menuIconButton': {
              color: defaultContentColor,
            },
            '& .MuiDataGrid-filterIcon': {
              color: defaultContentColor,
            },
            '& .MuiDataGrid-menuIconButton:hover': {
              backgroundColor: theme.palette.background.paper,
              color: alpha(defaultContentColor, 0.5),
            },
            '& .MuiDataGrid-iconSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-footerContainer': {
              justifyContent: 'flex-start',
            },
            '& .MuiDataGrid-cell': {
              color: defaultContentColor,
            },
            '& .MuiDataGrid-row .MuiDataGrid-cell': {
              border: 'none',
            },
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
              {
                outline: 'none',
              },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
              {
                outline: 'none',
              },
          }}
        />
      )}
    </Box>
  );
};
