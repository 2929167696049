export enum BottomCandlesType {
  SIZE = 'size',
  ABSOLUTE_DELTA = 'absolute_delta',
  FILTERED_DELTA = 'filtered_delta',
}

export enum StreamType {
  FILTERED = 1,
  ABSOLUTE = 2,
  BOTH = 3,

  AbsolutePrice = 0x4,

  // All
  FilteredTotalDelta = 0x08,
  AbsoluteTotalDelta = 0x10,
  FilteredCallDelta = 0x20,
  FilteredPutDelta = 0x40,

  // NextExp
  FilteredNextExpTotalDelta = 0x80,
  AbsoluteNextExpTotalDelta = 0x100,
  FilteredNextExpCallDelta = 0x200,
  FilteredNextExpPutDelta = 0x400,

  // Retail
  FilteredRetailTotalDelta = 0x800,
  AbsoluteRetailTotalDelta = 0x1000,
  FilteredRetailCallDelta = 0x2000,
  FilteredRetailPutDelta = 0x4000,
}
