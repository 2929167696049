import { Box, Link } from '@mui/material';
import useIntegrations from '../../hooks/integrations/useIntegrations';

export const ESignalIntegration = () => {
  const { getIntegrationsLinksBlock } = useIntegrations();

  return (
    <Box>
      <p>
        File URL updated: <strong>12/21/23</strong>
      </p>
      <br />
      <p>Here is the file to upload into eSignal:</p>
      {getIntegrationsLinksBlock('eSignal', ['SPY', 'QQQ', 'NDX', 'RUT'])}
      <img
        alt="eSignal SpotGamma ES Chart"
        width="1024"
        height="568"
        src="https://spotgamma.com/wp-content/uploads/2020/09/image-2-1024x568.png"
      />
    </Box>
  );
};
