import { SetterOrUpdater } from 'recoil';
import { Controls, ControlsProps } from '../../shared/Controls';
import { ExpandableContentButton, InfoButton } from 'components/shared';
import { alpha, Box, Stack, Typography, useTheme } from '@mui/material';
import { IndicesContentType, SymSelectorSettings } from 'types';
import { FoldableContentBtn } from 'components/shared/FoldableContentBtn';
import SymSelector from 'pages/components/Quadrant/Editor/SymSelector';

interface IndicesHeaderProps {
  type: IndicesContentType;
  title: string;
  expandable?: boolean;
  controllerProps?: ControlsProps;
  customController?: JSX.Element;
  hideTitle?: boolean;
  symSelectorSettings?: SymSelectorSettings;
  symbol: string;
  shouldHideBtns?: boolean;
  isUnfolded?: boolean;
  setIsUnfolded?: SetterOrUpdater<boolean>;
}

export const IndicesHeader = ({
  type,
  title,
  expandable,
  controllerProps,
  customController,
  hideTitle,
  isUnfolded,
  setIsUnfolded,
  shouldHideBtns,
  symSelectorSettings,
  symbol,
}: IndicesHeaderProps) => {
  const theme = useTheme();
  const contentController = (
    <Stack
      direction="row"
      gap={3}
      justifyContent="space-between"
      alignItems="center"
    >
      {symSelectorSettings && (
        <SymSelector
          value={symSelectorSettings.value}
          options={symSelectorSettings.options}
          onChange={symSelectorSettings.onChange}
          loading={symSelectorSettings.loading}
        />
      )}
      <Stack direction="row" alignItems="center" gap={1}>
        {controllerProps && <Controls {...controllerProps} />}
        {customController}
      </Stack>
    </Stack>
  );

  return (
    <Stack
      gap={1}
      sx={{
        cursor:
          isUnfolded != null && setIsUnfolded != null ? 'pointer' : 'inherit',
        padding: 2,
        borderRadius: 1,
        ':hover': {
          backgroundColor:
            isUnfolded != null && setIsUnfolded != null
              ? alpha(theme.palette.primary.main, 0.03)
              : 'transparent',
        },
      }}
      onClick={() =>
        isUnfolded != null && setIsUnfolded != null
          ? setIsUnfolded((prev) => !prev)
          : null
      }
    >
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        justifyContent="space-between"
      >
        {!hideTitle ? (
          typeof title === 'string' ? (
            <Typography
              variant="h2"
              color="text.primary"
              textTransform="capitalize"
              fontWeight={500}
            >
              {`${symbol} ${title}`}
            </Typography>
          ) : (
            `${symbol} ${title}`
          )
        ) : undefined}

        <Stack direction="row" alignItems="center" gap={2}>
          {!shouldHideBtns && (
            <>
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'flex',
                  },
                }}
              >
                {contentController}
              </Box>

              <InfoButton key={`${type}-info`} articleKey={type} />
              {expandable && <ExpandableContentButton type={type} />}
            </>
          )}

          {setIsUnfolded != null && isUnfolded != null && (
            <FoldableContentBtn
              isUnfolded={isUnfolded}
              setIsUnfolded={setIsUnfolded}
            />
          )}
        </Stack>
      </Stack>
      {!shouldHideBtns && (
        <Box
          sx={{
            display: {
              xs: 'flex',
              sm: 'none',
            },
          }}
        >
          {contentController}
        </Box>
      )}
    </Stack>
  );
};
