import { Box, Link } from '@mui/material';
import useIntegrations from '../../hooks/integrations/useIntegrations';

export const NinjaTraderIntegration = () => {
  const { getIntegrationsLinksBlock } = useIntegrations();

  return (
    <Box>
      <p>
        File URL updated: <strong>12/21/23</strong>
      </p>
      {getIntegrationsLinksBlock('NinjaTrader', ['SPY', 'QQQ'])}
      <br />
      <ol style={{ marginLeft: '2em' }}>
        <li>
          Click{' '}
          <Link href="https://spotgamma.com/mp-files/ninjatrader-file-loader.zip/">
            here{' '}
          </Link>
          to download our Custom NinjaTrader file loader. Do{' '}
          <strong>not </strong>unzip!
        </li>
        <li>
          From the NinjaTrader 8 main window, select Tools &gt;
          Import_NinjaScript Add-on
        </li>
        <li>Select the zip file</li>
        <li>Acknowledge the dialog boxes that appear </li>
        <li>
          Once it confirms the import has finished, the indicator will appear in
          the usual indicators list
        </li>
        <li>
          To add to a chart, right click the chart, select “Indicators” from the
          menu, then scroll through the list and select “SpotGamma” and press
          “Add”
        </li>
        <li>
          Then select “SpotGamma” in the lower window and enter the SpotGamma
          csv URL (See image below)
        </li>
        <li>
          Enter the SpotGamma csv URL from above for your selected symbol in the
          File URL field (no file name is required), and click “OK”.
        </li>
      </ol>

      <br />
      <img
        decoding="async"
        style={{ maxWidth: '100%' }}
        src="https://spotgamma.com/wp-content/uploads/2020/07/NT_ARROW_LI-2-863x1024.jpg"
      />
    </Box>
  );
};
