import { BottomCandlesType } from './bloomberg';

export interface TrendingData {
  currentDaySignal: number;
  instrument: string;
  signal_date?: string;
  hiro_timeseries?: number[];
  price: number;
}

// As returned from `v3/trending` endpoint
export interface TrendingElement {
  sym: number;
  trend: number;
  trend_abs: number;
  instrument: string;
}

export interface AverageData {
  high: number;
  low: number;
  average: number;
}

export interface HiroCumulativeData {
  '1 day': AverageData;
  '5 day': AverageData;
  '30 day': AverageData;
}

export interface RawHiroOverview {
  companyName: string;
  currentDayPrice?: string;
  currentDaySignal?: string;
  symbol: string;
  high1?: number;
  high5?: number;
  high20?: number;
  low1?: number;
  low5?: number;
  low20?: number;
  lastClose?: number;
  day?: string;
  sector?: string;
  live?: boolean;
}

export interface LowHigh {
  low: number;
  current?: number;
  high: number;
}

export interface HiroOverview {
  instrument: string;
  signal: number;
  companyName: string;
  '1 day': LowHigh;
  '5 day': LowHigh;
  '30 day': LowHigh;
  price: number;
  open: number;
  priceChange: number;
  sector: string;
  live?: boolean;
}

export enum Lense {
  All = 'all',
  NextExp = 'nextExp',
  Retail = 'retail',
}

export interface HiroStatistics {
  symbol: string;
  name: string;
  exchange: string;
  mic_code: string;
  currency: string;
  datetime: string;
  timestamp: number;
  open: string;
  high: string;
  low: string;
  volume: string;
  previous_close: string;
  change: string;
  percent_change: string;
  average_volume: string;
  rolling_1d_change: string;
  rolling_7d_change: string;
  rolling_period_change: string;
  is_market_open: boolean;
  fifty_two_week: {
    low: string;
    high: string;
    low_change: string;
    high_change: string;
    low_change_percent: string;
    high_change_percent: string;
    range: string;
  };
  extended_change: string;
  extended_percent_change: string;
  extended_price: string;
  extended_timestamp: string;
}

export enum Index {
  SPY = 'SPY',
  QQQ = 'QQQ',
}

export interface IndicesStatistics {
  [Index.SPY]: HiroStatistics;
  [Index.QQQ]: HiroStatistics;
}

export interface RawSpotgammaLevels {
  Symbol: string;
  activity_factor: number;
  atmgc: number;
  atmgp: number;
  call_gnot_list_absg: string;
  call_strikes_list_absg: string;
  callsum: number;
  cg_list: string;
  cv: number;
  date: string;
  dpi: number;
  keyd: number;
  keyg: number;
  largeCoi: number;
  largePoi: number;
  max_exp_d_date: string;
  max_exp_g_date: string;
  mf_list: string;
  minfs: number;
  next_exp_d: number;
  next_exp_g: number;
  pg_list: string;
  position_factor: number;
  put_gnot_list_absg: string;
  put_strikes_list_absg: string;
  putctrl: number;
  putsum: number;
  pv: number;
  pws: number;
  smf_list: string;
  strike_list: string;
  sym: string;
  total_volume: number;
  totaldelta: number;
  totalgamma: number;
  trade_date: string;
  upx: number;
  maxfs: number;
  cws: number;
  sig_high: number;
  sig_low: number;
}

export interface RawHiroAndPrice {
  utc_time: number;
  instrument: string;
  mid_signal: number;
  option_type: string;
  signal: number;
  stock_price: number;
}

export interface HiroAndPrice {
  utc_time: number;
  mid_signal: number;
  signal: number;
  stock_price: number;
  type: string;
}

export interface GroupedRawHiro {
  [ALL: string]: (HiroAndPrice | RawHiroAndPrice)[];
  P: (HiroAndPrice | RawHiroAndPrice)[];
  C: (HiroAndPrice | RawHiroAndPrice)[];
}

export interface ValueToMarker {
  '1': { label: string; seconds: number };
  '2': { label: string; seconds: number };
  '3': { label: string; seconds: number };
  '4': { label: string; seconds: number };
  '5': { label: string; seconds: number };
  '6': { label: string; seconds: number };
  '7': { label: string; seconds: number };
  '8': { label: string; seconds: number };
}

export type ValToMarkerKey = keyof ValueToMarker;

export interface SparkData {
  [x: string]: string[];
}

export interface RawHiroMap {
  all: [HiroAndPrice];
  nextExp: [HiroAndPrice];
  retail: [HiroAndPrice];
}

export interface RawHiroTrend {
  all: [HiroAndPrice];
}

export interface RawTrending {
  [instrument: string]: RawHiroTrend;
}

export type HiroTimeseries = {
  all: { TOT: RawHiroAndPrice[]; P: RawHiroAndPrice[]; C: RawHiroAndPrice[] };
  nextExp: {
    TOT: RawHiroAndPrice[];
    P: RawHiroAndPrice[];
    C: RawHiroAndPrice[];
  };
  retail: {
    TOT: RawHiroAndPrice[];
    P: RawHiroAndPrice[];
    C: RawHiroAndPrice[];
  };
};

export type HiroChartSettings = {
  showExtendedHours: boolean;
  showChartTooltip: boolean;
  showAlerts: boolean;
  sumWindow: number;
  selectedLenses: {
    all: number;
    nextExp: number;
    retail: number;
  };
  optionType: string;
  showSpotgammaLevels: boolean;
  bottomCandlesType: BottomCandlesType;
};

export enum HiroUpdatingType {
  STREAMING,
  POLLING,
}

export type PriceLineData = {
  value: number | undefined;
  color: string;
};

export type PriceLineKey =
  | 'Last Closing'
  | 'Hedge Wall'
  | 'Key Gamma Strike'
  | 'Key Delta Strike'
  | 'Call Wall'
  | 'Put Wall';

export type PriceData = {
  sym: string;
  date: string;
  price: number;
};

export type SigHighLowData = {
  sig_high: number;
  sig_low: number;
};
